(function () {
    'use strict';
    angular.module('app')
        .directive('adGroupEditor', ['$location', '$compile', function ($location, $compile) {
            return {
                template: require('@views/campaign/editors/ad-group-complex-editor.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { adGroup: '=', isCampaignWizard: '=', viewMode: '=' },
                controller: ['$rootScope', '$scope', 'hub', '$sce', '$timeout', 'accountService', 'campaignService', 'authService', 'timezoneService', 'roleService', 'context', 'statcontext', 'dragulaService', 'dialogService', 'validationService', 'featureService', function ($rootScope, $scope, hub, $sce, $timeout, accountService, campaignService, authService, timezoneService, roleService, context, statcontext, dragulaService, dialogService, validationService, featureService) {
                    var vm = this;
                    vm.log = hub.getLog('Ad Group Management', 'error');
                    var rootVM = $rootScope.rootVM;
                    vm.page = hub.page;
                    vm.isAdmin = authService.userAccountType() === "Admin";
                    vm.isAdvertiser = authService.userAccountType() === "Advertiser";
                    vm.isAdvertiserGroup = authService.userAccountType() === "AdvertiserGroup";
                    vm.canLaunch = authService.isSuperAdmin() || authService.hasAccess('campaigns', 'Launch');
                    vm.accountId = authService.userInfoData().accountId;
                    vm.openEvidenceDealId = hub.settings.environment === 'production' ? 3109 : (hub.settings.environment === 'staging' ? 3722 : 2699);

                    vm.adGroup = $scope.adGroup;

                    vm.adGroup.duplicateName = false;
                    vm.adGroupNameChanged = adGroupNameChanged;
                    vm.audienceNameChanged = audienceNameChanged;
                    vm.advancedSettingsText = "Show Advanced Schedule & Budget Settings";
                    vm.adGroup.endDateError = false;
                    vm.countEstimatedReachBounds = countEstimatedReachBounds;
                    vm.displayEstimateReach = false;
                    vm.estimatedReachLowerBound = 0;
                    vm.estimatedReachUpperBound = 0;
                    vm.isCampaignWizard = $scope.isCampaignWizard;
                    vm.hasEndemicDtcFeature = authService.hasFeature("ENDEMIC-DTC");

                    vm.dateDiff = dateDiff;
                    vm.datepickers = { startDate: false, endDate: false };
                    vm.openDatepicker = openDatepicker;
                    vm.hours = ["1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00"];
                    vm.meridiems = ["AM", "PM"];

                    vm.min;
                    vm.novalidate = novalidate;
                    vm.marketerMaximumBid = '';
                    vm.panelState = [true, true, true, true, true, true];
                    vm.recalculateBar = recalculateBar;
                    vm.showAudienceWarning = false;
                    vm.adGroup.audienceSelectionError = false;
                    vm.selectedCampaign = campaignService.getSelectedCampaign();
                    vm.toggleAdvancedSettings = toggleAdvancedSettings;
                    vm.viewMode = $scope.viewMode;
                    vm.currencies = campaignService.getCurrencies();
                    vm.recencyTimeUnits = campaignService.getRecencyTimeUnits();
                    vm.frequencyTimeUnits = campaignService.getFrequencyTimeUnits();
                    vm.adGroup.showAdvancedSettings = [16, 17, 21, 22, 31, 39, 43, 51, 52, 53, 56, 74, 81, 84, 91, 98, 100, 109, 125].indexOf(rootVM.accountId) > -1;

                    vm.channels = campaignService.getChannels();
                    vm.endemicMinimumCpm = vm.channels.find(c => c.id == 16).estimatedMediaCPM;
                    vm.ehrMinimumCpm = vm.channels.find(c => c.id == 25).estimatedMediaCPM;
                    vm.sizes = campaignService.getSizes();
                    vm.dataCPMChanged = vm.adGroup.adGroupId ? true : false;
                    vm.proactChannels = [1, 2, 4, 5, 6, 9, 16, 25];

                    if (!vm.adGroup.editor) {
                        vm.adGroup.editor = 'v1';
                    }

                    if (vm.adGroup.splits) {
                        vm.adGroup.splits.forEach(s => {
                            if (!s.conditionsJSON || s.conditionsJSON.length < 3) {
                                vm.adGroup.editor = 'v2';
                            }
                        });
                    }

                    if (vm.adGroup.adGroupId) {
                        if ([3, 6, 9].indexOf(vm.adGroup.channelId) > -1) {
                            vm.noUpdateDataCpm = true;
                            if (!vm.adGroup.channelId !== 9)
                                $timeout(function () {
                                    vm.updateDataCPM();
                                });
                        }
                        else {
                            hub.webapicontext.checkAdGroupDataCpmCalculationIsLegacy(vm.adGroup.adGroupId)
                                .then(function (res) {
                                    vm.noUpdateDataCpm = res;
                                    vm.updateDataCPM();
                                });
                        }
                    }

                    vm.allowedChannel = function (id) {
                        if (id === 9) {
                            return vm.filteredChannels.find(function (ch) { return ch.id === id; })
                                && (vm.isAdmin || vm.dataOnlyAllowed);
                        }

                        return vm.filteredChannels.find(function (ch) { return ch.id === id && (!vm.campaign.isProact || vm.proactChannels.indexOf(ch.id) > -1); });
                    }

                    vm.campaign = campaignService.getSelectedCampaign();
                    if (vm.campaign) {
                        vm.adGroup.marketerId = vm.campaign.marketerId;
                        vm.adGroup.budgetTypeId = vm.campaign.budgetTypeId;
                        var marketer = campaignService.marketers.find(function (r) { return r.id == vm.adGroup.marketerId; });
                        var allowedMediaTypes = marketer.options.find(function (ro) { return ro.optionId == 6; });
                        const lassoTriggerAllowed = marketer.options.find(function (ro) { return ro.optionId == 7; });
                        vm.lassoTriggerAllowed = lassoTriggerAllowed && lassoTriggerAllowed.enabled;
                        const dataOnlyAllowedObj = marketer.options.find(function (ro) { return ro.optionId == 10130; })
                        vm.dataOnlyAllowed = dataOnlyAllowedObj && dataOnlyAllowedObj.enabled;
                        vm.filteredChannels = allowedMediaTypes ? vm.channels.filter(function (c) { return JSON.parse(allowedMediaTypes.value).indexOf(c.id) > -1; }) : vm.channels;
                    }
                    else
                        vm.filteredChannels = vm.channels;

                    $scope.$watch('vm.campaign.isProact', function (newVal, oldVal) {
                        var marketer = campaignService.marketers.find(function (r) { return r.id == vm.adGroup.marketerId; });
                        var allowedMediaTypes = marketer.options.find(function (ro) { return ro.optionId == 6; });
                        const lassoTriggerAllowed = marketer.options.find(function (ro) { return ro.optionId == 7; });
                        vm.lassoTriggerAllowed = lassoTriggerAllowed && lassoTriggerAllowed.enabled;
                        vm.filteredChannels = allowedMediaTypes ? vm.channels.filter(function (c) { return JSON.parse(allowedMediaTypes.value).indexOf(c.id) > -1; }) : vm.channels;

                        if (vm.campaign.isProact)
                            vm.filteredChannels = vm.filteredChannels.filter(c => vm.proactChannels.indexOf(c.id) > -1);
                    });

                    vm.secondaryChannels = vm.filteredChannels;

                    if (!vm.adGroup.locationSegments)
                        vm.adGroup.locationSegments = [];

                    if (!vm.adGroup.endemicBid)
                        vm.adGroup.endemicBid = 250;

                    if (!vm.adGroup.currency)
                        vm.adGroup.currency = 1;

                    if (!vm.adGroup.recencyTimeUnit)
                        vm.adGroup.recencyTimeUnit = 1;

                    if (!vm.adGroup.adGroupId)
                        vm.adGroup.automaticallyPushAudience = true;

                    if (!vm.adGroup.frequencies || vm.adGroup.frequencies.length === 0)
                        vm.adGroup.frequencies = [{ frequencyTimeUnit: 3 }];

                    if (vm.adGroup.recencyValue == 0) {
                        vm.adGroup.recencyValue = undefined;
                    }

                    if (vm.adGroup.frequencies && vm.adGroup.frequencies.length > 0) {
                        vm.adGroup.frequencies.forEach(f => {
                            if (f.frequencyValue == 0) {
                                f.frequencyValue = undefined;
                            }
                        });
                    }

                    if (vm.adGroup.channelId == 16 && !vm.allowedChannel(16) && !vm.adGroup.adGroupId)
                        vm.adGroup.channelId = 1;


                    if (vm.adGroup.publishersJSON) {
                        var allPublishers = JSON.parse(vm.adGroup.publishersJSON);
                        vm.adGroup.publishers = allPublishers.filter(p => !p.hidden);
                        vm.adGroup.publishersHidden = allPublishers.filter(p => p.hidden);
                    }

                    if (vm.adGroup.monetizationPlacementsJSON)
                        vm.adGroup.monetizationPlacements = JSON.parse(vm.adGroup.monetizationPlacementsJSON);

                    if (vm.adGroup.adUnitsJSON)
                        vm.adGroup.adUnits = JSON.parse(vm.adGroup.adUnitsJSON);

                    if (!vm.adGroup.brandSafetySegments)
                        vm.adGroup.brandSafetySegments = [];

                    vm.channelEvents = campaignService.getChannelEvents().filter(function (ev) {
                        return (ev.id === 4 || ev.id === 5) && vm.adGroup.channelId === 6 && vm.adGroup.emailDeploymentType === 2
                            || (ev.id === 1 || ev.id === 2 || ev.id === 3) && vm.adGroup.channelId === 6 && vm.adGroup.emailDeploymentType !== 2
                            || (ev.id === 4 || ev.id === 5) && (vm.adGroup.channelId === 1 || vm.adGroup.channelId === 2 || vm.adGroup.channelId === 4 || vm.adGroup.channelId === 5)
                            || ev.id === 5 && vm.adGroup.channelId === 3 || ev.id === 0;
                    });

                    vm.emailDeploymentTypeChanged = function (emailDeploymentType) {
                        $rootScope.$broadcast("adGroupChannelChanged", vm.adGroup.channelIndex);
                        vm.channelEvents = campaignService.getChannelEvents().filter(function (ev) {
                            return (ev.id === 4 || ev.id === 5) && vm.adGroup.channelId === 6 && emailDeploymentType === 2
                                || (ev.id === 1 || ev.id === 2 || ev.id === 3) && vm.adGroup.channelId === 6 && emailDeploymentType !== 2;
                        });
                    };

                    if (!vm.campaign.advertiser)
                        vm.campaign.advertiser = campaignService.advertisers.find(function (a) { return a.id == vm.campaign.advertiserId; });
                    if (!vm.campaign.marketers)
                        vm.campaign.marketer = campaignService.marketers.find(function (m) { return m.id == vm.campaign.marketerId; });

                    function getExchangeName(channel, accountType) {
                        var name = channel + ' - ';
                        switch (accountType) {
                            case 1:
                                name = name + 'Adv-Level - ';
                                break;
                            case 2:
                                name = name + 'Mar-Level - ';
                                break;
                        }
                        return name;
                    }

                    vm.adAccounts = [];
                    var activeChannels = campaignService.getChannels().filter(function (c) { return [3, 8, 11, 12, 13, 14, 15, 18, 21, 22, 23].indexOf(c.id) > -1; });
                    activeChannels.forEach(function (c) {
                        if (vm.campaign.advertiser.exchangeSettings[c.id])
                            vm.adAccounts.push({
                                key: vm.campaign.advertiser.exchangeSettings[c.id].externalId,
                                name: getExchangeName(c.name, 1) + vm.campaign.advertiser.exchangeSettings[c.id].externalId,
                                id: vm.campaign.advertiser.exchangeSettings[c.id].externalId, channelId: c.id
                            });
                    });

                    activeChannels.forEach(function (c) {
                        if (vm.campaign.marketer.exchangeSettings[c.id])
                            vm.adAccounts.push({
                                key: vm.campaign.marketer.exchangeSettings[c.id].externalId,
                                name: getExchangeName(c.name, 2) + vm.campaign.marketer.exchangeSettings[c.id].externalId,
                                id: vm.campaign.marketer.exchangeSettings[c.id].externalId, channelId: c.id
                            });
                    });

                    vm.selectedAdAccountChange = function () {
                        const adAccount = vm.adAccounts.find(function (aa) { return aa.key == vm.adGroup.adAccountId; });
                        if (adAccount)
                            vm.adGroup.adAccountChannelId = adAccount.channelId;
                    }

                    if (vm.adGroup.adGroupId) {
                        vm.selectedAdAccountChange();
                        if (!vm.adGroup.customName){
                            vm.adGroup.customName = "";
                            vm.adGroup.useCustomName = false;
                        } else {
                            vm.adGroup.useCustomName = true;
                        }
                        if (!vm.adGroup.customName && vm.adGroup.audienceSegmentGroups
                            && vm.adGroup.audienceSegmentGroups.length
                            && vm.adGroup.audienceSegmentGroups[0].customName) {
                            vm.adGroup.customName = vm.adGroup.audienceSegmentGroups[0].customName;
                            vm.adGroup.useCustomName = true;
                        }
                    }

                    var now = new Date();
                    // Start date time in the past criteria Start Time <= NOW - 10 min
                    vm.adGroup.startDateIsInThePast = vm.adGroup.adGroupId !== undefined && (vm.adGroup.startDate.getTime() <= (now.getTime() + 10 * 60 * 1000));
                    // End date time in the past criteria End Time < NOW
                    vm.adGroup.endDateIsInThePast = vm.adGroup.endDate !== undefined && vm.adGroup.endDate !== null && vm.adGroup.endDate < now;

                    if (!vm.adGroup.optimizationGoalTypeId)
                        vm.adGroup.optimizationGoalTypeId = '';

                    if (!vm.adGroup.trackingCodes || vm.adGroup.trackingCodes.length === 0)
                        vm.adGroup.trackingCodes = [{}];

                    if (!vm.adGroup.emailDomainNames || vm.adGroup.emailDomainNames.length === 0) {
                        vm.adGroup.emailDomainNames = [];
                        if (vm.adGroup.emailDomains)
                            vm.adGroup.emailDomains.split(',').forEach(function (ed) { vm.adGroup.emailDomainNames.push({ value: ed }) });
                        else
                            vm.adGroup.emailDomainNames.push({ value: '' });
                    }

                    vm.retargetingSegmentCreated = vm.adGroup.retargetingAction;

                    if (!vm.adGroup.dataCPM){
                        if (vm.adGroup.channelId == 16)
                            vm.adGroup.dataCPM = vm.endemicMinimumCpm;
                        else if (vm.adGroup.channelId == 25)
                            vm.adGroup.dataCPM = vm.ehrMinimumCpm;
                        else
                            vm.adGroup.dataCPM = 0;
                    }

                    vm.adGroup.partnerFee = vm.adGroup.partnerFee ? vm.adGroup.partnerFee : 0;
                    vm.adGroup.pacingPercent = vm.adGroup.pacingPercent ? vm.adGroup.pacingPercent : 100;

                    if (!vm.adGroup.adGroupCodeDates)
                        vm.adGroup.adGroupCodeDates = [new Date(), new Date()];

                    if (!vm.adGroup.creatives)
                        vm.adGroup.creatives = [];

                    if (!vm.adGroup.conversionSegments)
                        vm.adGroup.conversionSegments = [];

                    if (!vm.adGroup.optimizationConversionSegments)
                        vm.adGroup.optimizationConversionSegments = [];

                    vm.creativeSelected = vm.adGroup.creatives.length;
                    vm.staticCreativeSelected = vm.adGroup.creatives.length && vm.adGroup.creatives.find(c => !c.thirdPartyServed);
                    vm.campaign = campaignService.getSelectedCampaign();

                    var m = moment.utc();
                    vm.currentEastTimeAbbreviation = m.tz('America/New_York').format('z');
                    var offset = -5.00;
                    if (vm.currentEastTimeAbbreviation === "EDT")
                        offset = -4.00;
                    var tzDifference = offset * 60 + now.getTimezoneOffset();

                    vm.setRange = function (channelId = null) {
                        if (channelId !== 9 && channelId != 'measurement'
                            && !(vm.adGroup.isMeasurementOnly && vm.adGroup.channelId === 9)) {
                            vm.minDate = now;
                            vm.minDate.setMinutes(vm.minDate.getMinutes() - 1);
                        } else {
                            vm.minDate = new Date(1971, 1, 1, 0, 0, 0, 0);
                            vm.minDate.setMinutes(vm.minDate.getMinutes() - 1);
                        }
                        vm.startCalendarDate = new Date(vm.minDate.getTime() + tzDifference * 60 * 1000);
                        vm.endCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);
                        vm.startDateValid = true;
                        var endDate = new Date(vm.adGroup.endDate);
                        vm.endDateValid = vm.adGroup.endDate == null || (vm.adGroup.endDate >= vm.adGroup.startDate
                            && Math.ceil((endDate.getTime() - now.getTime()) / 60 / 1000) >= 30);
                        vm.dateRangeValid = (vm.adGroup.channelId != '3' || (Math.abs(vm.adGroup.UTCCalendarEndDate - vm.adGroup.UTCCalendarStartDate) / 36e5) >= 25)
                            && Math.abs(vm.adGroup.endDate - vm.adGroup.startDate) / 36e5 >= 25;
                        if (vm.adGroup.adGroupId && !(vm.dateRangeValid && vm.endDateValid && vm.startDateValid)) {
                            vm.adGroup.datesInvalid = true;
                        }
                    }
                    vm.setRange(vm.adGroup.channelId);

                    vm.getEasternTimeZone = timezoneService.getEasternTimeZone;

                    vm.getEasternDateTime = function (date) {
                        return moment(date).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss ZZ');
                    };

                    vm.formatHours = function (hourType, hourValue) {
                        if (hourValue.length === 1)
                            hourValue = "0" + hourValue;
                        else if (hourValue.length === 3)
                            hourValue = hourValue.substr(1);

                        var parsed = parseInt(hourValue)

                        if (isNaN(parsed) || parsed > 12 || parsed < 1) {
                            hourValue = "01";
                            vm.selectTime(hourType, 'hour');
                        }

                        if (hourType === "start")
                            vm.adGroup.startDateHour = hourValue;
                        else if (hourType === "end")
                            vm.adGroup.endDateHour = hourValue;
                    };

                    vm.formatMinutes = function (minutesType, minutesValue) {
                        var parsed = parseInt(minutesValue);

                        if (isNaN(parsed) || parsed > 59 || parsed < 0) {
                            minutesValue = "00";
                            vm.selectTime(minutesType, 'minute');
                        }

                        if (minutesValue.length === 1)
                            minutesValue = "0" + minutesValue;
                        else if (minutesValue.length === 3)
                            minutesValue = minutesValue.substr(1);

                        if (minutesType === "start")
                            vm.adGroup.startDateMinutes = minutesValue;
                        else if (minutesType === "end")
                            vm.adGroup.endDateMinutes = minutesValue;
                    };

                    vm.selectTime = function (type, part) {
                        if (part === 'hour') {
                            setTimeout(function () {
                                angular.element(type === 'start' ? '#startDateHour' : '#endDateHour')[0].setSelectionRange(0, 2);
                            }, 0);
                        } else if (part === 'minute') {
                            setTimeout(function () {
                                angular.element(type === 'start' ? '#startDateMinutes' : '#endDateMinutes')[0].setSelectionRange(0, 2);
                            }, 0);
                        } else if (part === 'meridiem') {
                            setTimeout(function () {
                                angular.element(type === 'start' ? '#startDateMeridiem' : '#endDateMeridiem')[0].setSelectionRange(0, 2);
                            }, 0);
                        }
                    };

                    vm.toggleHours = function (hoursType, $event) {
                        var intHours;
                        var newHours;

                        if ($event.keyCode === 38) //up arrow
                        {
                            if (hoursType === 'start') {
                                intHours = parseInt(vm.adGroup.startDateHour) + 1;
                                newHours = (intHours).toString();
                                newHours = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.adGroup.startDateHour = intHours === 13 ? "01" : newHours;
                                vm.parseStartDate()
                            }
                            else if (hoursType === 'end') {
                                intHours = parseInt(vm.adGroup.endDateHour) + 1;
                                newHours = (intHours).toString();
                                newHours = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.adGroup.endDateHour = intHours === 13 ? "01" : newHours;
                                vm.parseEndDate();
                            }
                            vm.selectTime(hoursType, 'hour');

                        }
                        else if ($event.keyCode === 40) //down arrow
                        {
                            if (hoursType === 'start') {
                                intHours = parseInt(vm.adGroup.startDateHour) - 1;
                                newHours = (intHours).toString();
                                newHours = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.adGroup.startDateHour = intHours === 0 ? "12" : newHours;
                                vm.parseStartDate();
                            }
                            else if (hoursType === 'end') {
                                intHours = parseInt(vm.adGroup.endDateHour) - 1;
                                newHours = (intHours).toString();
                                var newMinewHoursnutes = newHours.length === 1 ? "0" + newHours : newHours;
                                vm.adGroup.endDateHour = intHours === 0 ? "12" : newHours;
                                vm.parseEndDate();
                            }
                            vm.selectTime(hoursType, 'hour');
                        }
                    };

                    vm.toggleMinutes = function (minutesType, $event) {
                        var intMinutes;
                        var newMinutes;
                        if ($event.keyCode === 38) //up arrow
                        {
                            if (minutesType === 'start') {
                                intMinutes = parseInt(vm.adGroup.startDateMinutes) + 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.adGroup.startDateMinutes = intMinutes === 60 ? "00" : newMinutes;
                                vm.parseStartDate();
                            }
                            else if (minutesType === 'end') {
                                intMinutes = parseInt(vm.adGroup.endDateMinutes) + 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.adGroup.endDateMinutes = intMinutes === 60 ? "00" : newMinutes;
                                vm.parseEndDate();
                            }
                            vm.selectTime(minutesType, 'minute');
                        }
                        else if ($event.keyCode === 40) //down arrow
                        {
                            if (minutesType === 'start') {
                                intMinutes = parseInt(vm.adGroup.startDateMinutes) - 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.adGroup.startDateMinutes = intMinutes === -1 ? "59" : newMinutes;
                                vm.parseStartDate();
                            }
                            else if (minutesType === 'end') {
                                intMinutes = parseInt(vm.adGroup.endDateMinutes) - 1;
                                newMinutes = intMinutes.toString();
                                newMinutes = newMinutes.length === 1 ? "0" + newMinutes : newMinutes;
                                vm.adGroup.endDateMinutes = intMinutes === -1 ? "59" : newMinutes;
                                vm.parseEndDate();
                            }
                            vm.selectTime(minutesType, 'minute');
                        }
                    };

                    vm.toggleMeridiem = function (meridiemType, $event) {
                        if ($event.keyCode === 38 || $event.keyCode === 40) {
                            if (meridiemType === 'start') {
                                vm.adGroup.startDateMeridiem = (vm.adGroup.startDateMeridiem === "AM") ? "PM" : "AM";
                                vm.parseStartDate();
                            }
                            else if (meridiemType === 'end') {
                                vm.adGroup.endDateMeridiem = (vm.adGroup.endDateMeridiem === "AM") ? "PM" : "AM";
                                vm.parseEndDate();
                            }
                        }
                        else if ($event.keyCode === 65) {
                            if (meridiemType === 'start' && vm.adGroup.startDateMeridiem === "PM") {
                                vm.adGroup.startDateMeridiem = "AM";
                                vm.parseStartDate();
                            }
                            else if (meridiemType === 'end' && vm.adGroup.endDateMeridiem === "PM") {
                                vm.adGroup.endDateMeridiem = "AM";
                                vm.parseEndDate();
                            }
                        }
                        else if ($event.keyCode === 80) {
                            if (meridiemType === 'start' && vm.adGroup.startDateMeridiem === "AM") {
                                vm.adGroup.startDateMeridiem = "PM";
                                vm.parseStartDate();
                            }
                            else if (meridiemType === 'end' && vm.adGroup.endDateMeridiem === "AM") {
                                vm.adGroup.endDateMeridiem = "PM";
                                vm.parseEndDate();
                            }
                        }

                        if ($event.keyCode != 9 && $event.keyCode != 16) {
                            $event.preventDefault();
                        }
                        vm.selectTime(meridiemType, 'meridiem');
                    };

                    const processDatepickerDate = timezoneService.processDatepickerDate;

                    if (vm.adGroup.sourceTypeId === 0)
                        vm.adGroup.sourceTypeId = undefined;

                    if (vm.adGroup.triggerEngagementId === 0)
                        vm.adGroup.triggerEngagementId = undefined;

                    vm.ongoingChanged = function () {
                        if (vm.isOngoing) {
                            vm.adGroup.endDate = null;
                            vm.adGroup.UTCCalendarEndDate = null;
                            vm.adGroup.budgetCapSpendModeId = 1;
                        }
                        else {
                            vm.adGroup.endDate = new Date(vm.adGroup.startDate.getTime());
                            vm.adGroup.endDate.setDate(vm.adGroup.endDate.getDate() + 1); //tomorrow
                            vm.adGroup.endDateTime = vm.adGroup.startDateTime;
                            vm.adGroup.endDateHour = "11";
                            vm.adGroup.endDateMinutes = "59";
                            vm.adGroup.endDateMeridiem = "PM";
                        }
                        vm.parseEndDate();
                    }

                    function dateDiff() {
                        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                        var firstDate = new Date(vm.adGroup.startDate);
                        var secondDate = new Date(vm.adGroup.endDate);
                        var diffDays = Math.ceil(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
                        return diffDays;
                    }

                    function openDatepicker($event, which) {
                        $event.preventDefault();
                        $event.stopPropagation();
                        for (var prop in vm.datepickers) {
                            if (prop !== which)
                                vm.datepickers[prop] = false;
                        }
                        if (vm.datepickers[which])
                            vm.datepickers[which] = false;
                        else
                            vm.datepickers[which] = true;
                    };

                    if (!vm.adGroup.flights)
                        vm.adGroup.flights = [
                            {
                                statusId: 0,
                                startDate: vm.adGroup.startDate,
                                startDateIsInThePast: vm.adGroup.startDateIsInThePast,
                                endDate: vm.adGroup.endDate,
                                endDateIsInThePast: vm.adGroup.endDateIsInThePast,
                                pacingPercent: 100,
                                isLifetimePacing: vm.adGroup.budgetCapSpendModeId == 2
                            }];
                    else {
                        var previousIsInThePast = false;
                        vm.adGroup.flights.forEach(function (f) {
                            // Start date time in the past criteria Start Time <= NOW - 10 min
                            f.startDateIsInThePast = f.startDate !== undefined && f.startDate !== null && (new Date(f.startDate).getTime() <= (now.getTime() + 10 * 60 * 1000));
                            // End date time in the past criteria End Time < NOW
                            f.endDateIsInThePast = f.endDate !== undefined && f.endDate !== null && new Date(f.endDate).getTime() < now.getTime();
                            if (previousIsInThePast && new Date(f.endDate) > now) {
                                f.likeFirstFlight = true;
                            }
                            previousIsInThePast = new Date(f.endDate) < now;
                        });
                    }

                    vm.addFlight = function () {
                        const flights = vm.adGroup.flights.filter(x => x.statusId !== 1);
                        var lastFlight = _.last(flights);
                        if (lastFlight.endDate > now || lastFlight.endDate.getDate() == now.getDate()) {
                            const date = timezoneService.getDayEnd(lastFlight.endDate);
                            lastFlight.endDate = date;
                            const { utcDate } = timezoneService.estUtc(lastFlight.endDate, lastFlight.endDate.getHours(), lastFlight.endDate.getMinutes());
                            lastFlight.UTCCalendarEndDate = utcDate;
                        }

                        const range = timezoneService.getNextRange(lastFlight.endDate < now ? now : lastFlight.endDate);
                        var likeFirstFlight = false;
                        if (lastFlight.endDate < now) {
                            range.startDate = (new Date(now)).setHours((new Date(now)).getHours() + 1);
                            likeFirstFlight = true;
                        }

                        if (vm.adGroup.channelId == 9 && vm.adGroup.isStructured) {
                            range.startDate = timezoneService.getNextMonth(lastFlight.startDate);
                            range.endDate = timezoneService.getMonthEnd(range.startDate);
                        }

                        vm.adGroup.flights.push(
                            {
                                ...range,
                                statusId: 0,
                                likeFirstFlight: likeFirstFlight,
                                pacingPercent: lastFlight ? lastFlight.pacingPercent : 100,
                                isLifetimePacing: vm.adGroup.budgetCapSpendModeId === 2
                            });
                        $rootScope.$broadcast('flightAdded', vm.adGroup.flights.length - 1);

                    }

                    vm.removeFlight = function (flight) {
                        if (flight.id)
                            flight.statusId = 1;
                        else
                            vm.adGroup.flights.splice($.inArray(flight, vm.adGroup.flights), 1);

                        var index = 0;
                        vm.adGroup.flights.forEach(function (f) {
                            if (!f.statusId) {
                                f.index = index;
                                index++;
                            }
                        });

                        $rootScope.$broadcast('flightRemoved');
                        calculateActualBudget();

                        if (vm.adGroup.channelId === 9 && vm.adGroup.isStructured) {
                            const flights = vm.adGroup.flights.filter(x => x.statusId !== 1 && vm.adGroup.flights.indexOf(x) !== 0);
                            var currentFlight = vm.adGroup.flights[0];
                            currentFlight.startDate = timezoneService.getMonthStart(currentFlight.startDate);
                            currentFlight.endDate = timezoneService.getMonthEnd(currentFlight.startDate);
                            flights.forEach(function (f) {
                                f.startDate = timezoneService.getNextMonth(currentFlight.startDate);
                                f.endDate = timezoneService.getMonthEnd(f.startDate, true);
                                currentFlight = f;
                            });
                            $rootScope.$broadcast('updateFlightDates', null);
                        }
                    }

                    vm.togglePlacements = function (item, p) {
                        item.selected = !item.selected;
                        if (item.items) {
                            for (let i of item.items) {
                                i.selected = item.selected;
                            }
                        } else if (p) {
                            p.selected = p.items.filter(x => x.selected).length == p.items.length;
                        }
                    }

                    vm.facebookOptimisation = [
                        {
                            id: 1, name: 'Awareness',
                            items: [{ id: 2, name: 'Reach' }, { id: 3, name: 'Brand Awareness' },]
                        },
                        {
                            id: 4, name: 'Consideration',
                            items: [{ id: 5, name: 'Traffic ' }, { id: 6, name: 'Engagement' }, { id: 7, name: 'Video Views' }]
                        },
                        {
                            id: 8, name: 'Conversion',
                            items: [{ id: 9, name: 'Conversions' }]
                        },
                    ];

                    vm.facebookPlacements = [
                        {
                            id: 1, name: 'Feeds', open: false, items: [
                                { id: 3, name: 'Facebook News Feed' },
                                { id: 4, name: 'Facebook Marketplace' },
                                { id: 5, name: 'Facebook Video Feeds' },
                                { id: 6, name: 'Facebook Right Column' },
                                { id: 7, name: 'Instagram Explore' },
                                { id: 8, name: 'Messenger Inbox' },
                                { id: 9, name: 'Facebook Group Feed' },
                            ]
                        },
                        {
                            id: 10, name: 'Stories', open: false, items: [
                                { id: 12, name: 'Facebook Stories' },
                                { id: 13, name: 'Instagram Stories' },
                                { id: 14, name: 'Messenger Stories' }
                            ]
                        },
                        {
                            id: 15, name: 'In-Stream', open: false, items: [
                                { id: 17, name: 'Facebook Stories' },
                                { id: 18, name: 'Instagram Stories' },
                                { id: 19, name: 'Messenger Stories' }
                            ]
                        },
                        {
                            id: 20, name: 'Search', open: false, items: [
                                { id: 22, name: 'Facebook Search Results' },
                            ]
                        },
                        {
                            id: 23, name: 'Messages', open: false, items: [
                                { id: 25, name: 'Messenger Sponsored Messages' },
                            ]
                        },
                        {
                            id: 26, name: 'In-Article', open: false, items: [
                                { id: 28, name: 'Facebook Instant Articles' },
                            ]
                        },
                        {
                            id: 29, name: 'Apps and Sites', open: false, items: [
                                { id: 31, name: 'Audience Network Native, Banner and Interstital' },
                                { id: 32, name: 'Audience Network  Rewarded Videos' },
                                { id: 33, name: 'Audience Network In-Stream Videos' },

                            ]
                        }
                    ]
                    vm.inventoryListsArray = [];
                    vm.inventoryListsArray.allChecked = false;
                    vm.checkSelectedInventoryLists = checkSelectedInventoryLists;
                    vm.getAllInventoryLists = getAllInventoryLists;
                    vm.showSelectedInventoryLists = false;
                    vm.selectAllInventoryLists = selectAllInventoryLists;
                    vm.selectInventoryList = selectInventoryList;
                    vm.toggleShowSelectedInventoryLists = toggleShowSelectedInventoryLists;
                    getAllInventoryLists();

                    vm.getLocations = getLocations;
                    vm.includedChanged = includedChanged;
                    vm.locationType = 'Region';
                    vm.loadPopulationData = loadPopulationData;
                    vm.locationTypeChanged = locationTypeChanged;
                    vm.selectedLocation = null;
                    vm.toggleLocationsCheck = toggleLocationsCheck;
                    vm.typeaheadOnSelect = typeaheadOnSelect;
                    vm.typeCanBeAdded = typeCanBeAdded;

                    vm.processFiles = processFiles;

                    vm.recencyValues = campaignService.getRecencyValues();
                    vm.recencyFromValues = angular.copy(vm.recencyValues);
                    vm.recencyToValues = angular.copy(vm.recencyValues);
                    vm.recencyFromValues.splice(5, 1);
                    vm.recencyToValues.splice(0, 1);
                    vm.selectedRecencyFromChanged = selectedRecencyFromChanged;
                    vm.selectedRecencyToChanged = selectedRecencyToChanged;

                    vm.recencyFrom = _.defaultTo(_.find(vm.recencyValues, { 'value': vm.adGroup.recencyFrom }), vm.recencyValues[0]);
                    vm.recencyTo = _.defaultTo(_.find(vm.recencyValues, { 'value': vm.adGroup.recencyTo }), vm.recencyValues[0]);

                    vm.adGroup.impsOverLifetime = vm.adGroup.impsOverLifetime == 0 ? '' : vm.adGroup.impsOverLifetime;
                    vm.adGroup.impsPerUserPerHour = vm.adGroup.impsPerUserPerHour == 0 ? '' : vm.adGroup.impsPerUserPerHour;
                    vm.adGroup.impsPerUserPerDay = vm.adGroup.impsPerUserPerDay == 0 ? '' : vm.adGroup.impsPerUserPerDay;
                    vm.adGroup.impsPerUserPerWeek = vm.adGroup.impsPerUserPerWeek == 0 ? '' : vm.adGroup.impsPerUserPerWeek;

                    vm.frequencyChanged = function () {
                        if (!vm.adGroup.showImpsPerUserPerHour)
                            vm.adGroup.impsPerUserPerHour = '';
                        if (!vm.adGroup.showImpsPerUserPerDay)
                            vm.adGroup.impsPerUserPerDay = '';
                        if (!vm.adGroup.showImpsPerUserPerWeek)
                            vm.adGroup.impsPerUserPerWeek = '';
                        if (!vm.adGroup.showImpsOverLifetime)
                            vm.adGroup.impsOverLifetime = '';
                    };

                    vm.perHourMin = function () {
                        return vm.adGroup.showImpsPerUserPerHour ? 1 : 0;
                    };
                    vm.perDayMin = function () {
                        return vm.adGroup.showImpsPerUserPerDay ? 1 : 0;
                    };
                    vm.perWeekMin = function () {
                        return vm.adGroup.showImpsPerUserPerWeek ? 1 : 0;
                    };
                    vm.perLifetimeMin = function () {
                        return vm.adGroup.showImpsOverLifetime ? 1 : 0;
                    };

                    vm.locationOptions = [{ id: 0, name: 'Include' }, {
                        id: 1, name: 'Exclude'
                    }];

                    //show exclude location if at least one location is excluded - normally all locations should be either included or excluded
                    vm.includeLocation = vm.adGroup.selectedLocations.length == 0 ? vm.locationOptions[0] : (vm.adGroup.selectedLocations.filter(function (item) { return item.excluded; }).length > 0 ? vm.locationOptions[1] : vm.locationOptions[0]);
                    vm.adGroup.locationsExcluded = (vm.includeLocation.id == 1);

                    vm.locationType = vm.adGroup.selectedLocations.length == 0 ? vm.locationType : vm.adGroup.selectedLocations[0].type;

                    vm.adGroupLoyality = !vm.adGroupLoyality ? [false, false, false, false, false, false, false, false, false] : vm.adGroupLoyality;

                    vm.loyality = [{ name: 'Heavy', values: [false, false, false], level: 0 }, { name: 'Medium', values: [false, false, false], level: 1 }, {
                        name: 'Light', values: [false, false, false], level: 2
                    }];

                    vm.inventoryLists = vm.adGroup.inventoryLists == undefined ? vm.adGroup.inventoryLists = [] : vm.adGroup.inventoryLists;

                    if (!vm.recencyTypes)
                        vm.recencyTypes = [{ id: 1, name: '14 days' }, { id: 2, name: '1 month' }, { id: 3, name: '3 months' }, { id: 4, name: '6 months' }, {
                            id: 5, name: '1 year'
                        }];

                    if (vm.adGroup && vm.adGroup.marketerId)
                        campaignService.setSelectedMarketerId(vm.adGroup.marketerId);

                    hub.webapicontext.getMatchReportTypes(vm.campaign.marketerId).then(function (data) {
                        vm.matchReportTypes = data;
                        var userInfo = authService.userInfoData();

                        vm.reportData = data;
                        if (userInfo.environment === 'Development') {
                            var i = 1;
                            vm.matchReportTypes.forEach(function (mr) {
                                mr.name = 'Report Format ' + i++;
                            });
                        }


                        if (vm.adGroup.matchReportTypeId && vm.matchReportTypes) {
                            vm.matchReportType = _.find(vm.matchReportTypes, function (r) { return vm.adGroup.matchReportTypeId === r.id; });
                        }
                    });

                    vm.adGroup.isLaunch = vm.adGroup.adGroupStatusId === 1;
                    vm.adGroup.initialAdGroupStatus = vm.adGroup.adGroupStatusId;
                    vm.changeAdGroupStatus = function () {
                        if (vm.adGroup.initialAdGroupStatus === 1) {
                            vm.adGroup.initialAdGroupStatus = -1;
                            vm.adGroup.isLaunch = false;
                        }
                        else
                            vm.adGroup.isLaunch = !vm.adGroup.isLaunch;
                    };

                    function checkSelectedInventoryLists(inventoryLists, inventoryListsArray) {
                        var checkNum = 0;
                        vm.inventoryLists = inventoryLists;
                        vm.inventoryListsArray = inventoryListsArray;
                        _.forEach(vm.inventoryLists, function (blkListId) {
                            var list = _.find(vm.inventoryListsArray, function (b) { return b.id == blkListId; });
                            if (list) {
                                list.checked = true;
                                checkNum++;
                            }
                        });
                        if (vm.inventoryListsArray.length === checkNum)
                            vm.inventoryListsArray.allChecked = true;
                    }

                    vm.getPlatformAudienceName = function () {
                        if (vm.adGroup.automaticallyPushAudience
                            && !vm.adGroup.useCustomName
                            && vm.adGroup.audienceSegmentGroups
                            && vm.adGroup.audienceSegmentGroups.length
                            && vm.adGroup.audienceSegmentGroups[0].segments
                            && vm.adGroup.audienceSegmentGroups[0].segments.length) {
                            var agAdience = vm.adGroup.audienceSegmentGroups[0].segments[0];
                            var dateString;
                            if (vm.adGroup.adGroupId)
                                dateString = moment(new Date(Date.parse(vm.adGroup.createdDate))).tz('America/New_York').format('YYYY-MM-DD');
                            else
                                dateString = moment(new Date()).tz('America/New_York').format('YYYY-MM-DD');
                            return agAdience.fullName + "_" + agAdience.audienceId + "_" + dateString;
                        }
                        return "\xa0";
                    }

                    vm.platformAudienceName = vm.getPlatformAudienceName();

                    function countCategories() {
                        vm.adGroup.tooManyCategoriesSelected = false;
                        var categoriesCount = 0;
                        var advertiserCategoriesCount = 0;
                        var marketerCategoriesCount = 0;
                        var oldAudience = false;
                        angular.forEach(vm.adGroup.audienceSegmentGroups, function (group) {
                            angular.forEach(group.segments, function (elem) {
                                categoriesCount = categoriesCount + (elem.childCount === 0 ? 1 : elem.childCount + 1);
                                if (elem.combinationAudienceType == 'advertiser')
                                    advertiserCategoriesCount = advertiserCategoriesCount + (elem.childCount === 0 ? 1 : elem.childCount + 1);
                                else if (elem.combinationAudienceType == 'marketer')
                                    marketerCategoriesCount = marketerCategoriesCount + (elem.childCount === 0 ? 1 : elem.childCount + 1);
                                else
                                    oldAudience = true;
                            });
                        });

                        vm.categoriesCount = categoriesCount;
                        vm.advertiserCategoriesCount = advertiserCategoriesCount;
                        vm.marketerCategoriesCount = marketerCategoriesCount;
                        if (oldAudience && !vm.marketerCategoriesCount)
                            vm.marketerCategoriesCount = vm.categoriesCount;

                        if (vm.categoriesCount > 6000) {
                            vm.adGroup.tooManyCategoriesSelected = true;
                        }
                        $timeout(function () {
                            $('#categoriesCountValidation').trigger('input');
                        });
                    };

                    function convertLocationData(response, keyword) {
                        var results = response.filter(function (res) {
                            if (!keyword)
                                return true;

                            var kw = res.name + res.code;

                            if (kw.toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
                                return true;
                            }
                        });
                        return results.map(function (item) {
                            return {
                                type: vm.locationType, id: item.id, name: item.name, code: item.code, displayName: item.displayName ? item.displayName : (item.name ? item.name : item.code), populationPercentage: item.populationPercentage ? item.populationPercentage : 0, countryCode: (item.countryId === 233 ? "US" : "CA")
                            };
                        });
                    }

                    function filterAudiencesByPartnerAndAdvertiser() {
                        vm.adGroups = vm.rootvm.adGroups.filter(function (a) {
                            return a.marketerId == vm.selectedMarketerId;
                        });
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        var advertiser = vm.selectedAdvertiser ? vm.selectedAdvertiser : (selectedCampaign ? selectedCampaign.advertiser : null);
                        if (advertiser) {
                            vm.adGroups = vm.adGroups.filter(function (a) {
                                return a.advertiserId == advertiser.id;
                            });
                        }

                        if (selectedCampaign && vm.adGroup.channelId) {
                            vm.adGroups = vm.adGroups.filter(function (a) {
                                return a.channelId == vm.adGroup.channelId;
                            });
                        }

                        if (vm.isAudiencePage == false && vm.adGroup.adGroupId > 0) {
                            var existing_audiences = vm.adGroup;
                            existing_audiences.name = "Current Audience";
                            vm.adGroups.push(existing_audiences);
                        }

                        if (!vm.adGroup.id)
                            vm.adGroups.push(vm.adGroup);
                        else {
                            var selectedMarketerId = campaignService.getSelectedMarketerId();
                            var selectedChannelId = selectedCampaign.channelId;
                            var deviceTypes = selectedChannelId == 3 ? [1, 8] : [1, 2, 4, 16]
                            var newAudience = { name: '', marketerId: selectedMarketerId, channelId: selectedChannelId, recencyTypeId: vm.recencyTypes[2].id, recencyFrom: 0, recencyTo: 30, deviceTypes: deviceTypes, supplyTypes: [1, 2, 4], placements: [1, 2, 4], countries: ["US"], selectedLocations: [], audienceSegmentGroups: [], connectedTVInventory: [], audienceLoyality: [false, false, false, false, false, false, false, false, false], includedBrandSafetySegments: [] };
                            vm.adGroups.push(newAudience);
                        }
                    }

                    vm.showImpsPerUserPerHourDisabled = function () {
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        if (!selectedCampaign || vm.isAudiencePage || vm.adGroup.adGroupStatusId == 6 || !selectedCampaign.campaignId || !vm.adGroup.adGroupStatusId || selectedCampaign.channelId != 3)
                            return false;

                        return true;
                    };

                    if (vm.showImpsPerUserPerHourDisabled()) {
                        vm.adGroup.showImpsPerUserPerHour = false;
                        vm.frequencyChanged();
                    }

                    vm.selectMeasurementCreativeMode = function (mode) {
                        if (mode === 'single') {
                            vm.measurementSingleCreative = true;
                            vm.measurementBulkCreative = false;
                            if (!vm.adGroup.creatives || vm.adGroup.creatives.length == 0)
                                vm.adGroup.creatives = [{ trackingCodes: [{}], advertiserId: vm.adGroup.advertiserId, marketerId: vm.adGroup.marketerId, channelId: vm.adGroup.channelId }];
                        }
                        else if (mode === 'bulk') {
                            vm.measurementSingleCreative = false;
                            vm.measurementBulkCreative = true;
                        }
                    }

                    vm.showPublishersTabs = true;
                    vm.endemicFormatChanged = function (endemicFormatId) {
                        if (endemicFormatId === 1 || endemicFormatId === 2) {
                            vm.adGroup.endemicTypeId = 0;
                            vm.adGroup.maxCPM = 250;
                            vm.adGroup.dayparting = 1;
                            vm.adGroup.schedule = '';
                            vm.adGroup.scheduleModel = {};
                        }
                        if (endemicFormatId !== 0  && endemicFormatId !== 2){
                            vm.adGroup.targetEHR = false;
                        }

                        $rootScope.$broadcast("adGroupChannelChanged", 0);

                        vm.showPublishersTabs = false;
                        $timeout(function () {
                            vm.showPublishersTabs = true;
                        });
                    }

                    vm.noPublishersToShow = function() {
                        return !vm.adGroup.publishers || vm.adGroup.publishers.length == 0
                            || vm.adGroup.channelId == 16 && !vm.adGroup.publishers.find(p => !p.ehr && p.id != 2620) && !vm.adGroup.targetEHR;
                    }

                    vm.endemicTypeChanged = function (endemicTypeId) {
                        if (endemicTypeId === 1) {
                            vm.adGroup.targetEHR = false;
                            vm.adGroup.maxCPM = 15;
                            vm.filterSegments('Consumer');
                            $timeout(function () {
                                vm.activeTab = 1;
                            });
                        } else {
                            vm.adGroup.maxCPM = 250;
                        }
                    }

                    vm.selectChannel = function (channelId) {
                        vm.activeTab = 0;
                        const flights = vm.adGroup.flights;
                        vm.adGroup.flights = [];
                        //tricky part to update flight budget cap (rebind on front)
                        vm.adGroup.flights = flights.map(({$$hashKey,...other}) => other);
                        if (vm.campaign.isProact && channelId == 'measurement')
                            return;

                        if (vm.adGroup.adGroupId || channelId == 'multichannel' && !authService.hasFeature("MULTICHANNEL"))
                            return;

                        if (!vm.allowedChannel(channelId) && channelId != 'measurement')
                            return;

                        if (channelId == 9 && vm.campaign.budgetTypeId != 1)
                            return;

                        if ((channelId == 3 || channelId == 'measurement') && vm.adGroup.dynamicSequentialMessaging)
                            return;

                        var channelChange = vm.adGroup.channelId && channelId !== vm.adGroup.channelId;

                        if (channelId !== vm.adGroup.channelId) {
                            vm.adGroup.creatives = [];
                            vm.creativeSelected = vm.adGroup.creatives.length;
                            vm.staticCreativeSelected = vm.adGroup.creatives.length && vm.adGroup.creatives.find(c => !c.thirdPartyServed);
                            $rootScope.$broadcast("cleanSelectedCreatives", 0);

                            if (vm.adGroup.splits)
                                vm.adGroup.splits.forEach(function (s) { s.creativeIds = []; });

                            $timeout(function () {
                                vm.recalculateSplits();
                            });
                        }

                        if (channelId == 9 && !vm.adGroup.adGroupId && vm.adGroup.dynamicSequentialMessaging) {
                            vm.adGroup.audienceSegmentGroups = [];
                            $rootScope.$broadcast('includedGroupsChanged', {
                                includedGroups: vm.adGroup.audienceSegmentGroups, mode: 'audience', channelIndex: vm.adGroup.channelIndex
                            });
                        }

                        vm.noUpdateDataCpm = [3, 6, 9].indexOf(channelId) > -1 || moment.utc() < moment('2023-02-20 UTC');

                        vm.setRange(channelId);
                        vm.adGroup.budgetTypeId = vm.campaign.budgetTypeId;
                        vm.adGroup.isMeasurementOnly = false;
                        vm.campaign.isMultiChannel = false;
                        vm.adGroup.channelId = undefined;
                        vm.campaign.channelId = undefined;
                        vm.adGroup.channel = undefined;
                        vm.campaign.channel = undefined;

                        if (vm.campaign.isMultiChannel && channelId !== 'multichannel') {
                            for (var i = 1; i < vm.campaign.adGroups.length; i++) {
                                $rootScope.$broadcast('deleteChannel', vm.campaign.adGroups[i]);
                            }
                        }

                        if (channelId === 'multichannel') {
                            vm.campaign.isMultiChannel = true;
                            vm.adGroup.channelIndex = 0;
                            if (vm.adGroup.channelId)
                                vm.adGroup.channelId = '';
                            vm.selectedChannelChange();
                        } else if (channelId == 'measurement') {
                            vm.adGroup.isMeasurementOnly = true;
                        } else {
                            vm.campaign.isMultiChannel = false;
                            vm.adGroup.channelId = channelId;
                            vm.adGroup.channel = campaignService.getChannels().filter(function (c) { return c.id == vm.adGroup.channelId; })[0];

                            vm.campaign.channelId = channelId;
                            vm.campaign.channel = campaignService.getChannels().filter(function (c) { return c.id == vm.campaign.channelId; })[0];

                            if (channelId == 4) {
                                vm.adGroup.crossDeviceExtension = true;
                                vm.adGroup.tapAdCrossDeviceExtension = false;
                                vm.updateDataCPM();
                            } else {
                                vm.adGroup.crossDeviceExtension = false;
                                vm.updateDataCPM();
                            }

                            if (channelId === 4 || channelId === 5)
                                vm.adGroup.viewability = null;

                            if (channelId == 3)
                                vm.adGroup.deviceTypes = [1, 8];
                            else
                                vm.adGroup.deviceTypes = [1, 2, 4];

                            if (channelId === 16) {
                                vm.adGroup.endemicFormatId = 0;
                                vm.adGroup.endemicTypeId = 0;
                            } else {
                                vm.adGroup.endemicFormatId = null;
                                vm.adGroup.endemicTypeId = null;
                            }

                            vm.adGroup.publishers = [];
                            vm.adGroup.publishersHidden = [];

                            setAdGroupData(true);
                            $rootScope.$broadcast("adGroupChannelChanged", 0);
                        }

                        vm.campaign.isProactDisabled = vm.proactChannels.indexOf(channelId) === -1;

                        if (vm.adGroup.channelId == 6) {
                            vm.adGroup.emailDeploymentType = 1;
                            vm.adGroup.endDate = null;
                            vm.adGroup.UTCCalendarEndDate = null;
                            vm.parseEndDate();
                            vm.ongoingChanged();
                            vm.channelEvents = campaignService.getChannelEvents().filter(function (ev) {
                                return ev.id === 4 || ev.id === 5;
                            });
                        } else {
                            vm.adGroup.emailDeploymentType = undefined;
                        }

                        if (vm.adGroup.channelId == 6 || vm.adGroup.channelId == 3) {
                            vm.campaign.budgetTypeId = 2;
                            vm.adGroup.budgetCapSpendModeId = 1;
                        } else if (!channelChange) {
                            vm.adGroup.budgetCapSpendModeId = 2;
                        }
                    }

                    vm.isStructuredChanged = function () {
                        $rootScope.$broadcast("adGroupIsStructuredChanged");
                    }

                    vm.deleteChannel = function () {
                        $rootScope.$broadcast('deleteChannel', vm.adGroup);
                    };

                    vm.getChannelPriorityByIndex = function (index) {
                        switch (index + 1) {
                            case 1:
                                return 'Primary';
                            case 2:
                                return 'Secondary';
                            case 3:
                                return 'Third';
                            case 4:
                                return 'Fourth';
                            default:
                                return '';
                        }
                    };

                    vm.dynamicSequentialMessagingChanged = function () {
                        if (vm.adGroup.channelId == 9 && vm.adGroup.dynamicSequentialMessaging) {
                            vm.adGroup.audienceSegmentGroups = [];
                            $rootScope.$broadcast('includedGroupsChanged', {
                                includedGroups: vm.adGroup.audienceSegmentGroups, mode: 'audience', channelIndex: vm.adGroup.channelIndex
                            });
                        }
                    }

                    vm.adGroupIdsListChanged = function () {
                        if (!vm.adGroup.adGroupIdsList) {
                            vm.adGroupIdsListValidationMessage = '';
                            return;
                        }
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        var validationData = { marketerId: selectedCampaign.marketerId, advertiserId: selectedCampaign.advertiserId, adGroupIds: vm.adGroup.adGroupIdsList };
                        hub.webapicontext.validateAdGroupIdsList(validationData).then(function (data) {
                            if (data.message !== "Valid IDs")
                                vm.adGroupIdsListValidationMessage = data.message;
                            else {
                                vm.adGroupIdsListValidationMessage = '';
                                vm.adGroup.dataCPM = data.maxDataCPM + 0.15;
                            }
                        });
                    }

                    vm.sourceAdGroupsKeyword = '';
                    vm.sourceTypeChanged = function () {
                        if (vm.adGroup.sourceTypeId) {
                            var selectedCampaign = campaignService.getSelectedCampaign();
                            if (selectedCampaign.marketerId || selectedCampaign.advertiserId || selectedCampaign.campaignId)
                                campaignService.getSourceAdGroups(selectedCampaign.marketerId, selectedCampaign.advertiserId, 0, vm.sourceAdGroupsKeyword, vm.adGroup.sourceTypeId).then(function (data) {
                                    vm.sourceAdGroups = data;
                                    vm.sourceAdGroups.forEach(function (ag) {
                                        if (vm.adGroup.sourceAdGroupIds.toString().indexOf(ag.adGroupId) > -1)
                                            ag.selected = true;
                                    });
                                    if (vm.sourceAdGroups && vm.sourceAdGroups.length > 0)
                                        vm.showSourceAdGroupsList = true;
                                });
                        }
                    }

                    vm.sourceTypeTemp = {};
                    vm.sourceTypeTemp[1] = { sourceAdGroupIds: '', sourceAdGroups: [] };
                    vm.sourceTypeTemp[2] = { sourceAdGroupIds: '', sourceAdGroups: [] };

                    $scope.$watch('vm.adGroup.sourceTypeId', function (newVal, oldVal) {
                        if (vm.adGroup.dynamicSequentialMessaging && !vm.adGroup.adGroupId) {
                            if (newVal && oldVal) {
                                vm.sourceTypeTemp[oldVal].sourceAdGroupIds = vm.adGroup.sourceAdGroupIds;
                                vm.sourceTypeTemp[oldVal].sourceAdGroups = vm.adGroup.sourceAdGroups;
                                vm.adGroup.sourceAdGroupIds = vm.sourceTypeTemp[newVal].sourceAdGroupIds;
                                vm.adGroup.sourceAdGroups = vm.sourceTypeTemp[newVal].sourceAdGroups;
                            } else if (!newVal && oldVal) {
                                vm.sourceTypeTemp[oldVal].sourceAdGroupIds = vm.adGroup.sourceAdGroupIds;
                                vm.sourceTypeTemp[oldVal].sourceAdGroups = vm.adGroup.sourceAdGroups;
                                vm.adGroup.sourceAdGroupIds = '';
                                vm.adGroup.sourceAdGroups = [];
                            } else if (newVal && !oldVal) {
                                vm.adGroup.sourceAdGroupIds = vm.sourceTypeTemp[newVal].sourceAdGroupIds;
                                vm.adGroup.sourceAdGroups = vm.sourceTypeTemp[newVal].sourceAdGroups;
                            }
                            vm.sourceAdGroupIdsListChanged();
                        }
                    });

                    $scope.$watch('vm.adGroup.optimizationGoalTypeId', function (newVal, oldVal) {
                        if (newVal == 3) {
                            getSegments('Conversion');
                            if (!vm.adGroup.cpaGoalTypeId) {
                                vm.adGroup.cpaGoalTypeId = 1;
                                vm.adGroup.cpaModeId = 1;
                            }
                        } else {
                            vm.adGroup.cpaGoalTypeId = null;
                            vm.adGroup.cpaModeId = null;
                        }
                    });

                    if (vm.adGroup.splits && (vm.adGroup.editor == 'v1' || vm.adGroup.channelId != 16)) {
                        vm.adGroup.splits.forEach(function (s) {
                            if (s.conditionsJSON) {
                                s.conditions = JSON.parse(s.conditionsJSON);
                                s.resPreview = s.conditions[0].name + ': ' + s.conditions[0].resName.split(',')[0] + '...';
                            }
                        });
                    }

                    function createNewSplit() {
                        if (!vm.adGroup.splits)
                            vm.adGroup.splits = [];
                        const newId = vm.adGroup.splits.length > 0 ? _.maxBy(vm.adGroup.splits, 'id').id : 0;
                        const split = { id: newId + 1, order: vm.adGroup.splits.length + 1, isNew: true, statusId: 0, creativeIds: _.map(vm.adGroup.creatives, 'id'), conditions: [] };
                        return split;
                    }

                    vm.advancedOptimizationChanged = function () {
                        if (vm.adGroup.advancedOptimization && (!vm.adGroup.splits || vm.adGroup.splits.length == 0))
                            vm.editSplit();

                        if (!vm.adGroup.advancedOptimization)
                            vm.adGroup.splitsInvalid = false;
                    }

                    vm.optimizationGoalTypeChanged = function () {
                        vm.adGroup.splits.forEach(function (split) {
                            split.bid = 0;
                        });
                        vm.validateSplits();
                    }

                    $rootScope.$on('creativeAdded', function (e, creative) {
                        if (vm.adGroup.splits)
                            vm.adGroup.splits.forEach(function (split) {
                                if (split.creativeIds.indexOf(creative.id) === -1) {
                                    split.creativeIds.push(creative.id);
                                    vm.validateSplits();
                                }
                            });
                    });

                    $rootScope.$on('creativeRemoved', function (e, creative) {
                        if (vm.adGroup.splits)
                            vm.adGroup.splits.forEach(function (split) {
                                var i = split.creativeIds.indexOf(creative.id);
                                if (i > -1) {
                                    split.creativeIds.splice(i, 1);
                                    vm.validateSplits();
                                }
                            });
                    });

                    vm.removeSplit = function (split) {
                        var splits = angular.copy(vm.adGroup.splits);
                        vm.adGroup.splits = [];
                        splits.forEach(function (s) {
                            if (s.id == split.id)
                                s.statusId = 1;
                            vm.adGroup.splits.push(s);
                        });

                        $timeout(function () {
                            vm.recalculateSplits();
                        })
                    }

                    vm.editSplit = function (split) {
                        var isEdit = true;
                        if (!split) {
                            isEdit = false;
                            split = createNewSplit();
                            vm.adGroup.splits.push(split);
                            vm.recalculateSplits();
                        } else {
                            split = vm.adGroup.splits.find(s => s.id == split.id);
                            split.isEdit = true;

                            var modalOptions = {
                                templateUrl: 'views/campaign/editors/split-editor.html',
                                animation: false,
                                controller: 'splitEditorController',
                                size: 'lg',
                                resolve: { split: split, adGroup: vm.adGroup, inventoryListsArray: function () { return vm.inventoryListsArray; } }
                            };
                            dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (res) {
                                var splits = angular.copy(vm.adGroup.splits);
                                vm.adGroup.splits = [];
                                splits.forEach(function (s) {
                                    if (s.id !== res.id)
                                        vm.adGroup.splits.push(s);
                                });
                                vm.adGroup.splits.push(res);

                                vm.recalculateSplits();
                            });
                        }
                    };

                    vm.recalculateSplits = function () {
                        const splits = (vm.adGroup.splits || []).filter(function (s) { return s.statusId == 0; });
                        vm.adGroup.splitsTotalBudget = _.sumBy(splits, 'targetBudget');
                        vm.adGroupSplitsLength = splits.length;
                        vm.validateSplits();
                    }

                    vm.validateSplits = function () {
                        vm.adGroup.splitsInvalid = false;
                        if (!vm.adGroup.advancedOptimization || vm.adGroup.editor != 'v1' && vm.adGroup.channelId == 16)
                            return;

                        vm.adGroup.splits.filter(function (s) { return s.statusId == 0; }).forEach(function (split) {
                            split.invalidName = !split.name || vm.adGroup.splits.find(s => s.name === split.name && s.id !== split.id);
                            split.invalidBid = !vm.adGroup.optimizationGoalTypeId && (!split.bid || split.bid < 0 || split.bid > 999);
                            split.invalidConditions = !split.conditions || split.conditions.length === 0;
                            split.invalidCreatives = !split.creativeIds || split.creativeIds.length === 0;
                            split.invalidTargetBudget = !split.targetBudget || split.targetBudget < 1 || split.targetBudget > 99;
                            var viewability = split.conditions.find(function (c) { return c.field === 'predicted_iab_view_rate'; });
                            if (viewability)
                                split.invalidViewability = !viewability.value[0] || viewability.value[0] < 1 || viewability.value[0] > 99;

                            if (split.invalidName || split.invalidBid || split.invalidTargetBudget || vm.adGroup.splitsTotalBudget !== 100 || split.invalidConditions || split.invalidCreatives || split.invalidViewability)
                                vm.adGroup.splitsInvalid = true;
                        });
                    }

                    vm.invalidSplitField = function (field) {
                        return vm.adGroup.splits.filter(function (s) { return s.statusId == 0; }).find(s => s['invalid' + field]);
                    }

                    vm.getCreativeName = function (id) {
                        var creative = vm.adGroup.creatives.find(c => c.id === id);
                        if (creative)
                            return creative.name;
                    }

                    if (vm.adGroup.splits)
                        vm.recalculateSplits();

                    dragulaService.options($scope, 'columns', {
                        revertOnSpill: true
                    });

                    $scope.$on('splitsBag.over', function (e, el, source) {
                        if (source[0].id === 'splitsField')
                            source[0].style = 'border: 1px dashed #dddddd;';
                        else
                            $('#splitsField')[0].style = '';
                    });

                    $scope.$on('splitsBag.out', function (e, el, source) {
                        $('#splitsField')[0].style = '';
                    });

                    $scope.$on('splitsBag.drop', function (e, el, target, source) {
                        if (source[0].id !== 'splitsField')
                            dragulaService.find($scope, 'splitsBag').drake.cancel(true);
                        else
                            $timeout(function () {
                                var order = 0;
                                while ($("[name='split']")[order]) {
                                    var split = vm.adGroup.splits.find(function (s) { return 'split_' + s.id === $("[name='split']")[order].id; });
                                    split.order = ++order;
                                }
                            });
                    });

                    if (!vm.adGroup.sourceAdGroupIds)
                        vm.adGroup.sourceAdGroupIds = '';

                    if (!vm.adGroup.sourceAdGroups)
                        vm.adGroup.sourceAdGroups = [];

                    if (vm.adGroup.dynamicSequentialMessaging && vm.adGroup.adGroupId)
                        vm.sourceTypeChanged();

                    vm.sourceAdGroupWarnings = [];

                    if (vm.adGroup.sourceAdGroupIds) {
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        campaignService.getSourceAdGroups(selectedCampaign.marketerId, selectedCampaign.advertiserId, 0, vm.sourceAdGroupsKeyword, vm.adGroup.sourceTypeId, vm.adGroup.sourceAdGroupIds).then(function (data) {
                            if (data) {
                                vm.adGroup.sourceAdGroups = data;
                                vm.initialSourceAdGroups = angular.copy(vm.adGroup.sourceAdGroups);
                            }
                            refreshWarnings()
                        });
                    }

                    vm.addSourceAdGroup = function (ag) {
                        if (ag.selected)
                            return;
                        vm.adGroup.sourceAdGroups.push(ag);
                        vm.sourceAdGroups = vm.sourceAdGroups.filter(function (ag) {
                            return !vm.adGroup.sourceAdGroups.find(function (a) { return ag.adGroupId == a.adGroupId; });
                        });
                        vm.sourceAdGroupIdsListChanged();
                        vm.sourceAdGroupsKeyword = '';
                        vm.sourceTypeChanged();
                    }

                    vm.removeSourceAdGroup = function ($event, ag) {
                        var checked = $event.target.checked;
                        var agIndex = vm.adGroup.sourceAdGroups.indexOf(ag);

                        if (!checked)
                            vm.adGroup.sourceAdGroups.splice(agIndex, 1);

                        vm.sourceAdGroupIdsListChanged();
                        vm.sourceTypeChanged();
                    }

                    function refreshWarnings() {
                        vm.sourceAdGroupWarnings = [];
                        vm.sourceAdGroupWarningIds = [];
                        vm.adGroup.sourceAdGroups.forEach(function (ag) {
                            if (ag.targetAdGroupId && !vm.sourceAdGroupWarnings.find(function (w) { return w.adGroupId == ag.adGroupId; }) && ag.targetAdGroupId != vm.adGroup.adGroupId) {
                                vm.sourceAdGroupWarningIds.push(ag.adGroupId);
                            }
                        });


                        if (vm.initialSourceAdGroups) {
                            var adGroupIds = [];
                            vm.initialSourceAdGroups.forEach(function (ag) {
                                if (vm.adGroup.sourceAdGroups.length == 0 || !vm.adGroup.sourceAdGroups.find(function (a) { return ag.adGroupId == a.adGroupId; })) {
                                    adGroupIds.push(ag.adGroupId);
                                }
                            });
                            if (adGroupIds.length == 1) {
                                const message = 'Ad Group ' + adGroupIds[0] + ' will be removed from this Trigger BUT the excluded trigger audience will not be automatically removed from Ad Group ' + adGroupIds[0] + '. This can be manually modified on Ad Group ' + adGroupIds[0] + '.';
                                vm.sourceAdGroupWarnings.push({ message: message });
                            } else if (adGroupIds.length > 1) {
                                const message = 'Ad Groups ' + adGroupIds.join(',') + ' will be removed from this Trigger BUT the excluded trigger audiences will not be automatically removed from Ad Groups ' + adGroupIds.join(',') + '. This can be manually modified on Ad Groups ' + adGroupIds.join(',') + '.';
                                vm.sourceAdGroupWarnings.push({ message: message });
                            }
                        }
                    }

                    vm.sourceAdGroupIdsListChanged = function () {
                        vm.adGroup.sourceAdGroupIds = _.map(vm.adGroup.sourceAdGroups, 'adGroupId').join(',');
                        if (!vm.adGroup.sourceAdGroupIds) {
                            vm.sourceAdGroupIdsListValidationMessage = '';
                            vm.sourceAdGroupIdsListConfirmationMessage = '';
                            vm.adGroup.audienceSegmentGroups = [];
                            refreshWarnings();
                            $rootScope.$broadcast('includedGroupsChanged', {
                                includedGroups: vm.adGroup.audienceSegmentGroups, mode: 'audience', channelIndex: vm.adGroup.channelIndex
                            });
                            return;
                        }
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        var validationData = {isLegacy: true, channelId: vm.adGroup.channelId, sourceTypeId: vm.adGroup.sourceTypeId, adGroupId: vm.adGroup.adGroupId, marketerId: selectedCampaign.marketerId, advertiserId: selectedCampaign.advertiserId, adGroupIds: _.map(vm.adGroup.sourceAdGroups, 'adGroupId').join(',') };
                        hub.webapicontext.validateSourceAdGroupIdsList(validationData).then(function (data) {
                            if (data.message !== "Valid IDs") {
                                vm.sourceAdGroupIdsListValidationMessage = data.message;
                                vm.sourceAdGroupIdsListValid = false;
                                vm.adGroup.sourceAdGroupsInvalid = true;
                                refreshWarnings();
                            }
                            else {
                                vm.sourceAdGroupIdsListValidationMessage = '';
                                vm.sourceAdGroupIdsListValid = true;
                                vm.adGroup.sourceAdGroupsInvalid = false;
                                vm.adGroup.xandrMaxDataCPM = data.xandrMaxDataCPM;
                                vm.adGroup.emailMaxDataCPM = data.emailMaxDataCPM;
                                vm.adGroup.triggerAudienceDataCPM = vm.adGroup.channelId != 6 ? data.xandrMaxDataCPM : data.emailMaxDataCPM;
                                vm.adGroup.triggerAudienceDataDataSourceID = data.dataSourceId;
                                vm.sourceAdGroupIdsListConfirmationMessage = 'Ad Group will target users triggered by Ad Groups ' + vm.adGroup.sourceAdGroupIds + '. These impressions will be associated with a $' + (Math.round(vm.adGroup.triggerAudienceDataCPM * 100) / 100).toFixed(2) + (vm.adGroup.channelId != 6 ? ' Data CPM ' : ' Cost Per Send');
                                vm.updateDataCPM();
                                refreshWarnings();
                            }
                        });
                    }

                    vm.removeCondition = function (condition) {
                        vm.adGroup.conditions.splice($.inArray(condition, vm.adGroup.conditions), condition);
                    }

                    vm.addCondition = function () {
                        vm.adGroup.conditions.push({ action: 'Viewed' });
                    }

                    vm.triggerEngagementIdChanged = function () {
                        if (vm.adGroup.triggerEngagementId == 3 && !vm.adGroup.conditions) {
                            vm.adGroup.conditions = [{ action: 'Viewed' }];
                            vm.adGroup.logicCondition = 'AND';
                        } else {
                            vm.adGroup.conditions = null;
                            vm.adGroup.logicCondition = null;
                        }
                    }

                    vm.showImpsPerUserPerDayDisabled = function () {
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        if (!selectedCampaign || vm.isAudiencePage || vm.adGroup.adGroupStatusId == 6 || !selectedCampaign.campaignId || !vm.adGroup.adGroupStatusId || vm.adGroup.channelId != 3)
                            return false;

                        return true;
                    };

                    if (vm.showImpsPerUserPerDayDisabled()) {
                        vm.adGroup.showImpsPerUserPerDay = false;
                        vm.frequencyChanged();
                    }

                    vm.showImpsPerUserPerWeekDisabled = function () {
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        if (!selectedCampaign || vm.isAudiencePage || vm.adGroup.adGroupStatusId == 6 || !selectedCampaign.campaignId || !vm.adGroup.adGroupStatusId || vm.adGroup.channelId != 3)
                            return false;

                        return true;
                    };

                    if (vm.showImpsPerUserPerWeekDisabled()) {
                        vm.adGroup.showImpsPerUserPerWeek = false;
                        vm.frequencyChanged();
                    }

                    function getAllInventoryLists() {
                        var advertiserId = vm.adGroup.advertiserId ? vm.adGroup.advertiserId : vm.selectedCampaign.advertiserId;
                        hub.webapicontext.getInventoryLists(null, vm.adGroup.marketerId ? vm.adGroup.marketerId : vm.selectedCampaign.marketerId).then(function (d) {
                            vm.inventoryListsArray = d.filter(function (il) { return il.advertiserId === advertiserId || !il.advertiserId || il.internal; });
                            checkSelectedInventoryLists(vm.inventoryLists, vm.inventoryListsArray);
                            vm.selectedInventoryLists = vm.inventoryListsArray ? vm.inventoryListsArray.filter(function (il) { return il.checked }) : [];
                        });
                    }

                    function getLocations(keyword) {
                        return context.external.getLocationInfo(vm.adGroup.countries, vm.locationType, keyword, false).then(function (response) {
                            return convertLocationData(response, keyword);
                        });
                    }

                    function includedChanged() {
                        if (vm.includeLocation.id == 1 && vm.locationType == "PostalCode") {

                            resourceService.log('Locations', 'audience', 'postalCodeIncludeOnly');

                            vm.includeLocation = vm.locationOptions[0];
                            return;
                        }
                        if (vm.includeLocation.id == 1 && _.filter(vm.adGroup.selectedLocations, {
                            'type': 'PostalCode'
                        }).length > 0) {
                            resourceService.log('Locations', 'audience', 'cantExcludePostalCode');
                            vm.includeLocation = vm.locationOptions[0];
                            return;
                        }
                        vm.adGroup.selectedLocations.forEach(function (item) {

                            item.excluded = (vm.includeLocation.id == 1);
                        });
                        vm.adGroup.locationsExcluded = (vm.includeLocation.id == 1);
                    }

                    function loadPopulationData(locations) {
                        context.external.getPopulationData(locations).then(function (response) {

                            var hasPopulationData = false;
                            response.forEach(function (item) {
                                var st = locations.filter(function (it) {
                                    return it.type == item.type && it.locationId == item.locationId;
                                });
                                if (st != null && st.length > 0 && item.populationPercentage) {
                                    hasPopulationData = true;
                                    st[0].populationPercentage = item.populationPercentage;
                                }
                            });

                            if (hasPopulationData) {
                                $rootScope.$broadcast('PopulationDataLoaded', response);
                            }
                        },
                            function (err) {
                                console.log(err);
                            }

                        );
                    }

                    function locationTypeChanged() {
                        if (vm.locationType == 'PostalCode') {
                            vm.locationOptions[1].disabled = true;
                            vm.includeLocation = vm.locationOptions[0];
                        }
                        else
                            vm.locationOptions[1].disabled = false;
                    }

                    function processFiles(flowFile) {
                        if (vm.includeLocation.id == 1) {
                            resourceService.log('Locations', 'audience', 'postalCodeIncludeOnly');
                            return;
                        }
                        if (!typeCanBeAdded('PostalCode')) {
                            resourceService.log('Locations', 'audience', 'multipleGeoTargeting');
                            return;
                        }

                        var fileReader = new FileReader();
                        fileReader.onload = function () {
                            var postalCodes = this.result;

                            if (postalCodes) {
                                var codes = postalCodes.split("\r\n");
                                if (codes.length == 0 || (isNaN(parseFloat(codes[0])) || !isFinite(codes[0]))) {

                                    resourceService.log('Locations', 'audience', 'invalidPostCodesUploadFormat');
                                    return;
                                }
                                context.external.uploadLocations(vm.adGroup.countries, codes).then(function (response) {

                                    var data = convertLocationData(response, '');
                                    data.forEach(function (item) {
                                        if (vm.adGroup.selectedLocations.filter(function (it) {
                                            return it.type == item.type && it.locationId == item.id;
                                        }).length == 0)
                                            vm.adGroup.selectedLocations.push({
                                                location: item.displayName, locationId: item.id, type: item.type, excluded: false, populationPercentage: item.populationPercentage ? item.populationPercentage : 0, countryCode: item.countryCode
                                            });
                                    });
                                    vm.postalCodeUpload = '';
                                },
                                    function (err) {
                                        console.log(err);
                                    }

                                );
                            }
                        };
                        fileReader.readAsText(flowFile.file);
                        return;
                    }

                    function selectAllInventoryLists() {
                        if (vm.inventoryListsArray.allChecked) {
                            _.forEach(vm.inventoryListsArray, function (b) {
                                b.checked = true; selectInventoryList(b);
                            });
                        }
                        else {
                            _.forEach(vm.inventoryListsArray, function (b) {
                                b.checked = false;
                                vm.inventoryLists.splice(vm.inventoryLists.indexOf(b.id), 1);
                            });
                        }
                    }

                    function selectInventoryList(b) {
                        if (!vm.inventoryLists)
                            vm.inventoryLists = [];
                        if (b.checked && !(_.includes(vm.inventoryLists, b.id))) {
                            vm.inventoryLists.push(b.id);
                        }
                        if (!b.checked && _.includes(vm.inventoryLists, b.id)) {
                            vm.inventoryLists.splice(vm.inventoryLists.indexOf(b.id), 1);
                        }
                        if (vm.inventoryLists.length !== vm.inventoryListsArray.length)
                            vm.inventoryListsArray.allChecked = false;
                        else
                            vm.inventoryListsArray.allChecked = true;

                        vm.selectedInventoryLists = vm.inventoryListsArray.filter(function (il) { return il.checked && (!vm.inventoryListTypeFilter || il.inventoryListType == vm.inventoryListTypeFilter) });
                    }

                    vm.inventoryListTypeFilterChanged = function () {
                        vm.selectedInventoryLists = vm.inventoryListsArray.filter(function (il) { return il.checked && (!vm.inventoryListTypeFilter || il.inventoryListType == vm.inventoryListTypeFilter) });
                    }

                    vm.selectedInventoryLists = vm.inventoryListsArray ? vm.inventoryListsArray.filter(function (il) { return il.checked }) : [];

                    function selectedRecencyFromChanged() {
                        vm.adGroup.recencyFrom = vm.recencyFrom.value;
                        if (vm.adGroup.recencyFrom >= vm.adGroup.recencyTo) {
                            var recTo = vm.recencyToValues.find(function (r) {
                                return r.value == vm.recencyFrom.value;
                            });
                            vm.recencyTo = vm.recencyToValues[vm.recencyToValues.indexOf(recTo) + 1];
                            vm.adGroup.recencyTo = vm.recencyTo.value;
                        }
                    }

                    function selectedRecencyToChanged() {
                        vm.adGroup.recencyTo = vm.recencyTo.value;
                        if (vm.adGroup.recencyFrom >= vm.adGroup.recencyTo) {
                            var recFrom = vm.recencyFromValues.find(function (r) {
                                return r.value == vm.recencyTo.value;
                            });
                            vm.recencyFrom = vm.recencyFromValues[vm.recencyFromValues.indexOf(recFrom) - 1];
                            vm.adGroup.recencyFrom = vm.recencyFrom.value;
                        }
                    }

                    vm.combinationAudienceTypes = [{ id: 1, name: 'Marketer Audience' }, { id: 2, name: 'Advertiser Audience' }];

                    vm.customSegmentsLoaded = false;

                    vm.addListedAudiences = function () {
                        if (!vm.customSegmentsLoaded) {
                            getSegments('Custom', true);
                            vm.customSegmentsLoaded = true;
                        } else {
                            vm.activeTab = 5;
                            var audienceIds = vm.adGroup.listedAudienceIds.replace(' ', '').split(',');
                            $scope.$broadcast('listedAudiencesAdded', audienceIds);
                        }
                    }

                    function getPublishers() {
                        if (!vm.adGroup.publishers) {
                            vm.adGroup.publishers = [];
                            vm.adGroup.publishersHidden = [];
                        }
                        if (!vm.adGroup.monetizationPlacements) {
                            vm.adGroup.monetizationPlacements = [];
                        }

                        hub.webapicontext.getPublishersForEndemic().then(function (publishers) {
                            if (vm.adGroup.channelId == 25) {
                                vm.publishers = publishers.filter(p => p.ehr);
                            } else {
                                vm.publishers = publishers;
                            }

                            vm.publishers.forEach(function (pub) {
                                var pubFound = vm.adGroup.publishers.find(p => p.id === pub.id);
                                if (pubFound) {
                                    pubFound.ehr = pub.ehr;
                                    pubFound.dtcEndemic = pub.dtcEndemic;
                                    pubFound.directSoldOnly = pub.directSoldOnly;
                                }
                            });

                            vm.publisherExcluded = !vm.adGroup.publishers || vm.adGroup.publishers.length === 0 ? null : vm.adGroup.publishers[0].excluded;

                            vm.publishers.forEach(function (pub) {
                                var pubFound = vm.adGroup.publishers.find(p => p.id === pub.id);
                                if (pubFound) {
                                    pub.selected = true;
                                    pub.excluded = pubFound.excluded;
                                    pubFound.name = pub.name;
                                }
                                pub.displayTypeFound = false;
                                pub.eNewsTypeFound = false;
                                pub.placements.forEach(function (place) {
                                    var placeFound = vm.adGroup.monetizationPlacements.find(p => p.id === place.id);
                                    if (placeFound) {
                                        place.selected = true;
                                        place.excluded = placeFound.excluded;
                                        placeFound.name = place.name;
                                        pub.open = true;
                                    }

                                    place.eNewsTypeFound = place.adUnits.find(a => a.mediaType === 4);

                                    place.adUnits.forEach(function (adUnit) {
                                        const adUnitFound = vm.adGroup.adUnits?.find(a => a.id === adUnit.id);
                                        if (adUnitFound) {
                                          adUnitFound.name = adUnit.name;
                                          adUnit.selected = true;
                                          adUnit.excluded = adUnitFound.excluded;
                                          place.open = true;
                                        }

                                        if ([1,null].indexOf(adUnit.mediaType) > -1) {
                                            pub.displayTypeFound = true;
                                        }
                                        if ([4].indexOf(adUnit.mediaType) > -1) {
                                            pub.eNewsTypeFound = true;
                                        }
                                    });
                                });
                            });
                            vm.displayPublishers = vm.publishers.filter(p => p.displayTypeFound);
                            vm.eNewsPublishers = vm.publishers.filter(p => p.eNewsTypeFound);
                        });
                    }

                    function getSegmentsWithParents(audienceType) {
                        const flt = {
                            audienceType: audienceType,
                            isAudiencePage: false,
                            marketerId: vm.campaign.marketerId,
                            channelId: vm.adGroup.channelId,
                            advertiserId: vm.campaign.advertiserId
                        };
                        const pager = {
                            pageSize: 100,
                            pageNumber: 1
                        };
                        hub.webapicontext.searchAudiencesWithParents(flt, pager).then(data => {
                            var segments = [];
                            const filter = 'Prescriber';
                            data.list = data.list.filter(function (r) {
                                return (r.emailPrice || vm.adGroup.channelId != 6) && (r.audienceType == filter || filter == 'retargeting' && (r.audienceType == 'Web' || r.audienceType == 'Conversion')) && (vm.adGroup.channelId !== 3 || r.audienceType == 'Conversion' || r.audienceType == 'Demographics' || r.socialCpm)
                                    && r.apnSegmentId || r.audienceType == 'Inventory' && (filter == 'InventoryByPublisher' || filter == 'InventoryByCategory') || r.isParent;
                            });
                            data.list.forEach(function (s) {
                                var segment = {
                                    dataSourceId: s.dataSourceId,
                                    audienceId: s.id,
                                    accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                    audienceType: s.audienceType,
                                    name: s.name,
                                    isInternal: s.isInternal,
                                    fullName: s.name,
                                    socialCpm: s.socialCpm,
                                    emailPrice: s.emailPrice,
                                    displayCpm: s.displayCpm,
                                    id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                    fullIdsList: s.isParent ? '' : (s.apnSegmentId ? (s.apnSegmentId).toString() : (s.id).toString()),
                                    activeReach: s.matchedDeviceRecords,
                                    createdDate: s.createdDate,
                                    isParent: s.isParent,
                                    childCount: s.isParent ? 1 : 0
                                };

                                segments.push(segment);
                            });

                            $rootScope.$broadcast('segmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                        });
                    }

                    $scope.$on('prescriberParentToggled', function (e, segment) {
                        getSegments('Prescriber', null, segment)
                    });

                    function getSegments(filter, listedAudiences, toggledSegment) {
                        hub.webapicontext.getAudiences(filter, vm.campaign.marketerId, false, vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25 ? 1 : vm.adGroup.channelId, vm.adGroup.advertiserId, 1).then(function (data) {
                            if (filter === undefined) {
                                vm.allSegments = data;
                                $scope.$broadcast('allSegmentsLoaded', data);
                            }
                            if (!filter) {
                                filter = 'Prescriber';
                                vm.activeTab = 0;
                            }
                            const segments = [];

                            data = data.filter(function (s) { return s.accountId == 1 || s.marketerId === 1 && (!s.advertiserId || vm.adGroup.advertiserId === s.advertiserId) || (s.marketerId === vm.adGroup.marketerId || s.accountId === vm.adGroup.marketerId) && (!s.advertiserId || vm.adGroup.advertiserId === s.advertiserId); });

                            if (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25) {
                                data = data.filter(function (s) { return s.dataFileUrl || s.audienceType === 'Custom'; });
                                if (vm.adGroup.endemicTypeId === 1 && filter === 'Custom') {
                                    data = data.filter(function (s) { return s.audienceTargetId === 2; });
                                }
                            }

                            data = data.filter(function (s) { return vm.noUpdateDataCpm || s.dataSourceId || filter == 'InventoryByPublisher' || filter == 'InventoryByCategory'; });

                            if (filter == 'Consumer' && vm.adGroup.channelId === 3) {     // channelId 3 is FaceBook/Insta
                                data = data.filter(function (s) { return s.isInternal && s.audienceType == filter && s.name.indexOf("Lasso Modeled Audiences") >= 0 });
                            } else {
                                data = data.filter(function (r) {
                                    return (
                                      r.emailPrice
                                      || vm.adGroup.channelId != 6
                                      )
                                      && (
                                        r.audienceType == filter
                                        || filter == 'retargeting'
                                        && (
                                          r.audienceType == 'Web'
                                          || r.audienceType == 'Conversion'
                                        )
                                        || filter === 'Conversion'
                                        && (
                                          featureService.isActive('UNIVERSAL_PIXELS')
                                          && r.audienceType === 'UniversalPixelAudienceConversion'
                                        )
                                      )
                                      && (
                                        vm.adGroup.channelId !== 3
                                        || r.audienceType == 'Conversion'
                                        || r.audienceType == 'Demographics'
                                        || r.socialCpm
                                      )
                                      && r.apnSegmentId
                                      || r.audienceType == 'Inventory'
                                      && (
                                        filter == 'InventoryByPublisher'
                                        || filter == 'InventoryByCategory'
                                      )
                                      || filter == 'Location';
                                });
                            }
                            data.forEach(function (s) {
                                if (filter == 'InventoryByPublisher')
                                    s.name = s.dealNamePublisher;
                                else if (filter == 'InventoryByCategory')
                                    s.name = s.dealNameCategory;

                                if (s.name) {

                                    var names = s.name.split('>');

                                    if (names.length > 1) {
                                        var parent = segments.find(function (seg) { return seg.name == names[0]; });
                                        if (!parent) {
                                            parent = { name: names[0], fullName: names[0], segments: [], fullIdsList: '' };
                                            segments.push(parent);
                                        }

                                        var child = parent.segments.find(function (seg) { return seg.name == names[1] && s.name != seg.fullName; });
                                        var childExists = true;
                                        if (!child) {
                                            child = {
                                                dataSourceId: s.dataSourceId,
                                                channelIndex: vm.adGroup.channelIndex,
                                                audienceId: s.id,
                                                accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                                audienceType: s.audienceType,
                                                name: names[1],
                                                isInternal: s.isInternal,
                                                fullName: names[0] + '>' + names[1],
                                                socialCpm: s.socialCpm,
                                                emailPrice: s.emailPrice,
                                                displayCpm: s.displayCpm,
                                                id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                                fullIdsList: (parent.id + '>' + s.apnSegmentId).toString(),
                                                activeReach: s.matchedDeviceRecords,
                                                createdDate: s.createdDate
                                            };
                                            childExists = false;
                                        }
                                        if (names.length > 2) {
                                            if (!childExists) {
                                                child.segments = [];
                                                child.id = null;
                                                child.audienceId = null;
                                                child.fullIdsList = '';
                                            }
                                            var seg = {
                                                dataSourceId: s.dataSourceId,
                                                channelIndex: vm.adGroup.channelIndex,
                                                audienceId: s.id,
                                                accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                                audienceType: s.audienceType,
                                                name: names[2],
                                                isInternal: s.isInternal,
                                                fullName: s.name,
                                                displayCpm: s.displayCpm,
                                                socialCpm: s.socialCpm,
                                                emailPrice: s.emailPrice,
                                                id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                                fullIdsList: (child.id + '>' + s.apnSegmentId).toString(),
                                                activeReach: s.matchedDeviceRecords,
                                                createdDate: s.createdDate
                                            };

                                            var segChildExist = child.segments.find(function (ss) { return ss.name == names[2]; });
                                            if (names.length < 4 || !segChildExist)
                                                child.segments.push(seg);
                                            child.childCount = child.segments.length;

                                            if (names.length > 3) {
                                                seg = child.segments.find(function (ss) { return ss.name == names[2]; });
                                                if (!seg.segments) {
                                                    seg.segments = [];
                                                    seg.id = null;
                                                    seg.audienceId = null;
                                                    seg.fullIdsList = '';
                                                    seg.fullName = names[0] + '>' + names[1] + '>' + names[2];
                                                }
                                                var newSeg = {
                                                    dataSourceId: s.dataSourceId,
                                                    channelIndex: vm.adGroup.channelIndex,
                                                    audienceId: s.id,
                                                    accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                                    audienceType: s.audienceType,
                                                    name: names[3],
                                                    isInternal: s.isInternal,
                                                    fullName: s.name,
                                                    displayCpm: s.displayCpm,
                                                    socialCpm: s.socialCpm,
                                                    emailPrice: s.emailPrice,
                                                    id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                                    fullIdsList: (child.id + '>' + s.apnSegmentId).toString(),
                                                    activeReach: s.matchedDeviceRecords,
                                                    createdDate: s.createdDate
                                                };
                                                seg.segments.push(newSeg);
                                                seg.childCount = seg.segments.length;
                                            }
                                        }
                                        if (!childExists)
                                            parent.segments.push(child);

                                        parent.childCount = parent.segments.length;
                                        parent.displayCpm = 0;
                                        parent.segments.forEach(function (seg) { if (seg.displayCpm > parent.displayCpm) parent.displayCpm = seg.displayCpm; });
                                        parent.emailPrice = 0;
                                        parent.socialCpm = 0;
                                        parent.segments.forEach(function (seg) {
                                            if (seg.emailPrice > parent.emailPrice)
                                                parent.emailPrice = seg.emailPrice;
                                            if (seg.socialCpm > parent.socialCpm)
                                                parent.socialCpm = seg.socialCpm;
                                        });
                                    } else {
                                        var segment = {
                                            dataSourceId: s.dataSourceId,
                                            channelIndex: vm.adGroup.channelIndex,
                                            audienceId: s.id,
                                            accountId: s.advertiserId ? s.advertiserId : s.marketerId,
                                            name: s.name,
                                            isInternal: s.isInternal,
                                            fullName: s.name,
                                            audienceType: s.audienceType,
                                            displayCpm: s.displayCpm,
                                            socialCpm: s.socialCpm,
                                            emailPrice: s.emailPrice,
                                            id: s.apnSegmentId ? s.apnSegmentId : s.id,
                                            fullIdsList: (s.apnSegmentId ? s.apnSegmentId : s.id).toString(),
                                            activeReach: s.matchedDeviceRecords,
                                            createdDate: s.createdDate
                                        };
                                        segments.push(segment);
                                    }
                                }
                            });

                            $timeout(function () {
                                if (filter == 'Location')
                                    $rootScope.$broadcast('locationGroupsSegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                                else if (filter == 'Conversion')
                                    $rootScope.$broadcast('conversionSegmentsChanged', { data: segments.filter(function (s) { return s.advertiserId === vm.campaign.advertiserId || !s.advertiserId; }), channelIndex: vm.adGroup.channelIndex });
                                else if (filter == 'Brand Safety')
                                    $rootScope.$broadcast('brandSafetySegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                                else if (filter == 'InventoryByPublisher') {
                                    $rootScope.$broadcast('inventoryByPublisherSegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                                    vm.publisherDealsExist = segments.length > 0;
                                }
                                else if (filter == 'InventoryByCategory') {
                                    $rootScope.$broadcast('inventoryByCategorySegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                                    vm.categoryDealsExist = segments.length > 0;
                                }
                                else {
                                    if (filter === 'channelEvent') {
                                        var segment = { channelIndex: vm.adGroup.channelIndex, audienceId: -1, name: 'Primary Channel Audience', isInternal: true, fullName: 'Primary Channel Audience', audienceType: 'channelEvent', id: -1, fullIdsList: '-1' };
                                        if (vm.adGroup.primaryAdGroup)
                                            segment.displayCpm = vm.adGroup.primaryAdGroup.dataCPM;
                                        segments.push(segment);
                                    }

                                    if (toggledSegment) {
                                        const ts = segments.find(s => s.name == toggledSegment.name);
                                        if (ts) {
                                            ts.showSpinner = false;
                                            ts.isOpen = true;
                                        }
                                    }

                                    vm.customSegmentsLoaded = filter === 'Custom';
                                    $rootScope.$broadcast('segmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex, listedAudiences: listedAudiences });
                                }
                            });
                        });
                    }

                    vm.addPublisher = function (p, excluded) {
                        if (vm.publisherExcluded !== null && vm.publisherExcluded !== excluded)
                            return;

                        if (!vm.adGroup.publishers) {
                          vm.adGroup.publishers = [];
                          vm.adGroup.publishersHidden = [];
                        }

                        p.selected = true;
                        p.excluded = excluded;
                        var item = vm.adGroup.publishers.find(i => i.id === p.id);
                        if (item)
                            item.excluded = excluded;
                        else
                            vm.adGroup.publishers.push(p);

                        vm.publisherExcluded = excluded;
                    };

                    vm.removePublisher = function (p) {
                        p.selected = false;
                        var item = vm.adGroup.publishers.find(i => i.id === p.id);
                        if (item)
                            vm.adGroup.publishers.splice($.inArray(item, vm.adGroup.publishers), 1);

                        var pub = vm.publishers.find(i => i.id === p.id);
                        if (pub)
                            pub.selected = false;

                        if (vm.adGroup.publishers.length == 0)
                            vm.publisherExcluded = null;
                    };

                    vm.removeAllPublishers = function () {
                        vm.adGroup.publishers.forEach(function (p) {
                            $timeout(function () { vm.removePublisher(p); });
                        });
                    }

                    vm.placementExcluded = !vm.adGroup.monetizationPlacements || vm.adGroup.monetizationPlacements.length === 0 ? null :
                        vm.adGroup.monetizationPlacements[0].excluded;
                    vm.addPlacement = function (p, excluded) {
                        if (vm.placementExcluded !== null && vm.placementExcluded !== excluded)
                            return;

                        if (!vm.adGroup.monetizationPlacements)
                            vm.adGroup.monetizationPlacements = [];

                        p.selected = true;
                        p.excluded = excluded;
                        var item = vm.adGroup.monetizationPlacements.find(i => i.id === p.id);
                        if (item)
                            item.excluded = excluded;
                        else
                            vm.adGroup.monetizationPlacements.push(p);

                        vm.placementExcluded = excluded;
                    };

                    vm.removePlacement = function (p) {
                        p.selected = false;
                        var item = vm.adGroup.monetizationPlacements.find(i => i.id === p.id);
                        if (item)
                            vm.adGroup.monetizationPlacements.splice($.inArray(item, vm.adGroup.monetizationPlacements), 1);

                        var pub = vm.publishers.find(i => i.placements && i.placements.find(j => j.id === p.id));
                        if (pub) {
                            var pl = pub.placements.find(j => j.id === p.id);
                            pl.selected = false;
                        }


                        if (vm.adGroup.monetizationPlacements.length == 0)
                            vm.placementExcluded = null;
                    };

                    vm.removeAllPlacements = function () {
                        vm.adGroup.monetizationPlacements.forEach(function (p) {
                            $timeout(function () { vm.removePlacement(p); });
                        });
                    }

                    // ad units
                    vm.adUnitExcluded = !vm.adGroup.adUnits || vm.adGroup.adUnits.length === 0 ? null :
                        vm.adGroup.adUnits[0].excluded;
                    vm.addAdUnit = function (au, excluded) {
                        if (vm.adUnitExcluded !== null && vm.adUnitExcluded !== excluded)
                            return;

                        if (!vm.adGroup.adUnits)
                            vm.adGroup.adUnits = [];

                        au.selected = true;
                        au.excluded = excluded;
                        var item = vm.adGroup.adUnits.find(i => i.id === au.id);
                        if (item)
                            item.excluded = excluded;
                        else
                            vm.adGroup.adUnits.push(au);

                        vm.adUnitExcluded = excluded;
                    };

                    vm.removeAdUnit = function (au) {
                        au.selected = false;
                        var item = vm.adGroup.adUnits.find(i => i.id === au.id);
                        if (item)
                            vm.adGroup.adUnits.splice($.inArray(item, vm.adGroup.adUnits), 1);

                        var pub = vm.publishers.find(i => i.placements && i.placements.find(j => j.id === au.placementId));
                        if (pub) {
                            var pl = pub.placements.find(j => j.id === au.placementId);
                            if (pl) {
                                var a = pl.adUnits.find(j => j.id === au.id);
                                a.selected = false;
                            }
                        }

                        if (vm.adGroup.adUnits.length == 0)
                            vm.adUnitExcluded = null;
                    };

                    vm.removeAllAdUnits = function () {
                        vm.adGroup.adUnits.forEach(function (p) {
                            $timeout(function () { vm.removeAdUnit(p); });
                        });
                    }

                    vm.filterSegments = function (filter) {
                        $rootScope.$broadcast('hideLoadMore', null);
                        getSegments(filter);
                    };

                    vm.clearPostalCodesList = function () {
                        vm.selectedPostalCodes = '';
                        vm.adGroup.selectedLocations = vm.adGroup.selectedLocations.filter(function (sl) { return sl.type !== "PostalCode"; });
                    };

                    function getStatesSegments() {
                        return context.external.getLocationInfo(vm.adGroup.countries, 'Region', 'states', false).then(function (response) {
                            var segments = [];
                            response.forEach(function (s) {
                                var segment = { locationSegmentType: 'state', name: s.name, fullName: s.name, id: s.id, fullIdsList: s.id.toString() };
                                segments.push(segment);
                            });
                            $timeout(function () {
                                $rootScope.$broadcast('stateLocationSegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                            });
                        });
                    }

                    vm.getCitiesSegments = function (keyword) {
                        if (!keyword) {
                            $rootScope.$broadcast('cityLocationSegmentsChanged', { data: [], channelIndex: 0 });
                            return;
                        }

                        return context.external.getLocationInfo(vm.adGroup.countries, 'City', keyword, false).then(function (response) {
                            var segments = [];
                            response.forEach(function (s) {
                                var segment = { locationSegmentType: 'city', name: s.displayName, fullName: s.name, id: s.id, fullIdsList: s.id.toString() };
                                segments.push(segment);
                            });
                            $timeout(function () {
                                $rootScope.$broadcast('cityLocationSegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                            });
                        });
                    };

                    vm.getDMASegments = function (keyword) {
                        if (!keyword) {
                            $rootScope.$broadcast('dmaLocationSegmentsChanged', { data: [], channelIndex: 0 });
                            return;
                        }

                        return context.external.getLocationInfo(vm.adGroup.countries, 'DMA', keyword, false).then(function (response) {
                            var segments = [];
                            response.forEach(function (s) {
                                var segment = { locationSegmentType: 'dma', name: s.name, fullName: s.name, id: s.id, fullIdsList: s.id.toString() };
                                segments.push(segment);
                            });
                            $timeout(function () {
                                $rootScope.$broadcast('dmaLocationSegmentsChanged', { data: segments, channelIndex: vm.adGroup.channelIndex });
                            });
                        });
                    };

                    vm.deviceChecked = function (id) {
                        return vm.adGroup && vm.adGroup.deviceTypes && vm.adGroup.deviceTypes.indexOf(id) > -1;
                    };

                    vm.selectDevice = function (id) {
                        var found = vm.adGroup.deviceTypes.indexOf(id);
                        if (found > -1)
                            vm.adGroup.deviceTypes.splice(found, 1);
                        else
                            vm.adGroup.deviceTypes.push(id);

                        if (!(vm.adGroup.deviceTypes.indexOf(8) > -1)) {
                            var inst = vm.adGroup.placements.indexOf(2);
                            if (inst > -1)
                                vm.adGroup.placements.splice(inst, 1);
                            var mess = vm.adGroup.placements.indexOf(4);
                            if (mess > -1)
                                vm.adGroup.placements.splice(mess, 1);
                        }
                        $rootScope.$broadcast('facebookAdFromatsFilterConditionsChanged', null);
                    };

                    vm.supplyTypeChecked = function (id) {
                        return vm.adGroup && vm.adGroup.supplyTypes && vm.adGroup.supplyTypes.indexOf(id) > -1;
                    };

                    vm.selectSupplyType = function (id) {
                        var found = vm.adGroup.supplyTypes.indexOf(id);
                        if (found > -1)
                            vm.adGroup.supplyTypes.splice(found, 1);
                        else
                            vm.adGroup.supplyTypes.push(id);
                    };

                    vm.placementChecked = function (id) {
                        return vm.adGroup && vm.adGroup.placements && vm.adGroup.placements.indexOf(id) > -1;
                    };

                    vm.selectPlacement = function (id) {
                        var found = vm.adGroup.placements.indexOf(id);
                        if (found > -1)
                            vm.adGroup.placements.splice(found, 1);
                        else
                            vm.adGroup.placements.push(id);

                        if (id == 1 && found > -1) {
                            var messenger = vm.adGroup.placements.indexOf(4);
                            if (messenger > -1) {
                                vm.adGroup.placements.splice(messenger, 1);
                                vm.rememberMessenger = true;
                            }
                        } else if (id == 1 && found == -1 && vm.rememberMessenger)
                            vm.adGroup.placements.push(4);

                        if (id == 4 && found > -1)
                            vm.rememberMessenger = false;

                        $rootScope.$broadcast('facebookAdFromatsFilterConditionsChanged', null);
                    };

                    vm.countryChecked = function (countryIsoCode) {
                        return vm.adGroup && vm.adGroup.countries && vm.adGroup.countries.indexOf(countryIsoCode) > -1;
                    };

                    vm.selectCountry = function (countryIsoCode) {
                        var found = vm.adGroup.countries.indexOf(countryIsoCode);
                        if (found > -1) {
                            vm.adGroup.countries.splice(found, 1);
                        }
                        else
                            vm.adGroup.countries.push(countryIsoCode);

                        //Fall back to 'Region' if both countries are selected or only Canada is selected and selected location type is US specific
                        if ((vm.adGroup.countries.length == 2 || (vm.adGroup.countries.indexOf('CA') > -1))
                            && vm.locationType != 'Region'
                            && vm.locationType != 'City')
                            vm.locationType = 'Region';
                    };

                    vm.whitelistDisabled = function (b) {
                        if (b.inventoryListType == 2 && !b.checked && vm.inventoryListsArray.find(function (il) { return il.inventoryListType == 2 && il.checked && il.id != b.id; }))
                            return true;

                        return false;
                    };

                    vm.selectedReportFormatChanged = function () {
                        vm.adGroup.matchReportTypeId = vm.matchReportType.id;
                    };

                    function setVisibleOptions(d) {
                        d = d || vm.selectedMarketer;
                        if (d && d.options) {
                            vm.showBrowsers = d.options.filter(function (o) { return o.optionId == 1024 })[0] && d.options.filter(function (o) { return o.optionId == 1024 })[0].enabled;
                            vm.showBuyers = d.options.filter(function (o) { return o.optionId == 2048 })[0] && d.options.filter(function (o) { return o.optionId == 2048 })[0].enabled;
                            vm.showMultipleMarketers = d.options.filter(function (o) { return o.optionId == 8192 })[0] && d.options.filter(function (o) { return o.optionId == 8192 })[0].enabled;
                            vm.showPlacements = d.options.find(function (o) { return o.optionId == 9350 }) ? d.options.find(function (o) { return o.optionId == 9350 }).value : "";
                            if (vm.showPlacements && !vm.adGroup.id) {
                                vm.adGroup.placements = JSON.parse(vm.showPlacements);
                            }
                        }
                    }

                    function toggleLocationsCheck($event, location) {
                        var checked = $event.target.checked;
                        var locationIndex = vm.adGroup.selectedLocations.indexOf(location);

                        if (!checked) {
                            vm.adGroup.selectedLocations.splice(locationIndex, 1); //remove
                        }
                    }

                    function toggleShowSelectedInventoryLists() {
                        if (vm.showSelectedInventoryLists)
                            vm.showSelectedInventoryLists = false;
                        else
                            vm.showSelectedInventoryLists = true;
                    }

                    function typeaheadOnSelect(item) {
                        if (!typeCanBeAdded(item.type)) {
                            resourceService.log('Locations', 'audience', 'multipleGeoTargeting');
                            return;
                        }

                        //don't add duplicates
                        if (vm.adGroup.selectedLocations.filter(function (it) {
                            return it.type == item.type && it.locationId == item.id;
                        }).length == 0)
                            vm.adGroup.selectedLocations.push({
                                location: item.displayName, locationId: item.id, type: item.type, excluded: vm.includeLocation.id == 1, populationPercentage: item.populationPercentage ? item.populationPercentage : 0, countryCode: item.countryCode
                            });
                        vm.selectedLocation = null;
                    }

                    vm.validatePostalCodes = function () {
                        var codes = vm.selectedPostalCodes.replace(/\s/g, "").split(',');
                        if (codes && codes.length > 0) {
                            context.external.getLocationByPostalCodes(vm.adGroup.countries, codes).then(function (response) {
                                var existingCodes = convertLocationData(response);
                                existingCodes.forEach(function (existingCode) {
                                    if (vm.adGroup.selectedLocations.filter(function (it) {
                                        return it.type == 'PostalCode' && it.locationId == existingCode.id;
                                    }).length == 0)
                                        vm.adGroup.selectedLocations.push({
                                            location: existingCode.displayName, locationId: existingCode.id, type: 'PostalCode', excluded: vm.includeLocation.id == 1, populationPercentage: existingCode.populationPercentage ? existingCode.populationPercentage : 0, countryCode: existingCode.countryCode
                                        });
                                });
                            });
                        }
                    };

                    vm.hasCountryLocations = function (locations, countryCode) {
                        var countryLocations = locations.find(function (l) { return l.countryCode == countryCode; });
                        if (countryLocations)
                            return true;

                        return false;
                    };

                    function typeCanBeAdded(locationType) {
                        if (vm.adGroup.selectedLocations.length === 0)
                            return true;

                        var locationsOfGivenType = vm.adGroup.selectedLocations.filter(function (it) {
                            return it.type == locationType;
                        });
                        if (locationsOfGivenType && locationsOfGivenType.length > 0)
                            return true;
                        else
                            return false;
                    }

                    //move to different place
                    vm.containsCustomAudience = vm.adGroup.audienceSegmentGroups.find(function (g) {
                        return g.segments.find(function (s) { return s.audienceType === 'Custom' || s.audienceType === 'Prescriber' || s.audienceType === 'retargeting'; });
                    });

                    var selectedCampaign = campaignService.getSelectedCampaign();
                    if (!vm.adGroup.adGroupId) {
                        selectedCampaign.marketer = campaignService.marketers.find(function (m) { return m.id == selectedCampaign.marketerId; });
                        vm.adGroup.isLegacy = selectedCampaign.marketer.marketerContract == null;
                    }

                    if (!vm.adGroup.marketerMinimumBid) {
                        if (!vm.adGroup.advertiserId) {
                            vm.adGroup.advertiserId = selectedCampaign.advertiserId;
                        }
                        var getCPMRateOptionId = function () {
                            var result = null;
                            var channelId = vm.adGroup.channelId;
                            if (vm.adGroup.isLegacy) {
                                // use floor cpm
                                if (channelId == 1) {
                                    result = 101;
                                } else if (channelId == 2) {
                                    result = 111;
                                } else if (channelId == 3) {
                                    result = 121
                                }
                            } else {
                                if (channelId == 1) {
                                    result = 102;
                                } else if (channelId == 2) {
                                    result = 112;
                                } else if (channelId == 3) {
                                    result = 122
                                }
                            }
                            return result;
                        }
                        var optionId = getCPMRateOptionId();

                        if (!rootVM.accountId) {
                            vm.advertisers = campaignService.advertisers;
                            var advertiser = vm.advertisers.filter(function (b) { return b.id == vm.adGroup.advertiserId; })[0];
                            if (vm.adGroup) {
                                var opt = accountService.getMarketerOption(vm.adGroup.marketerId, optionId, advertiser);
                                vm.adGroup.marketerMinimumBid = opt ? parseFloat(opt.value) : 0;
                            }
                        }
                        else {
                            if (vm.adGroup) {
                                var opt = accountService.getMarketerOption(vm.adGroup.marketerId, optionId);
                                vm.adGroup.marketerMinimumBid = opt ? parseFloat(opt.value) : 0;
                            }
                        }
                    }

                    vm.getEasternDateTime = function (date) {
                        return moment(date).tz('America/New_York').format('YYYY/MM/DD HH:mm:ss ZZ');
                    };

                    vm.adGroup.retargetingIsLocked = vm.adGroup.adGroupId && vm.adGroup.saveAudience;

                    vm.startDateDisabled = vm.adGroup.channelId !== 9
                        && (vm.viewMode === 'readonly' || vm.adGroup.hasImpression || vm.adGroup.startDateIsInThePast);

                    vm.endDateDisabled = vm.adGroup.channelId !== 9
                        && (vm.viewMode === 'readonly' || vm.adGroup.endDateIsInThePast);

                    if (vm.adGroup.channelId == 9) {
                        vm.adGroup.initialStartDate = vm.adGroup.startDate;
                        vm.adGroup.initialEndDate = vm.adGroup.endDate;
                    }

                    vm.parseStartDate = function () {
                        var result = processDatepickerDate(vm.adGroup.startDate, vm.adGroup.UTCCalendarStartDate, vm.adGroup.startDateHour, vm.adGroup.startDateMinutes, vm.adGroup.startDateMeridiem, 'America/New_York');
                        vm.adGroup.startDate = result.date;
                        vm.adGroup.startDateHour = result.hours;
                        vm.adGroup.UTCCalendarStartDate = result.utcDate;
                        if (vm.adGroup.flights && vm.adGroup.flights.length > 0 &&
                            (vm.adGroup.budgetCapSpendModeId != 2 || vm.adGroup.channelId == 3 || vm.adGroup.channelId == 6 && (vm.adGroup.budgetCapSpendModeId != 2 || vm.adGroup.dynamicSequentialMessaging))) {
                            var flight = vm.adGroup.flights[0];
                            flight.startDate = vm.adGroup.startDate;
                            flight.startDateHour = vm.adGroup.startDateHour;
                            flight.UTCCalendarStartDate = vm.adGroup.UTCCalendarStartDate;
                        }
                    };

                    vm.parseEndDate = function () {
                        if (vm.adGroup.endDate) {
                            if (vm.adGroup.UTCCalendarEndDate) {
                                vm.adGroup.endDate = timezoneService.utcToEst(vm.adGroup.UTCCalendarEndDate, vm.adGroup.endDateHour, vm.adGroup.endDateMinutes);
                            }
                            var result = processDatepickerDate(vm.adGroup.endDate, vm.adGroup.UTCCalendarEndDate, vm.adGroup.endDateHour, vm.adGroup.endDateMinutes, vm.adGroup.endDateMeridiem, 'America/New_York');
                            vm.adGroup.endDate = result.date;
                            vm.adGroup.UTCCalendarEndDate = result.utcDate;
                            vm.adGroup.endDateHour = result.hours;
                        }
                        if (vm.adGroup.flights && vm.adGroup.flights.length > 0 &&
                            (vm.adGroup.budgetCapSpendModeId != 2 || vm.adGroup.channelId == 3 || vm.adGroup.channelId == 6 && (vm.adGroup.budgetCapSpendModeId != 2 || vm.adGroup.dynamicSequentialMessaging))) {
                            var flight = vm.adGroup.flights[0];
                            flight.endDate = vm.adGroup.endDate;
                            flight.endDateHour = vm.adGroup.endDateHour;
                            flight.UTCCalendarEndDate = vm.adGroup.UTCCalendarEndDate;
                        }
                    };

                    vm.adGroup.originalMaxMediaBid = vm.adGroup.maxMediaBid;

                    vm.removeTrackingCode = function (list, tc) {
                        list.splice($.inArray(tc, list), 1);
                    };

                    vm.removeFrequency = function (list, f) {
                        list.splice($.inArray(f, list), 1);
                    };

                    vm.removeEmailDomainName = function (list, f) {
                        list.splice($.inArray(f, list), 1);
                    };

                    vm.addEmailDomainName = function () {
                        vm.adGroup.emailDomainNames.push({ value: '' });
                    }

                    vm.getEmailCreativeUrl = function () {
                        if (vm.adGroup.creatives[0].emailFiles.html[0].url.indexOf('.zip') === -1)
                            return $sce.trustAsResourceUrl(vm.adGroup.creatives[0].emailFiles.html[0].url);
                    }

                    vm.isAdGroupMarketerMarginEditor = roleService.isAdGroupMarketerMarginEditor();

                    vm.min = 0;
                    vm.errorMessage = "Please input your budget cap";

                    vm.selectedChannelChange = function () {
                        if (vm.adGroup.channelIndex === 0)
                            vm.campaign.channelId = vm.adGroup.channelId;

                        switch (vm.adGroup.channelId) {
                            case 6:
                                vm.campaign.budgetTypeId = 2;
                                vm.adGroup.emailDeploymentType = 1;
                                vm.adGroup.endDate = null;
                                vm.adGroup.UTCCalendarEndDate = null;
                                vm.parseEndDate();
                                break;
                            case 9:
                                vm.adGroup.budgetTypeId = 2;
                                if (!vm.campaign.campaignId)
                                    vm.campaign.budgetTypeId = 2;
                                break;
                        }

                        if (!vm.adGroup.isMeasurementOnly) {
                            vm.channelEvents = campaignService.getChannelEvents().filter(function (ev) {
                                return (ev.id === 4 || ev.id === 5) && vm.adGroup.channelId === 6 && vm.adGroup.emailDeploymentType === 2
                                    || (ev.id === 1 || ev.id === 2 || ev.id === 3) && vm.adGroup.channelId === 6 && vm.adGroup.emailDeploymentType !== 2
                                    || (ev.id === 4 || ev.id === 5) && (vm.adGroup.channelId === 1 || vm.adGroup.channelId === 2 || vm.adGroup.channelId === 4 || vm.adGroup.channelId === 5)
                                    || ev.id === 5 && vm.adGroup.channelId === 3 || ev.id === 0;
                            });
                            setAdGroupData();
                            $rootScope.$broadcast("adGroupChannelChanged", vm.adGroup.channelIndex);
                        }
                        else {
                            if (vm.adGroup.channelId == 3 || vm.adGroup.channelId == 7)
                                vm.selectMeasurementCreativeMode('single');
                            else if (vm.adGroup.channelId == 6)
                                vm.selectMeasurementCreativeMode('bulk');
                        }
                    };

                    vm.removeSecondaryChannel = function (list, ch) {
                        list.splice($.inArray(ch, list), 1);
                    }

                    function adGroupNameChanged() {
                        vm.adGroup.duplicateName = false;
                        var validationData = {};
                        validationData.entityName = vm.adGroup.name;
                        validationData.entityType = "adgroup";
                        validationData.campaignId = vm.adGroup.campaignId;
                        validationData.adGroupId = vm.adGroup.adGroupId;
                        hub.webapicontext.validateName(validationData).then(function (data) {
                            if (data.message !== "Valid Name")
                                vm.adGroup.duplicateName = true;
                        });
                    }

                    function audienceNameChanged() {
                        vm.adGroup.duplicateAudienceName = false;
                        var validationData = {};
                        validationData.entityName = vm.adGroup.audienceName;
                        validationData.entityType = "audience";
                        validationData.campaignId = vm.adGroup.campaignId;
                        validationData.adGroupId = vm.adGroup.adGroupId;
                        hub.webapicontext.validateName(validationData).then(function (data) {
                            if (data.message !== "Valid Name")
                                vm.adGroup.duplicateAudienceName = true;
                        });
                    }

                    function calcEstimatedReachModel(budgetAmount, adGroup, campaignService) {
                        var campaignMarketer = campaignService.getSelectedMarketer();
                        if (campaignMarketer) {
                            var frequencyFactor = campaignMarketer.options.filter(function (o) {
                                return o.optionId == 9020
                            })[0];
                            if (frequencyFactor)
                                frequencyFactor = frequencyFactor.value;
                            var estimatedReachRange = campaignMarketer.options.filter(function (o) {
                                return o.optionId == 9030
                            })[0];
                            if (estimatedReachRange)
                                estimatedReachRange = estimatedReachRange.value;

                            var estimatedReachLowerBound = budgetAmount * 1000 * (1 - estimatedReachRange * 0.01) / (adGroup.maxCPM * frequencyFactor);
                            if (estimatedReachLowerBound)
                                estimatedReachLowerBound = Math.ceil(estimatedReachLowerBound / 1000) * 1000;
                            var estimatedReachUpperBound = budgetAmount * 1000 * (1 + estimatedReachRange * 0.01) / (adGroup.maxCPM * frequencyFactor);
                            if (estimatedReachUpperBound) {
                                estimatedReachUpperBound = Math.ceil(estimatedReachUpperBound / 1000) * 1000;
                            }

                            estimatedReachUpperBound = Math.min(estimatedReachUpperBound, vm.adGroup.potentialReach);
                            estimatedReachLowerBound = Math.min(estimatedReachLowerBound, vm.adGroup.potentialReach);

                            return {
                                estimatedReachLowerBound: estimatedReachLowerBound,
                                estimatedReachUpperBound: estimatedReachUpperBound
                            };
                        }
                        return {
                            estimatedReachLowerBound: 0,
                            estimatedReachUpperBound: 0
                        };
                    }

                    function countEstimatedReachBounds() {
                        var budgetAmount = vm.adGroup.budgetCap;
                        if (vm.adGroup.budgetCapSpendModeId === 1) {
                            if (vm.adGroup.channelId === 3) {
                                if (!vm.adGroup.endDate || vm.adGroup.endDate === '') {
                                    vm.min = 0;//500;
                                    budgetAmount = budgetAmount * 30;
                                    vm.adGroup.endDateError = false;
                                    novalidate();

                                } else {
                                    vm.min = vm.min ? '' : vm.min;
                                    novalidate();
                                    if (budgetAmount && vm.adGroup.endDate) {
                                        var totalAmount = budgetAmount * dateDiff();
                                        if (totalAmount < 0) {
                                            vm.adGroup.endDateError = true;
                                            return;
                                        }
                                        else {
                                            vm.adGroup.endDateError = false;
                                            budgetAmount = totalAmount;
                                        }
                                    }

                                }

                            } else {

                                if (!vm.adGroup.endDate || vm.adGroup.endDate === '') {
                                    budgetAmount = budgetAmount * 30;
                                }
                                else {
                                    budgetAmount = budgetAmount * dateDiff();
                                }
                            }
                        } else {
                            if (vm.adGroup.channelId === 3)
                                vm.min = 0;//500;
                            else if (vm.adGroup.channelId === 9) {
                                vm.min = -10000000;
                            }
                            vm.adGroup.endDateError = false;
                            novalidate();
                        }

                        var model = calcEstimatedReachModel(budgetAmount, vm.adGroup, campaignService);
                        vm.estimatedReachLowerBound = model.estimatedReachLowerBound;
                        vm.estimatedReachUpperBound = model.estimatedReachUpperBound;


                        if (!vm.estimatedReachLowerBound || !vm.estimatedReachUpperBound || !vm.adGroup.potentialReach)
                            vm.displayEstimateReach = false;
                        else
                            vm.displayEstimateReach = true;

                        vm.formattedEstimatedReachUpperBound = vm.estimatedReachUpperBound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        vm.formattedEstimatedReachLowerBound = vm.estimatedReachLowerBound.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }

                    vm.formattedEstimatedReach = function () {
                        if (vm.estimatedReachLowerBound && vm.estimatedReachUpperBound) {
                            if (vm.estimatedReachLowerBound !== vm.estimatedReachUpperBound) {
                                return vm.formattedEstimatedReachLowerBound + ' - ' + vm.formattedEstimatedReachUpperBound;
                            }
                            return vm.formattedEstimatedReachLowerBound;
                        }
                        return vm.adGroup.potentialReach;
                    }

                    function recalculateBar() {
                        vm.estimatedReachBarValue = Math.ceil((((vm.estimatedReachUpperBound + vm.estimatedReachLowerBound) / 2) / vm.adGroup.potentialReach) * 100);
                        if (vm.estimatedReachBarValue > 100) {
                            vm.estimatedReachBarValue = 100;
                            vm.showAudienceWarning = true;
                        }
                        else {
                            vm.showAudienceWarning = false;
                        }

                    }

                    if (vm.adGroup.adGroupId)
                        setAdGroupData();

                    function getMarketerOptionOrDefault(marketer, optionId, defaultValue) {
                        var option = marketer.options.find(function (o) { return o.optionId == optionId; });

                        var result = option ? option.value : defaultValue;
                        if (isNaN(result)) {
                            return result;
                        }
                        return parseFloat(result);
                    }

                    function loadResourceLists() {
                        if (!vm.platformFeeTypes || !vm.pricingOptions) {
                            hub.datacontext.getLocalJson('lists').then(function (l) {
                                if (!vm.platformFeeTypes)
                                    vm.platformFeeTypes = l.platformFeeTypes.values;

                                if (!vm.pricingOptions)
                                    vm.pricingOptions = l.pricingOptions.values;
                            });
                        }
                    }

                    function setMarketerSettings(campaignMarketer) {
                        if (campaignMarketer && campaignMarketer.marketerContract) {
                            loadResourceLists();
                            vm.marketerSettings = {
                                marketerMargin: getMarketerOptionOrDefault(campaignMarketer, 9430, 1),
                                platformFeeType: campaignMarketer.marketerContract.platformFeeType,
                                pricingOption: getMarketerOptionOrDefault(campaignMarketer, 9410, 1),
                                deliveryCostPerImp: getMarketerOptionOrDefault(campaignMarketer, 9420, 0)
                            };
                        }
                    }

                    function setAdGroupData(channelChanged = false) {
                        vm.showEndemicBlend = false;
                        var selectedMarketer = campaignService.getSelectedMarketer();
                        if (selectedMarketer) {
                            var endemicBlendOption = selectedMarketer.options.find(function (o) { return o.optionId == 10150 });
                            vm.showEndemicBlend = vm.adGroup.channelId == 1 && endemicBlendOption && endemicBlendOption.enabled;
                        }
                        //set default value for budget cap spend mode
                        if (!vm.adGroup.budgetCapSpendModeId)
                            vm.adGroup.budgetCapSpendModeId = vm.adGroup.channelId == 6 ? 1 : 2;
                        if (!vm.selectedCampaign || !vm.selectedCampaign.marketerId) {
                            return;
                        }
                        //set default pacing option
                        var campaignMarketer = campaignService.marketers.find(function (r) { return r.id == vm.selectedCampaign.marketerId; });
                        setMarketerSettings(campaignMarketer);
                        vm.pricingOption = campaignMarketer.options.find(function (o) { return o.optionId == 9410; });


                        if (vm.pricingOption && parseInt(vm.pricingOption.value) < 3) {
                            vm.adGroup.pacingType = parseInt(vm.pricingOption.value);
                        }
                        else
                            vm.adGroup.pacingType = 1;

                        vm.adGroup.dataFee = getMarketerOptionOrDefault(campaignMarketer, 9440, 0);

                        if ((vm.adGroup.PlatformFeeType && vm.adGroup.PlatformFeeType === 3) || (!vm.adGroup.PlatformFeeType && vm.marketerContract && vm.marketerContract.platformFeeType === 3)) {
                            vm.adGroup.marketerMargin = null;
                        } else if (vm.adGroup.marketerMargin == null) {
                            vm.adGroup.marketerMargin = getMarketerOptionOrDefault(campaignMarketer, 9430, 0);
                        }

                        vm.adGroup.deliveryCostPerImp = getMarketerOptionOrDefault(campaignMarketer, 9420, 0);

                        if (!vm.adGroup.pacingOption)
                            vm.adGroup.pacingOption = 1;

                        //set dayparting
                        if (!vm.adGroup.dayparting)
                            vm.adGroup.dayparting = 1;

                        if (vm.adGroup.schedule && vm.adGroup.schedule !== '')
                            vm.adGroup.scheduleModel = JSON.parse(vm.adGroup.schedule);
                        else if (!vm.adGroup.scheduleModel)
                            vm.adGroup.scheduleModel = {};

                        var channel = campaignService.getChannels().find(function (c) { return c.id === vm.adGroup.channelId; });
                        if (channel) {
                            if (channel.id == 3 || channel.id == 16 || channel.id == 25 || channelChanged) {
                                if (!vm.adGroup.adGroupId) {
                                    vm.adGroup.maxCPM = channel.estimatedMediaCPM;
                                }
                            }
                            vm.adGroup.channel = channel;
                        }

                        if (vm.adGroup.startDate)
                            vm.adGroup.startDate = new Date(vm.adGroup.startDate);

                        //setup datepicker in Eastern Time
                        if (vm.adGroup.startDate && (vm.adGroup.startDate.valueOf(Date) > 0)) {
                            vm.endCalendarDate = new Date(vm.adGroup.startDate.getTime() + tzDifference * 60 * 1000);
                            if (!(vm.adGroup.isMeasurementOnly && vm.adGroup.channelId === 9)
                                && vm.adGroup.startDate < now) {
                                vm.minDate = new Date(vm.adGroup.startDate);
                            }
                        }
                        else {
                            vm.adGroup.startDate = new Date((new Date()).getTime() + 60 * 60000);//add 60 minutes to current date time
                        }


                        vm.adGroup.startDateTime = moment(vm.adGroup.startDate).tz('America/New_York').format('h') + ':00';
                        vm.adGroup.startDateHour = moment(vm.adGroup.startDate).tz('America/New_York').format('hh');
                        vm.adGroup.startDateMinutes = moment(vm.adGroup.startDate).tz('America/New_York').format('mm');
                        vm.adGroup.startDateMeridiem = moment(vm.adGroup.startDate).tz('America/New_York').format('A');

                        if (vm.adGroup.endDate)
                            vm.adGroup.endDate = new Date(vm.adGroup.endDate);

                        if (vm.adGroup.endDate && (vm.adGroup.endDate.valueOf(Date) > 0)) {
                            if (vm.adGroup.endDate.getUTCFullYear() < 2037) {
                                vm.isOngoing = false;
                                vm.adGroup.endDateTime = moment(vm.adGroup.endDate).tz('America/New_York').format('h') + ':00';
                                vm.adGroup.endDateHour = moment(vm.adGroup.endDate).tz('America/New_York').format('hh');
                                vm.adGroup.endDateMinutes = moment(vm.adGroup.endDate).tz('America/New_York').format('mm');
                                vm.adGroup.endDateMeridiem = moment(vm.adGroup.endDate).tz('America/New_York').format('A');
                            }
                            else if (vm.adGroup.endDate.getUTCFullYear() >= 2037) {
                                vm.adGroup.endDate = null;
                                vm.adGroup.UTCCalendarEndDate = null;
                                vm.isOngoing = true;
                            }
                        }
                        else {
                            vm.adGroup.endDate = null;
                            vm.adGroup.UTCCalendarEndDate = null;
                            vm.isOngoing = true;
                        }
                        vm.adGroup.originalMaxMediaBid = vm.adGroup.maxMediaBid;
                        vm.parseStartDate();
                        vm.parseEndDate();


                        if (vm.adGroup.isMeasurementOnly && vm.adGroup.creatives && vm.adGroup.creatives.length > 0) {
                            vm.adGroup.creatives.forEach(function (c) {
                                if (c.trackingCodes && c.trackingCodes.length > 0) {
                                    c.trackingCode = '';
                                    c.trackingCodes.forEach(function (tc) {
                                        if (tc.trackingCodeValue)
                                            c.trackingCode = c.trackingCode + tc.trackingCodeValue + ' ';
                                    })
                                }
                            });
                        }


                        if ((!vm.adGroup.audienceSegmentGroups || vm.adGroup.audienceSegmentGroups.length === 0) && vm.adGroup.audienceSegmentGroupsJSON && vm.adGroup.audienceSegmentGroupsJSON != '')
                            vm.adGroup.audienceSegmentGroups = JSON.parse(vm.adGroup.audienceSegmentGroupsJSON);

                        if (!vm.adGroup.audienceSegmentGroups || vm.adGroup.audienceSegmentGroups.length === 0) {
                            if (vm.adGroup.channelId != 3) {
                                vm.adGroup.audienceSegmentGroups = [];
                            } else {
                                vm.adGroup.audienceSegmentGroups = [{
                                    segments: [], booleanOperator: 'OR', id: 1
                                }, {
                                    segments: [], booleanOperator: 'OR', id: 2
                                }];
                            }
                        } else {
                            if (vm.adGroup.channelId != 3) {
                                vm.adGroup.audienceSegmentGroups = vm.adGroup.audienceSegmentGroups.filter(x => x.segments && x.segments.length > 0);
                            }
                        }
                        if (!vm.adGroup.selectedSegmentIds)
                            vm.adGroup.selectedSegmentIds = [];

                        if (!vm.adGroup.includedBrandSafetySegments)
                            vm.adGroup.includedBrandSafetySegments = [];

                        if (vm.adGroup.conditionsJSON)
                            vm.adGroup.conditions = JSON.parse(vm.adGroup.conditionsJSON);

                        vm.adGroup.tooManyCategoriesSelected = false;

                        if (vm.adGroup.recencyTo)
                            vm.recencyTo = vm.recencyToValues.find(function (r) { return r.value == vm.adGroup.recencyTo; });
                        if (vm.adGroup.recencyFrom || vm.adGroup.recencyFrom == 0)
                            vm.recencyFrom = vm.recencyFromValues.find(function (r) { return r.value == vm.adGroup.recencyFrom; });

                        var recencyType = _.find(vm.recencyTypes, function (rt) {
                            return vm.adGroup.recencyTypeId == rt.id;
                        });
                        if (recencyType)
                            vm.adGroup.recencyType = recencyType.name;
                        else
                            vm.adGroup.recencyType = vm.recencyTypes[2].name;

                        vm.includeLocation = vm.adGroup.selectedLocations.length == 0 ? vm.locationOptions[0] : (vm.adGroup.selectedLocations.filter(function (item) { return item.excluded; }).length > 0 ? vm.locationOptions[1] : vm.locationOptions[0]);
                        vm.adGroup.locationsExcluded = (vm.includeLocation.id == 1);

                        if (!(vm.adGroup.deviceTypes.indexOf(8) > -1)) {
                            var inst = vm.adGroup.placements.indexOf(2);
                            if (inst > -1)
                                vm.adGroup.placements.splice(inst, 1);
                            var mess = vm.adGroup.placements.indexOf(4);
                            if (mess > -1)
                                vm.adGroup.placements.splice(mess, 1);
                        }

                        vm.adGroup.calculatePotentialReach = function () {
                            if (this.audienceSegmentGroups && this.audienceSegmentGroups.length > 0) {
                                var that = this;
                                forecastService.calculatePotentialReach(this.audienceSegmentGroups, vm.recencyFrom.value, vm.recencyTo.value, vm.adGroup.taxonomyId ? vm.adGroup.taxonomyId : (vm.selectedMarketer ? vm.selectedMarketer.taxonomyId : null), vm.adGroup.channelId, vm.adGroup.advertiserId ? vm.adGroup.advertiserId : vm.selectedCampaign.advertiserId).then(function (result) {

                                    if (!that.audienceSegmentGroups || that.audienceSegmentGroups.length == 0) {
                                        that.formattedPotentialReach = "N/A";
                                        return;
                                    }

                                    //apply marketer factors
                                    var selectedMarketer = campaignService.getSelectedMarketer();

                                    if (selectedMarketer) {
                                        var userOverlapFactor = selectedMarketer.options.filter(function (o) {
                                            return o.optionId == 9000
                                        })[0];
                                        if (userOverlapFactor)
                                            userOverlapFactor = userOverlapFactor.value > 0 ? userOverlapFactor.value : 1;

                                        var userReachFactor = selectedMarketer.options.filter(function (o) {
                                            return o.optionId == 9010
                                        })[0];

                                        if (userReachFactor)
                                            userReachFactor = userReachFactor.value > 0 ? userReachFactor.value : 100;

                                        var facebookAudienceFactor = selectedMarketer.options.filter(function (o) {
                                            return o.optionId == 9040
                                        })[0];

                                        facebookAudienceFactor = facebookAudienceFactor && facebookAudienceFactor.value > 0 ? facebookAudienceFactor.value : 1;

                                        result = (result / userOverlapFactor) * (userReachFactor / 100);
                                        result = Math.ceil(result / 1000) * 1000;
                                        if (isFacebookCampaign()) {
                                            result = result * facebookAudienceFactor;
                                        }
                                    }

                                    if (vm.adGroup.countries.length == 2)
                                        result = Math.round(result * 1.1);
                                    else if (vm.adGroup.countries.length == 1 && vm.adGroup.countries.indexOf('CA') > -1)
                                        result = Math.round(result * 0.1);

                                    if (that.selectedLocations && that.selectedLocations.length > 0) {
                                        that.potentialReach = forecastService.filterSelectedCategoriesPotentialReachByLocation(that.selectedLocations, result, vm.adGroup.locationsExcluded);

                                        $rootScope.$broadcast('potentialReachCalculated', that.potentialReach);
                                    } else {
                                        that.potentialReach = result;
                                        $rootScope.$broadcast('potentialReachCalculated', that.potentialReach);
                                    }



                                    that.formattedPotentialReach = that.potentialReach > 0 ? that.potentialReach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A";
                                });
                            }
                            else
                                this.formattedPotentialReach = "N/A";
                        };

                        var marketer = _.find(vm.marketers, function (r) {
                            return vm.adGroup.marketerId == r.id;
                        });

                        if (marketer)
                            vm.selectedMarketer = marketer;

                        $rootScope.$broadcast('includedGroupsChanged', {
                            includedGroups: vm.adGroup.audienceSegmentGroups, mode: 'audience', channelIndex: vm.adGroup.channelIndex
                        });

                        $timeout(function () {
                            $scope.slider = {
                                options: {
                                    floor: 50,
                                    ceil: 150,
                                    step: 1,
                                    translate: function (value) {
                                        return value + '%';
                                    }
                                }
                            };
                        });

                        if (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25) {
                            getPublishers();
                            if (vm.adGroup.publishers && vm.adGroup.publishers.length == 1 && vm.adGroup.publishers.find(p => p.id == 2620)) {
                                vm.wkOnly = true;
                            }
                        }

                        if (vm.adGroup.channelId) {

                            if (vm.adGroup.channelId == 16 && vm.adGroup.endemicTypeId === 1) {
                                getSegments('Consumer');
                                $timeout(function () {
                                    vm.activeTab = 1;
                                });
                            } else {
                                getSegmentsWithParents('Prescriber');
                            }

                            getStatesSegments();
                            getSegments('Location');
                        }

                        if (!vm.adGroup.adGroupId && !(vm.adGroup.channelId == 6 && vm.adGroup.emailDeploymentType == 1)) {
                            vm.isOngoing = false;
                            vm.ongoingChanged();
                        }
                    }

                    vm.showAdvancedSettingsClicked = function () {
                        vm.adGroup.showAdvancedSettings = !vm.adGroup.showAdvancedSettings;
                        getSegments('Brand Safety');
                        getSegments('InventoryByPublisher');
                        getSegments('InventoryByCategory');
                    }

                    vm.populateLocations = function (locationType) {
                    }

                    function calculateActualBudget() {
                        if (vm.adGroup.adGroupId)
                            statcontext.getAdGroupFlightsPerformance(vm.adGroup.adGroupId).then(function (data) {
                                if (data.flights && data.flights.length > 0) {

                                    data.flights.forEach(function (f) {
                                        var flight = vm.adGroup.flights.find(function (ff) { return ff.id === f.flightId; });
                                        if (flight) {
                                            flight.actualBudget = vm.campaign.budgetTypeId == 1 ? f.impressions : Math.round(f.spend * 100) / 100;
                                        }
                                    });

                                    var actualAndFutureFlights = vm.adGroup.flights.filter(function (f) { return f.statusId == 0 && f.endDate > now; });
                                    if (actualAndFutureFlights && actualAndFutureFlights.length > 0) {
                                        var actualImps = _.map(data.flights.filter(f => _.map(actualAndFutureFlights, 'id').indexOf(f.flightId) == -1), 'impressions').reduce((a, b) => a + b, 0);
                                        var actualSpend = _.map(data.flights.filter(f => _.map(actualAndFutureFlights, 'id').indexOf(f.flightId) == -1), 'spend').reduce((a, b) => a + b, 0);
                                        var futureBudget = _.map(actualAndFutureFlights, 'budgetCap').reduce((a, b) => a + b, 0);
                                        if (futureBudget || futureBudget === 0)
                                            vm.adGroup.actualBudget = vm.campaign.budgetTypeId == 1 ? (actualImps + futureBudget) : (Math.round((actualSpend + futureBudget) * 100) / 100);
                                    } else {
                                        vm.adGroup.actualBudget = null;
                                    }
                                }
                            });
                    }

                    vm.validatePostalCodes = function () {
                        var codes = vm.selectedPostalCodes.replace(/\s/g, "").split(',');
                        if (codes && codes.length > 0) {
                            context.external.getLocationByPostalCodes(vm.adGroup.countries, codes).then(function (response) {
                                var existingCodes = convertLocationData(response);
                                existingCodes.forEach(function (existingCode) {
                                    if (vm.adGroup.selectedLocations.filter(function (it) {
                                        return it.type == 'PostalCode' && it.locationId == existingCode.id;
                                    }).length == 0)
                                        vm.adGroup.selectedLocations.push({
                                            location: existingCode.displayName, locationId: existingCode.id, type: 'PostalCode', excluded: false, populationPercentage: existingCode.populationPercentage ? existingCode.populationPercentage : 0, countryCode: existingCode.countryCode
                                        });
                                });
                            });
                        }
                    };

                    function convertLocationData(response, keyword) {
                        var results = response.filter(function (res) {
                            if (!keyword)
                                return true;

                            var kw = res.name + res.code;

                            if (kw.toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
                                return true;
                            }
                        });
                        return results.map(function (item) {
                            return {
                                type: vm.locationType, id: item.id, name: item.name, code: item.code, displayName: item.displayName ? item.displayName : (item.name ? item.name : item.code), populationPercentage: item.populationPercentage ? item.populationPercentage : 0, countryCode: (item.countryId === 233 ? "US" : "CA")
                            };
                        });
                    }

                    function calculateMaxBid() {
                        if (vm.marketerSettings) {

                            var data = {
                                adGroupId: vm.adGroup.adGroupId,
                                budgetCap: vm.adGroup.budgetCap,
                                campaignId: vm.adGroup.campaignId,
                                advertiserid: vm.adGroup.advertiserId,
                                marketerId: vm.adGroup.marketerId,
                                channelId: vm.adGroup.channelId,
                                maxCPM: vm.adGroup.maxCPM,
                                pacingType: vm.adGroup.pacingType,
                                marketerMargin: isNaN(vm.adGroup.marketerMargin) ? null : vm.adGroup.marketerMargin,
                                dataCPM: vm.adGroup.dataCPM
                            };
                            vm.isCalculating = true;
                            hub.api.post('api/adgroup/cpmcalculator', data)
                                .then(function (data) {
                                    vm.isCalculating = false;
                                    if (vm.adGroup.channelId === 1) {
                                        var segments = [];
                                        vm.adGroup.audienceSegmentGroups.forEach(g => {
                                            g.segments.forEach(s => {
                                                segments.push({ audienceId: s.audienceId, included: !s.excluded });
                                            });
                                        })
                                        if(vm.adGroup.adGroupId) {
                                            hub.webapicontext.getTotalCpm({
                                                adGroupId: vm.adGroup.adGroupId,
                                                channelID: vm.adGroup.channelId,
                                                segments,
                                                brandSafetySegments: vm.adGroup.brandSafetySegments.map((s) =>({ audienceId: s.audienceId, included: !s.excluded })),
                                                crossDeviceExtension: vm.adGroup.crossDeviceExtension,
                                                tapAdCrossDeviceExtension: vm.adGroup.tapAdCrossDeviceExtension,
                                                mediaBid: vm.adGroup.maxCPM
                                            })
                                        }
                                    }
                                    if (vm.adGroup.channelId == 6 && !vm.campaign.isMultiChannel)
                                        vm.adGroup.netCPM = vm.adGroup.dataCPM;
                                    else
                                        vm.adGroup.netCPM = data.netCPM;

                                    if (vm.adGroup.channelId == 6)
                                        vm.adGroup.estimatedEmailDeployments = vm.adGroup.netCPM ? vm.adGroup.budgetCap / vm.adGroup.netCPM : 0;
                                    else if (vm.adGroup.channelId == 9)
                                        vm.adGroup.estimatedSpend = vm.adGroup.budgetCap / 1000 * vm.adGroup.dataCPM;
                                    else {
                                        if (vm.campaign.budgetTypeId == 1)
                                            vm.adGroup.estimatedSpend = data.estimatedSpend;
                                        else
                                            vm.adGroup.estimatedImpressions = data.estimatedImpressions;
                                    }

                                }, function (err) {
                                    vm.isCalculating = false;
                                    if (!err.data.logged) {
                                        console.log(err);
                                    }
                                });
                        }
                        calculateActualBudget();
                    }
                    vm.maxMediaBidPercent = 80;
                    $scope.updateFromCalculator = function () {
                        vm.adGroup.maxMediaBid = parseFloat((vm.maxMediaBidPercent * vm.maxBidCPM / 100).toFixed(2));
                    }
                    $scope.restoreOriginalMaxMediaBid = function () {
                        vm.adGroup.maxMediaBid = vm.adGroup.originalMaxMediaBid;
                    }

                    function ceilMinutes(date) {
                        date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60));
                        date.setMinutes(0);
                        return date;
                    }

                    function novalidate() {
                        $('#adGroupForm').validator('destroy');
                        $('#minBudgetError').hide();
                        $('#endDate').removeClass('has-error has-danger');
                        $('#startDate').removeClass('has-error has-danger');
                        $('#minBudgetTxt').show();
                    }

                    function toggleAdvancedSettings() {
                        vm.showAdvancedSettings = !vm.showAdvancedSettings;
                        if (!vm.showAdvancedSettings)
                            vm.advancedSettingsText = "Show Advanced Schedule & Budget Settings";
                        else
                            vm.advancedSettingsText = "Hide Advanced Schedule & Budget Settings";
                        getSegments('Conversion');
                        vm.conversionsLoaded = true;
                    }

                    $scope.$on('campaignSelected', function () {
                        var selectedCampaign = campaignService.getSelectedCampaign();
                        vm.selectedCampaign = selectedCampaign;
                    });

                    $scope.$watch('vm.adGroup.channelId', function (newVal, oldVal) {
                        if (newVal == 3) {
                            vm.optimization = vm.facebookOptimisation[0];
                        } else if (newVal == 9) {
                            vm.min = -10000000;
                        }
                    });

                    $scope.$watch('vm.optimization', function (newVal) {
                        if (newVal)
                            vm.adGroup.facebookOptimizationId = newVal.items[0].id;
                    });

                    $scope.$watch('vm.adGroup.budgetCapSpendModeId', function (newVal) {
                        if (newVal && vm.adGroup.flights)
                            vm.adGroup.flights.forEach(function (f) {
                                f.isLifetimePacing = vm.adGroup.budgetCapSpendModeId == 2;
                            });
                    });

                    $scope.$watch('vm.platformFeeTypes', function (newVal, oldVal) {
                        if (newVal) {
                            vm.marketerSettings.platformFeeTypeName = _.find(vm.platformFeeTypes, function (r) { return r.id == vm.marketerSettings.platformFeeType; }).name;
                        }
                    });

                    function changePricingOptionName() {
                        if (vm.marketerSettings && vm.pricingOptions) {
                            vm.marketerSettings.pricingOptionName = _.find(vm.pricingOptions, function (r) { return r.id == vm.adGroup.pacingType; }).name;
                        }
                    }

                    $scope.$watch('vm.pricingOptions', function (newVal, oldVal) {
                        if (newVal) {
                            changePricingOptionName();
                        }
                    });

                    $scope.$watch('vm.adGroup.pacingType', function (newVal, oldVal) {
                        if (newVal) {
                            changePricingOptionName();
                        }
                    });

                    $scope.$watchGroup(['vm.adGroup.budgetCap', 'vm.campaign.budgetTypeId', 'vm.adGroup.maxCPM', 'vm.adGroup.pacingType', 'vm.adGroup.marketerMargin', 'vm.adGroup.dataCPM'], function (newVal, oldVal) {
                        vm.adGroup.budgetTypeId = vm.campaign.budgetTypeId;
                        if (vm.adGroup.budgetCap) {
                            calculateMaxBid();
                        }
                    });

                    $scope.$watch('vm.adGroup.creatives.length', function (newVal) {
                        if (typeof newVal !== 'undefined') {
                            vm.creativeSelected = newVal > 0;
                            vm.staticCreativeSelected = vm.adGroup.creatives.length && vm.adGroup.creatives.find(c => !c.thirdPartyServed);
                            $timeout(function () {
                                $('#creativesValidation').trigger('input');
                            });
                        }
                    });

                    $scope.$watch('vm.adGroup.startDate', function (newVal) {
                        if (typeof newVal !== 'undefined') {
                            const now = new Date();
                            vm.startDateValid = vm.adGroup.startDateIsInThePast || vm.adGroup.hasImpression || vm.adGroup.adGroupStatusId == 1 || vm.adGroup.adGroupStatusId == 3
                                || vm.adGroup.channelId === 9 || newVal >= moment(now).add(10, 'm').toDate();
                            const endDate = new Date(vm.adGroup.endDate);
                            vm.endDateValid = vm.adGroup.endDate == null || vm.adGroup.channelId === 9 || (endDate >= newVal && Math.ceil((endDate.getTime() - now.getTime()) / 60 / 1000) >= 30);
                            vm.dateRangeValid = vm.adGroup.endDate == null || Math.abs(endDate - newVal) / 1000 / 60 >= 59;
                            vm.inBounds = vm.adGroup.inBounds = validationService.validateFlight(vm.flight, vm.adGroup, vm.campaign).inBounds;
                            vm.adGroup.datesInvalid = !(vm.inBounds && vm.dateRangeValid && vm.endDateValid && vm.startDateValid);
                            vm.endCalendarDate = new Date(newVal.getTime() + tzDifference * 60 * 1000);

                            $timeout(function () {
                                $('#startDateValidation').trigger('input');
                                $('#endDateValidation').trigger('input');
                                $('#dateRangeValidation').trigger('input');
                            });
                        }
                    });

                    $scope.$watch('vm.adGroup.endDate', function (newVal) {
                        if (typeof newVal !== 'undefined') {
                            const now = new Date();
                            const startDate = new Date(vm.adGroup.startDate);
                            vm.endDateValid = newVal >= startDate && Math.ceil((newVal.getTime() - now.getTime()) / 60 / 1000) >= 30;
                            vm.dateRangeValid = Math.abs(newVal - startDate) / 1000 / 60 >= 59;
                            vm.inBounds = vm.adGroup.inBounds = validationService.validateFlight(vm.flight, vm.adGroup, vm.campaign).inBounds;
                            vm.adGroup.datesInvalid = !(vm.inBounds && vm.dateRangeValid && vm.endDateValid && vm.startDateValid);

                            $timeout(function () {
                                $('#endDateValidation').trigger('input');
                                $('#dateRangeValidation').trigger('input');
                            });
                        }
                    });

                    vm.startDateChanged = function () {
                        var newDate = moment(timezoneService.utcToEst(vm.adGroup.UTCCalendarStartDate)).tz('America/New_York').format('YYYY/MM/DD');
                        var currentDate = moment(Date.now()).tz('America/New_York').format('YYYY/MM/DD');
                        if (currentDate === newDate) {
                            vm.adGroup.startDateHour = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('hh');
                            vm.adGroup.startDateMinutes = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('mm');
                            vm.adGroup.startDateMeridiem = moment(Date.now()).add(1, 'hours').tz('America/New_York').format('A');
                        }
                        else {
                            vm.adGroup.startDateHour = "12";
                            vm.adGroup.startDateMinutes = "00";
                            vm.adGroup.startDateMeridiem = "AM";
                        }
                        vm.adGroup.startDate = timezoneService.utcToEst(vm.adGroup.UTCCalendarStartDate);
                        vm.parseStartDate();
                    };

                    vm.retargetingClicked = function () {
                        if (!vm.adGroup.retargetingAction)
                            vm.adGroup.retargetingAction = 1;
                    };

                    $scope.$on('segmentsChanged', function () {
                        if (vm.needsValidation) {
                            vm.needsValidation = false;
                            $timeout(function () {
                                $('#adGroupForm').validator('validate');
                            });
                        }
                    });

                    $scope.$on('potentialReachCalculated', function (event, data) {
                        vm.adGroup.potentialReach = data;
                        countEstimatedReachBounds();
                        recalculateBar();
                    });

                    $scope.$on('includedGroupsSegmentsChanged', function (event) {
                        if (!vm.adGroup.adGroupId) {
                            vm.platformAudienceName = vm.getPlatformAudienceName();
                            if (!vm.adGroup.useCustomName) {
                                vm.adGroup.customName = vm.platformAudienceName;
                            }
                        }
                        vm.adGroup.audienceSelectionError = false;
                        $('#audienceSelectionError').hide();
                        vm.updateDataCPM();
                    });

                    vm.automaticallyPushAudienceChanged = function () {
                        vm.adGroup.automaticallyPushAudience = !vm.adGroup.automaticallyPushAudience;
                        if (!vm.adGroup.adGroupId) {
                            vm.adGroup.useCustomName = !vm.adGroup.automaticallyPushAudience;
                            vm.platformAudienceName = vm.getPlatformAudienceName();
                            if (!vm.adGroup.useCustomName)
                                vm.adGroup.customName = vm.platformAudienceName;
                        }
                    }

                    vm.useCustomNameChanged = function () {
                        vm.adGroup.useCustomName = !vm.adGroup.useCustomName;
                        vm.platformAudienceName = vm.getPlatformAudienceName();
                        if (!vm.adGroup.useCustomName)
                            vm.adGroup.customName = vm.platformAudienceName;
                    }

                    $scope.$on('includedBrandSafetySegmentsChanged', function (event) {
                        vm.updateDataCPM();
                    });

                    vm.deviceExtensionChanged = function (isXandr) {
                        if (vm.isAdmin) {
                            if (vm.adGroup.channelId == 4) {
                                if (isXandr)
                                    vm.adGroup.tapAdCrossDeviceExtension = !vm.adGroup.crossDeviceExtension;
                                else
                                    vm.adGroup.crossDeviceExtension = !vm.adGroup.tapAdCrossDeviceExtension;
                            } else {
                                if (isXandr && vm.adGroup.crossDeviceExtension)
                                    vm.adGroup.tapAdCrossDeviceExtension = false;
                                else if (!isXandr && vm.adGroup.tapAdCrossDeviceExtension)
                                    vm.adGroup.crossDeviceExtension = false;
                            }
                        }

                        vm.updateDataCPM();
                    };

                    vm.updateDataCPM = function () {
                        if (vm.adGroup.channelId === 6 && vm.adGroup.emailDeploymentType === 2) {
                            return;
                        }

                        var dataCPM = 0;
                        var externalDataCPM = 0;
                        var partnerFee = 0;
                        var emailReach = 0;
                        vm.adGroup.dataSourceId = null;
                        vm.adGroup.maxCpmAudienceId = null;

                        switch (vm.adGroup.channelId) {
                            case 3:
                                vm.adGroup.audienceSegmentGroups.forEach(function (g) {
                                    g.segments.forEach(function (s) {
                                        if (s.socialCpm && s.socialCpm > partnerFee && s.isInternal) {
                                            partnerFee = s.socialCpm;
                                        }
                                        if (s.socialCpm && s.socialCpm > dataCPM) {
                                            dataCPM = s.socialCpm;
                                            vm.adGroup.dataSourceId = s.dataSourceId;
                                            vm.adGroup.maxCpmAudienceId = s.audienceId;
                                        }
                                    });
                                });
                                break;
                            case 6:
                                vm.adGroup.audienceSegmentGroups.forEach(function (g) {
                                    g.segments.forEach(function (s) {
                                        if (s.emailPrice && s.emailPrice > partnerFee && s.isInternal && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                            partnerFee = s.emailPrice;
                                        }
                                        if (s.activeReach > emailReach) {
                                            emailReach = s.activeReach;
                                        }
                                        if (s.emailPrice && s.emailPrice > dataCPM && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                            dataCPM = s.emailPrice;
                                            vm.adGroup.dataSourceId = s.dataSourceId;
                                            vm.adGroup.maxCpmAudienceId = s.audienceId;
                                        }
                                    });
                                    if (vm.campaign.isMultiChannel && vm.adGroup.channelIndex > 0) {
                                        dataCPM = dataCPM + 0.15;
                                    }
                                });
                                break;
                            case 9:
                                if (vm.adGroup.audienceSegmentGroups.length > 0) {
                                    vm.adGroup.audienceSegmentGroups.forEach(function (g) {
                                        g.segments.forEach(function (s) {
                                            if (s.displayCpm && s.displayCpm > partnerFee && s.isInternal && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                                partnerFee = s.displayCpm;
                                            }
                                            if (s.socialCpm && s.socialCpm > 0) {
                                                if (vm.dataCPMChanged === false) {
                                                    dataCPM = s.socialCpm;
                                                }
                                                vm.adGroup.dataSourceId = s.dataSourceId;
                                                vm.adGroup.maxCpmAudienceId = s.audienceId;
                                            }
                                            else {
                                                if (s.displayCpm && s.displayCpm > 0) {
                                                    if (vm.dataCPMChanged === false) {
                                                        dataCPM = s.displayCpm;
                                                    }
                                                    vm.adGroup.dataSourceId = s.dataSourceId;
                                                    vm.adGroup.maxCpmAudienceId = s.audienceId;
                                                }
                                            }
                                        });
                                    });
                                }
                                else {
                                    dataCPM = 0.00;
                                }
                                break;
                            default:
                                if (vm.noUpdateDataCpm) {
                                    vm.adGroup.audienceSegmentGroups.forEach(function (g) {
                                        g.segments.forEach(function (s) {
                                            if (s.displayCpm && s.displayCpm > partnerFee && s.isInternal && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                                partnerFee = s.displayCpm;
                                            }
                                            if (s.displayCpm && s.displayCpm > externalDataCPM && !s.isInternal) {
                                                externalDataCPM = s.displayCpm;
                                            }
                                            if (s.displayCpm && s.displayCpm > dataCPM && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                                dataCPM = s.displayCpm;
                                                vm.adGroup.dataSourceId = s.dataSourceId;
                                                vm.adGroup.maxCpmAudienceId = s.audienceId;
                                            }
                                        });
                                    });
                                } else {
                                    var maxCPMs = {};
                                    const dsKey = (dataSourceId) => {
                                        if ([1, 2, 3].find(x => x == dataSourceId))
                                            return 1;
                                        else if ([4, 6].find(x => x == dataSourceId))
                                            return 4;
                                        else
                                            return dataSourceId;
                                    };

                                    const setAdGroupCpmData = (s) => {
                                        if (s.displayCpm && s.displayCpm > partnerFee && s.isInternal && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                            partnerFee = s.displayCpm;
                                        }

                                        var key = dsKey(s.dataSourceId);
                                        if (key && s.displayCpm && (!maxCPMs[key] || s.displayCpm > maxCPMs[key]) && (!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1)) {
                                            maxCPMs[key] = s.displayCpm;
                                            if (dataCPM < s.displayCpm) {
                                                dataCPM = s.displayCpm;
                                                vm.adGroup.dataSourceId = s.dataSourceId;
                                                vm.adGroup.maxCpmAudienceId = s.audienceId;
                                            }
                                        }
                                    }

                                    vm.adGroup.audienceSegmentGroups.forEach(function (g) {
                                        g.segments.forEach(function (s) {
                                            setAdGroupCpmData(s);
                                        });
                                    });
                                    const triggerKey = dsKey(1); //trigger audiences has dataSourceId = 1
                                    if(vm.adGroup.triggerAudienceDataCPM){
                                        const triggerSegment = {
                                            displayCpm: vm.adGroup.triggerAudienceDataCPM,
                                            dataSourceId: 1,
                                            name: 'LassoTrigger_AG',
                                            excluded: false,
                                            isInternal: true,
                                        };
                                        setAdGroupCpmData(triggerSegment);
                                    }
                                    dataCPM = _.sum(Object.values(maxCPMs));
                                }

                                break;
                        }

                        var maxDataCPM = 0;
                        var maxSocialCPM = 0;
                        var maxEmailPrice = 0;
                        vm.adGroup.audienceSegmentGroups.forEach(function (g) {
                            g.segments.forEach(function (s) {
                                if (s.displayCpm && s.displayCpm > maxDataCPM && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                    maxDataCPM = s.displayCpm;
                                }
                                if (s.socialCpm && s.socialCpm > maxSocialCPM && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                    maxSocialCPM = s.socialCpm;
                                }
                                if (s.emailPrice && s.emailPrice > maxEmailPrice && ((!s.excluded || s.name.indexOf('LassoTrigger_AG') == -1) || vm.adGroup.dynamicSequentialMessaging)) {
                                    maxEmailPrice = s.emailPrice;
                                }
                            });
                        });

                        var bsCPM = 0;
                        if (vm.adGroup.brandSafetySegments) {
                            vm.adGroup.brandSafetySegments.forEach(function (s) {
                                if (s.displayCpm) {
                                    bsCPM = bsCPM + s.displayCpm;
                                }
                            });
                        }

                        if (vm.noUpdateDataCpm && vm.adGroup.channelId !== 3 && vm.adGroup.channelId !== 6 && vm.adGroup.channelId !== 9)
                            dataCPM = partnerFee;
                        if (vm.adGroup.triggerAudienceDataCPM && vm.adGroup.triggerAudienceDataCPM > dataCPM && vm.adGroup.dynamicSequentialMessaging) {
                            dataCPM = vm.adGroup.triggerAudienceDataCPM;
                        }

                        var deviceExtension = vm.adGroup.crossDeviceExtension ? 0.35 : (vm.adGroup.tapAdCrossDeviceExtension ? 0.50 : 0);

                        if (vm.adGroup.channelId === 16 || vm.adGroup.channelId == 25)
                            dataCPM = 0;

                        if (vm.adGroup.channelId !== 9) {
                            if (vm.noUpdateDataCpm)
                                vm.adGroup.dataCPM = Math.round((dataCPM + externalDataCPM + bsCPM + deviceExtension) * 100) / 100;
                            else
                                vm.adGroup.dataCPM = Math.round((dataCPM + bsCPM + deviceExtension) * 100) / 100;
                        }
                        else {
                            if (vm.dataCPMChanged === false) {
                                vm.adGroup.dataCPM = dataCPM.toFixed(2);
                            }
                        }

                        vm.adGroup.partnerFee = Math.round((partnerFee + deviceExtension) * 100) / 100;

                        if (vm.adGroup.channelId === 6) {
                            vm.adGroup.totalEmailReach = Math.round(emailReach);
                            vm.adGroup.totalPossibleSpend = Math.round(emailReach * partnerFee * 100) / 100;
                        }

                        $rootScope.$broadcast('dataCPMUpdated', { channelIndex: vm.adGroup.channelIndex, dataCpm: maxDataCPM + 0.15, socialCpm: maxSocialCPM, emailPrice: maxEmailPrice });
                    };

                    if (vm.adGroup.channelId) {
                        vm.selectChannel(vm.adGroup.channelId);
                    }

                    $scope.$on('adGroupSelected', function (event, data) {
                        var selectedAdGroup = campaignService.getSelectedAdGroup();
                        vm.adGroup = selectedAdGroup;
                        setAdGroupData();
                        if (!vm.adGroup.initialAdGroupStatus)
                            vm.adGroup.initialAdGroupStatus = vm.adGroup.adGroupStatusId;

                        vm.inventoryLists = data.inventoryLists;

                        if (selectedAdGroup)
                            vm.adGroup = selectedAdGroup;

                        _.forEach(vm.inventoryListsArray, function (arr, key) {
                            vm.inventoryListsArray[key]['checked'] = false;
                            vm.inventoryListsArray.allChecked = false;
                        });

                        checkSelectedInventoryLists(vm.inventoryLists, vm.inventoryListsArray);

                    });

                    $scope.$on('marketersNamesLoaded', function () {
                        vm.marketersNames = vm.rootVM.marketersNames;
                    });

                    $scope.$on('segmentsChanged', function (event, data) {
                        countCategories(vm.adGroup.audienceSegmentGroups);
                        if (vm.customSegmentsLoaded && data.listedAudiences) {
                            $timeout(function () {
                                vm.activeTab = 5;
                                var audienceIds = vm.adGroup.listedAudienceIds.replace(' ', '').split(',');
                                $scope.$broadcast('listedAudiencesAdded', audienceIds);
                            })
                        }
                    });

                    $scope.$on('selectedMarketerChanged', function (event, selectedMarketerId) {
                        setSelectedMarketer(selectedMarketerId);
                        filterAudiencesByPartnerAndAdvertiser();
                        setVisibleOptions(vm.selectedMarketer);
                    });

                    $scope.sortBy = function (item) {
                        return item.bc + item.name;
                    };

                    $scope.$watch('vm.adGroup.selectedItems.length', function () {
                        countCategories(vm.adGroup.audienceSegmentGroups);
                    });

                    $scope.$watch('potentialReach', function (newVal) {
                        if (typeof newVal !== 'undefined')
                            vm.potentialReach = newVal ? newVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
                    });

                    $scope.$watch('vm.adGroup.excludeMyAdvertiserCustomers', function (newVal) {
                        $rootScope.$broadcast('excludeMyAdvertiserCustomersChanged', { excludeMyAdvertiserCustomers: vm.adGroup.excludeMyAdvertiserCustomers });
                    });

                    $scope.$on('disableExcludeMyAdvertiserCustomers', function (event, data) {
                        if (data.disableExcludeMyAdvertiserCustomers)
                            vm.disableExcludeMyAdvertiserCustomers = true;
                        else
                            vm.disableExcludeMyAdvertiserCustomers = false;

                        if (vm.disableExcludeMyAdvertiserCustomers)
                            vm.adGroup.excludeMyAdvertiserCustomers = false;
                    });

                    $scope.$on('includedGroupsSegmentsChanged', function (event) {
                        countCategories(vm.adGroup.audienceSegmentGroups);
                        vm.containsCustomAudience = vm.adGroup.audienceSegmentGroups.find(function (g) {
                            return g.segments.find(function (s) { return s.audienceType === 'Custom' || s.audienceType === 'Prescriber' || s.audienceType === 'retargeting'; });
                        });
                    });

                    vm.getRecencyTimeUnitName = function () {
                        var recency = vm.recencyTimeUnits.find(function (r) { return r.id == vm.adGroup.recencyTimeUnit; })

                        if (recency)
                            return recency.name;
                    }

                    vm.getFrequencyTimeUnitName = function (frequencyTimeUnit) {
                        var frequency = vm.frequencyTimeUnits.find(function (r) { return r.id == frequencyTimeUnit; })

                        if (frequency)
                            return frequency.name;
                    }

                    vm.filteredFrequencyTimeUnits = function () {
                        return vm.frequencyTimeUnits.filter(function (ftu) {
                            return !vm.adGroup.frequencies.find(function (f) { return f.frequencyTimeUnit === ftu.id; });
                        });
                    }

                    //Measurement Only section

                    vm.uploadXLSX = function (file) {
                        return hub.webapicontext.uploadFile('Creative/MeasurementXLSXUpload', file).then(function (data) {
                            vm.fileName = file.name;
                            vm.adGroup.creatives = data.data;
                            var invalid = false;
                            vm.adGroup.creatives.forEach(function (creative) {
                                if (creative.name.length > 100)
                                    invalid = true;
                                creative.advertiserId = vm.adGroup.advertiserId;
                                creative.marketerId = vm.adGroup.marketerId;
                                creative.channelId = vm.adGroup.channelId;
                                if (creative.trackingCode)
                                    creative.trackingCodes = [{ trackingCodeValue: creative.trackingCode, trackingCodeMode: 'Lifetime' }];
                            });

                            if (invalid) {
                                vm.adGroup.validate = true;
                                vm.adGroup.creatives = undefined;
                            }
                            else {
                                vm.adGroup.validate = false;
                            }
                        });
                    };

                    function convertToCSV(objArray, headerList) {
                        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                        let str = '';
                        let row = '';
                        headerList.forEach(function (h) {
                            row += h + ',';
                        });

                        row = row.slice(0, -1);
                        str += row + '\r\n';
                        for (let i = 0; i < array.length; i++) {
                            let line = '';
                            headerList.forEach(function (h) {
                                line += array[i][h] + ','
                            });
                            line = line.slice(0, -1);
                            str += line + '\r\n';
                        }
                        return str;
                    }

                    vm.downloadCreativesList = function () {
                        var a = document.createElement("a");
                        var data = [];
                        vm.adGroup.creatives.forEach(function (c) {
                            data.push({
                                Name: c.name, ImpressionTracker: 'https://trc.lhmos.com/msmnt/?id=' + c.id + '&siteID=[Client Insert Macro]&creativeID=[Client Insert Macro]&placementID=[Client Insert Macro]',
                                ClickTracker: 'https://trc.lhmos.com/msmnt/click/?id=' + c.id + '&siteID=[Client Insert Macro]&creativeID=[Client Insert Macro]&placementID=[Client Insert Macro]&maid=[Client Insert Macro]&redir=[Client Insert Macro]',
                                TrackingCode: c.trackingCode
                            });
                        });

                        var csv = convertToCSV(data, ['Name', 'ImpressionTracker', 'ClickTracker', 'TrackingCode'])
                        var name = vm.adGroup.name + '_creatives.csv';
                        if (window.navigator.msSaveOrOpenBlob) {
                            var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
                                type: "text/csv;charset=utf-8;"
                            });
                            navigator.msSaveBlob(blob, name);
                        } else {

                            a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
                            a.target = '_blank';
                            a.download = name;
                            document.body.appendChild(a);
                            a.click();
                        }
                    }

                    //End of Measurement Only section

                    return vm;
                }]
            };
        }]);

    angular.module('app')
        .controller('splitEditorController', ['$scope', 'split', 'adGroup', 'inventoryListsArray', '$uibModalInstance', 'hub', 'campaignService', '$timeout', function ($scope, split, adGroup, inventoryListsArray, $modalInstance, hub, campaignService$timeout) {
            var vm = this;
            $scope.vm = vm;
            vm.split = angular.copy(split);
            vm.adGroup = adGroup;
            vm.inventoryListsArray = angular.copy(inventoryListsArray);
            vm.inventoryListsArray.forEach(function (il) {
                il.unchecked = true; il.value = il.id;
                il.shortName = il.name.length > 32 ? il.name.substring(0, 32) + '...' : il.name;
            });

            vm.adGroup.creatives.forEach(function (c) {
                c.shortName = c.name.length > 50 ? c.name.substring(0, 50) + '...' : c.name;
            });


            if (vm.split.conditionsJSON)
                vm.split.conditions = JSON.parse(vm.split.conditionsJSON);

            vm.itemsToSelectBatch = [
                {
                    value: 'targeting', name: 'Targeting', items: [
                        {
                            checked: vm.split.conditions.find(function (c) { return c.field === 'inventory_type'; }),
                            field: 'inventory_type', name: 'Supply Type', operator: 'in', values: [
                                { value: 'app', name: 'App' },
                                { value: 'web', name: 'Web' }
                            ]
                        },
                        {
                            checked: vm.split.conditions.find(function (c) { return c.field === 'device_type'; }),
                            field: 'device_type', name: 'Device Type', operator: 'in', values: [
                                { value: 'desktop', name: 'Desktop' },
                                { value: 'tablet', name: 'Tablet' },
                                { value: 'mobile', name: 'Mobile' },
                                { value: 'ctv', name: 'CTV' }
                            ]
                        },
                        {
                            checked: vm.split.conditions.find(function (c) { return c.field === 'predicted_iab_view_rate'; }),
                            field: 'predicted_iab_view_rate', name: 'Viewability Minimum', operator: '>=', values: [{}]
                        },
                        {
                            checked: vm.split.conditions.find(function (c) { return c.field === 'inventory_url_list'; }),
                            field: 'inventory_url_list', name: 'Inventory List', operator: 'in', values: vm.inventoryListsArray
                        }
                    ]
                },
                {
                    value: 'creatives', name: 'Creatives', items: [
                        {
                            checked: true, field: 'creatives', name: 'Creatives', values: angular.copy(vm.adGroup.creatives)
                        }
                    ]
                }
            ];

            if (!vm.split.isNew || vm.split.isEdit)
                vm.itemsToSelectBatch.forEach(function (its) {
                    if (its.value === 'targeting') {
                        if (vm.split.conditions)
                            its.items.forEach(function (item) {
                                item.values.forEach(function (v) {
                                    if (item.field == 'predicted_iab_view_rate') {
                                        if (vm.split.conditions.find(function (c) { return c.field == item.field; }))
                                            v.value = parseInt(vm.split.conditions.find(function (c) { return c.field == item.field; }).value[0]);
                                    }
                                    else {
                                        if (!vm.split.conditions.find(function (c) { return c.field == item.field && c.value.indexOf(v.value) > -1; }))
                                            v.unchecked = true;
                                        else if (item.field == 'inventory_url_list')
                                            v.unchecked = false;
                                    }
                                });
                            });
                    } else if (its.value === 'creatives') { // && vm.split.creativeIds && vm.split.creativeIds.length > 0) {
                        its.items[0].values.forEach(function (v) {
                            if (vm.split.creativeIds.indexOf(v.id) === -1)
                                v.unchecked = true;
                        });
                    }
                });

            vm.itemsToSelect = vm.itemsToSelectBatch[0];

            vm.checkAll = function (item) {
                if (item.field !== 'inventory_url_list' && item.field !== 'predicted_iab_view_rate')
                    item.values.forEach(function (v) { v.unchecked = item.checked; });
                item.checked = !item.checked;
                vm.prepareItems();
            }

            vm.checkboxDisabled = function (item, v) {
                return item.field === 'inventory_url_list' && item.values.find(val => !val.unchecked) && v.unchecked;
            }

            vm.prepareItems = function () {
                vm.itemsToSelectBatch[0].resPreview = '';
                vm.itemsToSelectBatch[0].items.forEach(function (item) {
                    var res = item.values.filter(function (v) { return !v.unchecked; });
                    if (res) {
                        item.resValue = _.map(res, 'value').toString();
                        item.resName = !item.resValue ? '' : (item.field === 'inventory_url_list' ? _.map(res, 'value').toString() + ' - ' + _.map(res, 'shortName').toString() : (item.field !== 'predicted_iab_view_rate' ? _.map(res, 'name').toString() : item.resValue + '%'));
                        item.longResName = !item.resValue ? '' : (item.field === 'inventory_url_list' ? _.map(res, 'value').toString() + ' - ' + _.map(res, 'name').toString() : (item.field !== 'predicted_iab_view_rate' ? _.map(res, 'name').toString() : item.resValue + '%'));
                        if (!vm.itemsToSelectBatch[0].resPreview && item.resName)
                            vm.itemsToSelectBatch[0].resPreview = item.name + ': ' + (item.field !== 'predicted_iab_view_rate' ? (item.field === 'inventory_url_list' ? res[0].value.toString() + ' - ' + res[0].shortName : res[0].name) : item.resValue + '%') + '...';
                    }
                    if (item.field !== 'inventory_url_list' && item.field !== 'predicted_iab_view_rate')
                        item.checked = item.values.find(function (v) { return !v.unchecked; });
                });
            }

            vm.getCreativeName = function (id) {
                var creative = vm.adGroup.creatives.find(c => c.id === id);
                if (creative)
                    return creative.name;
            }

            vm.getClassByItemValue = function (val) {
                switch (val) {
                    case 'targeting':
                        return 'target-list';
                    case 'creatives':
                        return 'creative-list';
                }
            }

            vm.getIconClassByItemValue = function (val) {
                switch (val) {
                    case 'targeting':
                        return 'icon-target';
                    case 'creatives':
                        return 'icon-banner';
                }
            }

            vm.prepareItems();

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            }

            $scope.ok = function () {
                vm.itemsToSelectBatch.forEach(function (its) {
                    if (its.value === 'targeting') {
                        vm.split.conditions = [];
                        its.items.filter(x => x.checked).forEach(function (item) {
                            var res = item.values.filter(function (v) { return !v.unchecked; });
                            if (res && _.map(res, 'value').length > 0) {
                                var cond = {
                                    name: item.name, field: item.field, operator: item.operator,
                                    value: _.map(res, function (obj) {
                                        if (item.field === 'predicted_iab_view_rate')
                                            return obj.value.toString();
                                        else
                                            return obj.value;
                                    }), resName: _.map(res, 'name').toString()
                                };
                                if (cond.field === 'inventory_url_list') {
                                    var il = vm.inventoryListsArray.find(i => i.id == cond.value[0]);
                                    if (il.inventoryListType === 1)
                                        cond.operator = 'not_in';
                                    else if (il.inventoryListType === 2)
                                        cond.operator = 'all';
                                }
                                if (cond.field === 'predicted_iab_view_rate') {
                                    cond.resName = cond.value + "%";
                                    cond.operator = '>=';
                                }

                                vm.split.conditions.push(cond);
                            }
                        });
                        vm.split.conditionsJSON = JSON.stringify(vm.split.conditions);
                        vm.split.resPreview = its.resPreview;
                    } else if (its.value === 'creatives') {
                        vm.split.creativeIds = _.map(its.items[0].values.filter(function (c) { return !c.unchecked; }), 'id');
                    }
                });

                $modalInstance.close(vm.split);
            }
        }]);
})();
