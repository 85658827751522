(function () {
    'use strict';
    angular.module('app')
        .directive('creativeEditor', ['$location', function () {
            return {
                template: require('@views/creative/editors/creative-editor.html').default,
                restrict: 'E',
                controllerAs: 'vm',
                scope: { creative: '=', isCreativePage: '=', justUploaded: '=', viewMode: '=', zoom: '=' },
                controller: ['$rootScope', '$scope', '$parse', 'hub', '$sce', /*'jwplayerService',*/ 'campaignService', 'creativeService', 'Enum', '$timeout', 'authService', '$uibModal', 'accountService', 'validationService', '$document', 'api', function ($rootScope, $scope, $parse, hub, $sce, /*jwplayerService,*/ campaignService, creativeService, Enum, $timeout, authService, $modal, accountService, validationService, $document, api) {
                    const jwplayerService = {}
                    var vm = this;
                    var rootVM = $rootScope.rootVM;
                    vm.rootVM = rootVM;
                    const TRACER_HOST = hub.settings.environment === 'production' ? 'https://trc.lhmos.com' : 'https://trc-tst.lhmos.com';
                    vm.isCreativePage = $scope.isCreativePage;
                    vm.creative = $scope.creative;
                    vm.creativeDefaultExchangeSeat = hub.settings.environment === 'production' ? 11550 : 13361;

                    if (!vm.creative.impressionTrackers) {
                        vm.creative.impressionTrackers = [];
                    }
                    if (vm.creative.impressionTrackers.length < 1) {
                        vm.creative.impressionTrackers.push({ Tracker: "" });
                    }

                    vm.hasAccess = authService.hasAccess;
                    vm.isObserver = authService.isObserver;
                    vm.isAdmin = authService.userAccountType() === "Admin";
                    vm.isAdvertiser = authService.userAccountType() === "Advertiser" || authService.userAccountType() === "AdvertiserGroup";
                    vm.account = accountService.get();
                    vm.selectedCreativeSlide = vm.creative.creativeSlides[0];
                    vm.creative.canvasCollectionStep = 1;
                    vm.creativeTypes = campaignService.getCreativeTypes();
                    vm.isZoom = $scope.zoom;
                    vm.campaignStatuses = campaignService.getCampaignStatuses();
                    vm.adBlocked = $rootScope.canRunAds === undefined;
                    vm.emailTab = 'html';

                    if (!vm.creative.trackingCodes || vm.creative.trackingCodes.length === 0)
                        vm.creative.trackingCodes = [{}];

                    if (!vm.creative.exchangeSeatId) {
                        vm.noExchangeSeatId = true;
                        vm.creative.exchangeSeatId = vm.creativeDefaultExchangeSeat;
                    }

                    vm.getCampaignStatusNameById = function (statusId) {
                        var name = '';
                        vm.campaignStatuses.forEach(function (item) {
                            if (item.id == statusId)
                                name = item.name;
                        });
                        return name;
                    }

                    vm.viewMode = $scope.viewMode;
                    vm.getCreativeStatusById = creativeService.getCreativeStatusById;

                    if (!vm.creative.width && vm.creative.channelId == 1) {
                        vm.sizes = campaignService.getSizes();
                        vm.creative.size = vm.sizes.find(function (s) { return s.id == vm.creative.bannerSize });
                        vm.creative.width = vm.creative.size.name.substring(0, vm.creative.size.name.indexOf('x'));
                        vm.creative.height = vm.creative.size.name.substring(vm.creative.size.name.indexOf('x') + 1);
                        vm.creative.height = parseInt(vm.creative.height);
                        vm.creative.width = parseInt(vm.creative.width);
                    }

                    vm.facebookAdFormats = creativeService.getFacebookAdFormats();
                    vm.facebookAdFormat = vm.facebookAdFormats[0];
                    vm.changeAdFormat = function (next) {
                        if (next && vm.facebookAdFormat.id < vm.facebookAdFormats.length)
                            vm.facebookAdFormat = vm.facebookAdFormats[vm.facebookAdFormat.id];
                        else if (!next && vm.facebookAdFormat.id > 1)
                            vm.facebookAdFormat = vm.facebookAdFormats[vm.facebookAdFormat.id - 2];
                    }

                    vm.callToActions = creativeService.getCallToActions();
                    vm.getCallToActionText = function (value) {
                        var callToAction = vm.callToActions.find(function (item) { return item.value == value; });
                        return callToAction ? callToAction.text : 'Shop Now';
                    }

                    vm.justUploaded = $scope.justUploaded;
                    vm.warning = null;
                    vm.showCommentHistory = false;
                    vm.showAuditAttributes = false;

                    if (vm.creative.id)
                        hub.webapicontext.getCreativeHistory(vm.creative.id).then(function (historyRecords) {
                            if (historyRecords.length > 0)
                                vm.creative.creativeHistory = historyRecords;
                        });

                    if (vm.creative.advertiserId) {
                        var advertiser = campaignService.advertisers.find(function (b) { return b.id == vm.creative.advertiserId; })
                        if (advertiser && advertiser.campaignManagers && advertiser.campaignManagers.length > 0)
                            vm.creative.notifiedCampaignManagers = advertiser.campaignManagers.join(';');

                        setAvailableActions(vm.creative, advertiser);
                        vm.selectedAdvertiser = advertiser;
                        campaignService.getAccounts(2).then(function () {
                            vm.marketers = [];
                            campaignService.marketers.forEach(function (r) {
                                if (vm.selectedAdvertiser && vm.selectedAdvertiser.marketer.id == r.id) {
                                    if (r.status == 0)
                                        vm.marketers.push(r);
                                }
                            });
                        });
                    }

                    vm.seatIds = [
                      { id: vm.creativeDefaultExchangeSeat, name: 'Lasso Xandr Seat' }
                    ];

                    if (vm.creative.marketerId) {
                        campaignService.getAccounts(2).then(function () {
                            vm.selectedMarketer = campaignService.marketers.find(function (r) { return r.id == vm.creative.marketerId; })
                            vm.selectedMarketer.avatarUrl = hub.settings.imagesUrl + 'avatars/' + vm.selectedMarketer.avatar;

                            var allowedChannels = vm.selectedMarketer.options.find(function (o) { return o.optionId == 6; });
                            if (allowedChannels) {
                                var allowedChannelsIds = JSON.parse(allowedChannels.value);
                                vm.filteredChannels = vm.channels.filter(function (ch) { return allowedChannelsIds.indexOf(ch.id) > -1; });
                            }
                            else {
                                vm.filteredChannels = vm.channels;
                            }
                            var dgidMacroOption = vm.selectedMarketer.options.find(function (o) { return o.optionId == 10170 });
                            vm.dgidMacroEnabled = dgidMacroOption && dgidMacroOption.enabled;


                          if (!vm.seatIdsCreated && vm.selectedMarketer.exchangeSettings && vm.selectedMarketer.exchangeSettings[1]) {
                              vm.seatIds.push({ id: parseInt(vm.selectedMarketer.exchangeSettings[1].externalId), name: vm.selectedMarketer.name + ' Seat' });
                              if (!vm.creative.id) {
                                 vm.creative.exchangeSeatId = parseInt(vm.selectedMarketer.exchangeSettings[1].externalId);
                              }
                          }

                          vm.creative.exchangeSeat = vm.seatIds.find(s => s.id === vm.creative.exchangeSeatId);
                        });
                    }



                    hub.datacontext.getLocalJson('provisionalCreativeAudit').then(function (l) {
                        vm.creative.provisionalAuditData = angular.copy(l);
                        creativeService.fillSetAuditData(vm.creative);
                    });


                    vm.GetDateInCurrentTimezone = function (stringDate) {
                        var d = new Date(stringDate);
                        return d.toLocaleString();
                    }

                    if (vm.creative.thirdPartyCreativeType)
                        vm.creative.thirdPartyCreativeType = vm.creative.thirdPartyCreativeType.toString();
                    vm.httpsRegex = '^https://.*$';
                    if (vm.creative) {
                        vm.creative.secureVASTValid = null;
                        vm.creative.nonSecureVASTValid = null;
                    }

                    vm.marketers = campaignService.marketers;
                    vm.advertisers = campaignService.advertisers;

                    vm.selectedMarketerId = campaignService.getSelectedMarketerId();
                    vm.adGroup = campaignService.getSelectedAdGroup();
                    vm.campaign = campaignService.getSelectedCampaign();

                    vm.sizes = campaignService.getSizes();

                    if (vm.isCreativePage)
                        vm.selectedAdvertiser = vm.advertisers.filter(function (b) { return b.id == vm.creative.advertiserId; })[0];
                    else
                        vm.selectedAdvertiser = vm.campaign.advertiser;


                    if (vm.creative) {
                        vm.emptyCreative = vm.creative.channelId == 0;
                        if (vm.emptyCreative) {
                            vm.creative.channelId = null;
                        }
                    }

                    vm.selectedAdvertiserChanged = function () {
                        if (vm.selectedAdvertiser) {
                            vm.creative.advertiserId = vm.selectedAdvertiser.id;
                            if (vm.creative.advertiserId) {
                                var advertiser = campaignService.advertisers.find(function (b) { return b.id == vm.creative.advertiserId; });
                                if (advertiser && advertiser.campaignManagers && advertiser.campaignManagers.length > 0)
                                    vm.creative.notifiedCampaignManagers = advertiser.campaignManagers.join(';');

                                setAvailableActions(vm.creative, advertiser);
                            }
                            if (vm.rootVM.accountId) {
                                vm.marketers = [];
                                campaignService.getAccounts(2).then(function () {
                                    campaignService.marketers.forEach(function (r) {
                                        if (vm.selectedAdvertiser.marketers.indexOf(r.id) >= 0) {
                                            if (r.status == 0)
                                                vm.marketers.push(r);
                                        }
                                    });
                                });
                            }
                        }
                        else {
                            vm.creative.advertiserId = null;
                            if (vm.rootVM.accountId)
                                vm.selectedMarketer = null;
                        }
                    }

                    function setAvailableActions(creative, advertiser) {
                        var advertiserManagementType = advertiser && advertiser.options ? advertiser.options.find(function (b) { return b.optionId == 9066; }) : null;
                        if (!advertiserManagementType || advertiserManagementType.value === "1") {
                            creative.adminManaged = true;
                            if (!creative.id) {
                                vm.creative.availableActions = [];
                                if (vm.isAdmin)
                                    vm.creative.availableActions.push('AdvertiserApprove');
                            }
                        }
                        else {
                            creative.adminManaged = false;
                            if (!creative.id) {
                                vm.creative.availableActions = [];
                                if (vm.isAdmin) {
                                    vm.creative.availableActions.push('SendToCreativeTeam');
                                    vm.creative.availableActions.push('SendToAdvertiser');
                                    vm.creative.availableActions.push('TDMApprove');
                                    vm.creative.availableActions.push('AdvertiserApprove');
                                }
                                else if (vm.isAdvertiser)
                                    vm.creative.availableActions.push('SendForReview');
                            }
                        }
                    }

                    vm.selectedMarketerChanged = function () {
                        if (vm.selectedMarketer) {
                            vm.creative.marketerId = vm.selectedMarketer.id;
                            var allowedChannels = vm.selectedMarketer.options.find(function (o) { return o.optionId == 6; });
                            if (allowedChannels) {
                                var allowedChannelsIds = JSON.parse(allowedChannels.value);
                                vm.filteredChannels = vm.channels.filter(function (ch) { return allowedChannelsIds.indexOf(ch.id) > -1; });
                            }
                            else {
                                vm.filteredChannels = vm.channels;
                            }
                            var dgidMacroOption = vm.selectedMarketer.options.find(function (o) { return o.optionId == 10170 });
                            vm.dgidMacroEnabled = dgidMacroOption && dgidMacroOption.enabled;

                            getMacros();
                            vm.advertisers = campaignService.advertisers.filter(function (a) { return a.marketerId == vm.selectedMarketer.id; });

                            vm.seatIds = [
                              { id: vm.creativeDefaultExchangeSeat, name: 'Lasso Xandr Seat' }
                            ];
                            if (vm.selectedMarketer.exchangeSettings && vm.selectedMarketer.exchangeSettings[1]) {
                                vm.seatIds.push({ id: parseInt(vm.selectedMarketer.exchangeSettings[1].externalId), name: vm.selectedMarketer.name + ' Seat' });
                                vm.seatIdsCreated = true;
                                if (!vm.creative.id) {
                                   vm.creative.exchangeSeatId = parseInt(vm.selectedMarketer.exchangeSettings[1].externalId);
                                }
                            } else {
                                vm.creative.exchangeSeatId = vm.creativeDefaultExchangeSeat;
                            }
                            vm.creative.exchangeSeat = vm.seatIds.find(s => s.id === vm.creative.exchangeSeatId);
                        }
                        if (!vm.creative.id) {
                            vm.creative.channelId = '';
                            vm.selectedChannel = null;
                        }
                    };

                    vm.selectedSeatIdChanged = function () {
                        vm.creative.exchangeSeatId = vm.creative.exchangeSeat.id;
                    };

                    vm.creative.duplicateName = false;
                    vm.creativeNameChanged = function () {
                        vm.creative.duplicateName = false;
                        if (vm.creative.advertiserId) {
                            var validationData = {};
                            validationData.entityName = vm.creative.name;
                            validationData.entityType = "creative";
                            validationData.advertiserId = vm.creative.advertiserId;
                            validationData.creativeId = vm.creative.id
                            hub.webapicontext.validateName(validationData).then(function (data) {
                                if (data.message !== "Valid Name")
                                    vm.creative.duplicateName = true;
                            });
                        }
                    }

                    function getMacros(){
                          api.getDirect('/internal/macros', TRACER_HOST).then(function (data) {
                              if (data) {
                                  vm.validMacrosList = angular.copy(data.data.lassoMacros);
                                  if (!vm.dgidMacroEnabled){
                                      vm.validMacrosList.splice($.inArray('${DGID}', vm.validMacrosList), 1);
                                  }
                                  vm.validXandrMacros = angular.copy(data.data.xandrMacros);
                                  for (var attr in vm.validXandrMacros) {
                                      vm.validMacrosList.push(vm.validXandrMacros[attr]);
                                  }
                                  validateMacro();
                              }
                          });
                    }

                    if (!vm.validMacros)
                        getMacros();

                    vm.destinationURLChanged = function () {
                        if (vm.validMacrosList)
                            validateMacro();
                    }

                    function validateMacro() {
                        vm.validatedMacros = [];
                        vm.validMacrosList.forEach(function (m) {
                            if (vm.creative.destinationURL && vm.creative.destinationURL.indexOf(m) > -1){
                                vm.validatedMacros.push(m);
                            }
                        });

                        vm.invalidDGIDUsage = !vm.dgidMacroEnabled && vm.creative.destinationURL && vm.creative.destinationURL.indexOf('${DGID}') > -1;

                        vm.creative.validatedXandrMacros = [];
                        for (var attr in vm.validXandrMacros) {
                            if (vm.creative.destinationURL && vm.creative.destinationURL.indexOf(vm.validXandrMacros[attr]) > -1)
                                vm.creative.validatedXandrMacros.push('&' + attr + '=' + vm.validXandrMacros[attr]);
                        }
                    }

                    vm.creativeNameChanged();
                    vm.destinationURLChanged();

                    $scope.$on('selectedMarketerChanged', function (event, selectedMarketerId) {
                        vm.selectedMarketer = vm.marketers.filter(function (r) { return r.id == selectedMarketerId })[0];
                        filterCreativesByPartner();
                    });

                    vm.removeCreative = function () {
                        $rootScope.$broadcast('creativeImageRemoved');
                        vm.creative.bannerURL = '';
                        vm.creative.videoURL = '';
                        vm.warning = null;
                    }

                    vm.downloadCreative = function () {

                        var name = vm.creative.name ? vm.creative.name : 'Creative Name';
                        if (vm.creative.id)
                            name = name + '_' + vm.creative.id;
                        var re = /(?:\.([^.]+))?$/;
                        var downloadURL = vm.creative.videoURL ? vm.creative.videoURL : vm.creative.bannerURL;
                        var ext = re.exec(downloadURL)[1].replace('?uploaded=true', '');
                        var fileName = name + '.' + ext;

                        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                        if (isSafari) {
                            hub.webapicontext.downloadFileFromUrlSafari(downloadURL, fileName).then(function (data) {
                                var url = hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true";
                                var downloadContainer = angular.element('<div data-tap-disabled="true"><a target="_self" download href="' + url + '"></a></div>');
                                var downloadLink = angular.element(downloadContainer.children()[0]);
                                $document.find('body').append(downloadContainer);
                                $timeout(function () {
                                    downloadLink[0].click();
                                    downloadLink.remove();
                                    $timeout(function () {
                                        hub.webapicontext.removeImageSafari(data.data.filename);
                                    }, 1000);
                                }, 500);

                            })
                        } else {
                            hub.webapicontext.downloadFileFromUrl(downloadURL, fileName);
                        }
                    }

                    vm.download = function (url) {
                        hub.downloadFile(url);
                    };

                    vm.removeEmailFile = function (id) {
                        var file = vm.creative.emailFiles[vm.emailTab].find(function (f) { return f.id === id; });
                        if (file)
                            vm.creative.emailFiles[vm.emailTab].splice($.inArray(file, vm.creative.emailFiles[vm.emailTab]), 1);
                    }

                    vm.showAssetsVersions = function () {
                        var modalInstance = $modal.open({
                            templateUrl: 'views/creative/editors/creative-versions.html',
                            animation: false,
                            controller: 'creativeVersionsController',
                            size: 'lg',
                            resolve: {
                                data: function () {
                                    return { creativeVersions: vm.creative.creativeHistory };
                                }
                            }
                        });
                    }

                    vm.removeVideo = function () {
                        $rootScope.$broadcast('creativeVideoRemoved', null);
                        vm.creative.videoURL = '';
                        vm.creative.creativeIconURL = '';
                        $scope.videoFile = '';
                        vm.videoOptions.image = '';
                    }

                    vm.updateHistory = function (oldBannerURL, newBannerURL) {

                        if (oldBannerURL && oldBannerURL !== newBannerURL) {
                            if (!vm.creative.creativeHistory) {
                                vm.creative.creativeHistory = [];
                                vm.creative.creativeHistory.push({ bannerURL: oldBannerURL, validFrom: (new Date()).toUTCString() });
                            }

                            var oldBannerHistoryRecord = _.find(vm.creative.creativeHistory, { 'bannerURL': oldBannerURL });
                            if (!oldBannerHistoryRecord) {
                                vm.creative.creativeHistory.push({ bannerURL: oldBannerURL, validFrom: (new Date()).toUTCString() });
                            }
                        }
                    }

                    vm.fbCreativeObj = {
                        "object_story_spec": {
                            "link_data": {
                                "call_to_action": { "type": "USE_APP", "value": { "link": "https://developers.facebook.com/docs/" } },
                                "description": "Description",
                                "link": "https://developers.facebook.com/docs/",
                                "message": "Message",
                                "name": "Name",
                                "picture": "https://tdxtst.blob.core.windows.net/creatives/a275e609-2424-4062-9e65-3d954f6745be.png"
                            },
                            "page_id": "329224787470885"
                        }
                    }

                    vm.uploadImage = function (file, dimensions, bannerAttributes, warning, mode) {
                        return hub.webapicontext.uploadFile('Creative/ImageUpload', file).then(function (data) {
                            if (vm.creative.channelId == 1 && (data.data.contentType === "application/x-zip-compressed" || data.data.contentType === "application/zip" || data.data.contentType === "multipart/x-zip")) {
                                vm.creative.isHtml5 = true;
                                vm.creative.bannerURL = hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true";
                                vm.creative.originalFileName = data.data.originalFileName;
                            } else if (vm.creative.channelId == 1 || vm.creative.channelId == 3) {
                                vm.wrongDimension = false;
                                vm.wrongCanvasProductDimension = false;
                                var newBanner = hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true";
                                vm.updateHistory(vm.creative.bannerURL, newBanner);
                                vm.creative.bannerURL = newBanner;

                                if (mode == 'carousel' || mode == 'canvasProduct') {
                                    vm.selectedCreativeSlide.imageURL = newBanner;
                                    $timeout(function () {
                                        vm.validateCreativeSlides();
                                    });
                                }

                                vm.nonStandardCreativeSize = false;
                                if (vm.creative.channelId == 1 || vm.creative.channelId == 3) {
                                    if (dimensions) {
                                        var dim = dimensions.split('x');
                                        vm.creative.width = dim[0];
                                        vm.creative.height = dim[1];
                                        vm.creative.height = parseInt(vm.creative.height);
                                        vm.creative.width = parseInt(vm.creative.width);
                                        vm.creative.bannerSize = Enum.getId('BannerSizes', dimensions);
                                        if (!vm.creative.bannerSize)
                                            vm.nonStandardCreativeSize = true;
                                    }
                                }

                                if (bannerAttributes) {
                                    hub.datacontext.getLocalJson('provisionalCreativeAudit').then(function (l) {
                                        vm.creative.provisionalAuditData = angular.copy(l);
                                        vm.creative.technicalAttributes = bannerAttributes;
                                        creativeService.fillSetAuditData(vm.creative);
                                    });
                                }
                                else {
                                    hub.datacontext.getLocalJson('provisionalCreativeAudit').then(function (l) {
                                        vm.creative.provisionalAuditData = angular.copy(l);
                                    });
                                }

                                $timeout(function () {
                                    $('#bannerValidation').trigger('input');
                                });

                                if (warning)
                                    vm.creative.warning = warning;
                                else
                                    vm.creative.warning = null;

                                vm.creativeNameChanged();
                            } else if (vm.creative.channelId === 6) {
                                if (!vm.creative.emailFiles)
                                    vm.creative.emailFiles = {};
                                if (!vm.creative.emailFiles[vm.emailTab])
                                    vm.creative.emailFiles[vm.emailTab] = [];

                                vm.creative.emailFiles[vm.emailTab].push({
                                    id: vm.creative.emailFiles[vm.emailTab].length + 1,
                                    url: hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true",
                                    name: data.data.originalFileName
                                });
                            }
                        });
                    };

                    vm.addCreativeSlide = function () {
                        var newSlide = {};
                        vm.creative.creativeSlides.push(newSlide);
                        vm.selectedCreativeSlide = newSlide;
                    };

                    vm.uploadCreativeSlideImage = function () {
                        $('#carouselButton').trigger('click');
                    };

                    vm.removeCreativeSlide = function () {
                        var newSelectedSlidePosition = vm.creative.creativeSlides.indexOf(vm.selectedCreativeSlide) == 0 ? 0 : vm.creative.creativeSlides.indexOf(vm.selectedCreativeSlide) - 1;
                        vm.creative.creativeSlides.splice($.inArray(vm.selectedCreativeSlide, vm.creative.creativeSlides), 1);
                        vm.selectedCreativeSlide = vm.creative.creativeSlides[newSelectedSlidePosition];
                    };

                    vm.selectSlide = function (cs) {
                        vm.selectedCreativeSlide = cs;
                        $timeout(function () {
                            $('#destinationURLFB').trigger('input');
                        });
                    };

                    vm.removeTrackingCode = function (list, tc) {
                        list.splice($.inArray(tc, list), 1);
                    };

                    vm.removeImpressionTracker = function (index) {
                        vm.creative.impressionTrackers.splice(index, 1);
                        vm.checkForDuplicateImpressionTrackers();
                    }

                    vm.checkForDuplicateImpressionTrackers = function () {
                        let dups_found = [];
                        vm.creative.impressionTrackers.forEach((item, index) => {
                            item.isDuplicate = dups_found.includes(index);
                            for (let x = index + 1; x < vm.creative.impressionTrackers.length; x++) {
                                if (item.impressionTracker == vm.creative.impressionTrackers[x].impressionTracker) {
                                    dups_found.push(x);
                                }
                            }
                        });
                    }

                    $scope.$on('wrongDimension', function (event, data) {
                        vm.dimensionMessage = data.message;
                        $timeout(function () {
                            if (data.isCanvasProduct)
                                vm.wrongCanvasProductDimension = true;
                            else
                                vm.wrongDimension = true;
                        });
                    });

                    vm.uploadVideo = function (file, thumbURL) {
                        return hub.webapicontext.uploadFile('Creative/ImageUpload', file).then(function (data) {
                            vm.creative.videoURL = hub.settings.creativeImagesUrl + data.data.filename + "?uploaded=true";
                            vm.creative.creativeIconURL = thumbURL;
                            $scope.videoFile = $sce.trustAsResourceUrl(vm.creative.videoURL);
                            vm.videoOptions.image = thumbURL;
                            vm.justUploaded = true;
                            $rootScope.$broadcast("videoUploaded", $scope.videoFile);
                        });
                    }

                    vm.cleanPreviousData = function () {
                        vm.creative.name = '';
                        vm.creative.destinationURL = '';
                    }

                    function setCreativeData() {
                        if (vm.marketers) {
                            if (!vm.creative.marketerId && vm.selectedMarketer) {
                                vm.creative.marketerId = vm.selectedMarketer.id;
                                vm.creative.marketer = vm.selectedMarketer.name;
                            }
                            if (!vm.creative.advertiserId && vm.selectedAdvertiser) {
                                vm.creative.advertiserId = vm.selectedAdvertiser.id;
                            }
                        }
                    }

                    vm.validateTag = function () {
                        hub.webapicontext.validateTag(vm.creative.thirdPartyAdTag).then(function (result) {
                            vm.creative.thirdPartyAdTag = result;
                            vm.tagValidated = true;
                        });
                    }

                    vm.bannerSizeChanged = function () {
                        if (vm.creative.size != 'All Sizes') {
                            vm.creative.bannerSize = Enum.getId('BannerSizes', vm.creative.size);
                            vm.creative.width = vm.creative.size.split('x')[0];
                            vm.creative.height = vm.creative.size.split('x')[1];

                            vm.creative.height = parseInt(vm.creative.height);
                            vm.creative.width = parseInt(vm.creative.width);

                            if (isNaN(vm.creative.height) || isNaN(vm.creative.width)) {
                                vm.creative.bannerSize = null;
                                vm.creative.size = null;
                            }
                        }
                    }

                    vm.thirdPartyAdTagChanged = function (newSize) {
                        vm.creative.thirdPartyAdTagUrl = null;
                        vm.tagValidated = false;
                        vm.nonStandardCreativeSize = false;
                        $timeout(function () {
                            vm.creative.height = '';
                            vm.creative.width = '';
                            vm.creative.size = '';
                            if (vm.creative.thirdPartyAdTag && vm.creative.thirdPartyAdTag.indexOf('<ins') > -1) {
                                if (vm.creative.thirdPartyAdTag.match(/height:(\d)*/g))
                                    vm.creative.height = vm.creative.thirdPartyAdTag.match(/height:(\d)*/g)[0].substring(7);
                                if (vm.creative.thirdPartyAdTag.match(/width:(\d)*/g))
                                    vm.creative.width = vm.creative.thirdPartyAdTag.match(/width:(\d)*/g)[0].substring(6);
                                if (vm.creative.height && vm.creative.width)
                                    vm.creative.size = vm.creative.width + 'x' + vm.creative.height;
                            }
                            else if (vm.creative.thirdPartyAdTag && vm.creative.thirdPartyAdTag.indexOf('${CLICK_URL}') > -1) {
                                vm.creative.bannerURL = '';
                                if (vm.creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)) {
                                    var img = new Image();
                                    img.onload = function () {
                                        var allowedDimensions = ['728x90', '300x600', '300x250', '160x600', '320x50', '300x50', '970x90', '970x250', '1x1', '460x120', '570x70', '120x240', '650x136', '1200x628', '320x480', '640x480', '640x640', '120x600', '1920x1080', '768x1024', '1024x768', '320x100', '300x1050', '250x250', '336x280', '480x320',  '320x250', '970x550', '468x60'];
                                        if (allowedDimensions.indexOf(this.width + 'x' + this.height) >= 0) {
                                            vm.creative.size = this.width + 'x' + this.height;
                                            vm.creative.height = this.height;
                                            vm.creative.width = this.width;
                                            vm.creative.bannerURL = vm.creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)[0];

                                            if (vm.creative.size != 'All Sizes') {
                                                vm.creative.bannerSize = Enum.getId('BannerSizes', vm.creative.size);
                                            }
                                        }
                                        else {
                                            vm.dimensionIsValid = false;
                                            vm.validateDimension = true;
                                        }
                                    }
                                    img.src = vm.creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)[0];

                                }
                                if (!vm.creative.size || vm.creative.size.id == 0)
                                    vm.creative.size = vm.creative.thirdPartyAdTag.match(/([0-9]+x[0-9]+)/i)[0];
                                else if (vm.creative.size.id)
                                    vm.creative.size = vm.creative.size.name;

                                if (vm.creative.size) {
                                    vm.creative.width = vm.creative.size.split('x')[0];
                                    vm.creative.height = vm.creative.size.split('x')[1];
                                }

                            } else if (vm.creative.thirdPartyAdTag && vm.creative.thirdPartyAdTag.indexOf('<script ') > -1) {
                                if (!vm.creative.size || vm.creative.size.id == 0) {
                                    if (vm.creative.thirdPartyAdTag.match(/([0-9]+x[0-9]+)/i)) {
                                        vm.creative.size = vm.creative.thirdPartyAdTag.match(/([0-9]+x[0-9]+)/i)[0];
                                        if (vm.creative.size) {
                                            vm.creative.width = vm.creative.size.split('x')[0];
                                            vm.creative.height = vm.creative.size.split('x')[1];
                                        }
                                    }
                                    else {
                                        if (vm.creative.thirdPartyAdTag.match(/h=(\d)*/g))
                                            vm.creative.height = vm.creative.thirdPartyAdTag.match(/h=(\d)*/g)[0].substring(2);
                                        if (vm.creative.thirdPartyAdTag.match(/w=(\d)*/g))
                                            vm.creative.width = vm.creative.thirdPartyAdTag.match(/w=(\d)*/g)[0].substring(2);
                                        if (vm.creative.height && vm.creative.width)
                                            vm.creative.size = vm.creative.width + 'x' + vm.creative.height;
                                    }
                                } else {
                                    if (vm.creative.size.id)
                                        vm.creative.size = vm.creative.size.name;
                                }
                            }

                            if (!vm.sizes.find(function (s) { return s.name == vm.creative.size || vm.creative.size && s.name == vm.creative.size.name; })) {
                                if (vm.creative.height && vm.creative.width) {
                                    vm.creative.bannerSize = 0;
                                    vm.creative.size = 'No Size';
                                    vm.nonStandardCreativeSize = true;
                                } else {
                                    vm.creative.height = '';
                                    vm.creative.width = '';
                                    vm.creative.size = '';
                                }
                            }
                            else {
                                var size = vm.sizes.find(function (s) { return s.name == vm.creative.size || vm.creative.size && s.name == vm.creative.size.name; });
                                vm.creative.width = size.name.split('x')[0];
                                vm.creative.height = size.name.split('x')[1];
                            }

                            if (vm.creative.size != 'All Sizes' && !vm.creative.bannerSize) {
                                vm.creative.bannerSize = Enum.getId('BannerSizes', vm.creative.size);
                            }

                            vm.creative.height = parseInt(vm.creative.height);
                            vm.creative.width = parseInt(vm.creative.width);

                            if (isNaN(vm.creative.height) || isNaN(vm.creative.width)) {
                                vm.creative.bannerSize = null;
                                vm.creative.size = null;
                            }


                            vm.creative.thirdPartyAdTagUrl = $sce.trustAsResourceUrl(vm.creative.thirdPartyAdTag);
                        });
                    };

                    vm.channels = campaignService.getChannels();
                    if (vm.creative.id)
                        vm.filteredChannels = vm.channels;

                    if (!vm.isAdmin && !vm.selectedMarketer && vm.marketers && vm.marketers.length === 1){
                        vm.selectedMarketerId = vm.marketers[0].id;
                        vm.selectedMarketer = vm.marketers[0];
                        vm.selectedMarketerChanged();
                    }

                    vm.selectedChannelChanged = function (channelId) {
                        if (vm.creative.id)
                            return;
                        vm.creative.channelId = channelId; 
                        vm.selectedChannel = vm.channels.find(function (c) { return c.id === vm.creative.channelId; });
                        vm.creative.bannerURL = null;
                        vm.wrongDimension = false;
                        vm.wrongCanvasProductDimension = false;
                        if (vm.creative.channelId == 5)
                            vm.videoOptions = { height: 40 };
                    }

                    if (vm.creative.channelId)
                        vm.selectedChannel = vm.channels.find(function (c) { return c.id == vm.creative.channelId; });

                    vm.creativeTypeChanged = function () {
                        CleanCreativeResetValidation();
                    }

                    if (vm.creative.creativeTypeId)
                        vm.selectedCreativeType = vm.creativeTypes.find(function (ct) { return ct.id == vm.creative.creativeTypeId; });

                    vm.selectedCreativeTypeChanged = function () {
                        if (vm.selectedCreativeType)
                            vm.creative.creativeTypeId = vm.selectedCreativeType.id;

                        if (vm.creative.creativeTypeId == 3) {
                            vm.creative.canvasCollectionStep = 1;
                        }

                        CleanCreativeResetValidation();
                    }

                    function CleanCreativeResetValidation() {
                        vm.creative.bannerURL = null;
                        vm.creative.cardsInvalid = false;
                        vm.wrongDimension = false;
                        vm.wrongCanvasProductDimension = false;
                        if (vm.creative.creativeTypeId == 3)
                            vm.creative.creativeSlides = null;
                        else if (vm.creative.creativeTypeId == 2) {
                            vm.creative.creativeSlides = [{}, {}];
                            vm.selectedCreativeSlide = vm.creative.creativeSlides[0];
                        }
                        vm.creative.validation = false;
                        $('.clickURL').removeClass('has-error has-danger');
                        $('.invalidURL').hide();
                        $('#creativeForm').validator('destroy');
                    }

                    if (vm.creative && vm.creative.videoURL) {
                        $scope.videoFile = $sce.trustAsResourceUrl(vm.creative.videoURL);
                    }

                    vm.videoOptions = {
                    }

                    vm.player = null;
                    // Optional: Catch ng-jwplayer event for when JWPlayer is ready
                    $scope.$on('ng-jwplayer-ready', function (event, args) {

                        // Get player from service
                        vm.player = jwplayerService.myPlayer[args.playerId];
                        vm.processingSecure = false;
                        vm.processingNonSecure = false;

                        vm.player.once('error', function (event) {
                            HandlePlayerError(event.message);
                        });

                        vm.player.once('time', function () {
                            vm.creative.videoDuration = vm.player.getDuration();
                            vm.player.pause();
                        });

                        vm.player.play();
                    });

                    $scope.$on('ng-jwplayer-setup-error', function (event, args) {
                        HandlePlayerError();
                    });

                    function HandlePlayerError(message) {
                        if (vm.selectedVAST == 'nonsecure') {
                            vm.processingNonSecure = false;
                            vm.creative.nonSecureVASTValid = false;
                            vm.nonSecureVASTErrorReason = message ? message : 'Invalid video file';
                            vm.selectedSecureVASTURLChanged();
                        }

                        if (vm.selectedVAST == 'secure') {
                            vm.processingSecure = false;
                            vm.creative.secureVASTValid = false;
                            vm.secureVASTErrorReason = message ? message : 'Invalid video file';
                        }
                    }

                    vm.getChannelById = function (channelId) {
                        var name = '';
                        vm.channels.forEach(function (item, index) {
                            if (item.id == channelId)
                                name = item.name;
                        });
                        return name;
                    }

                    vm.selectedVAST = '';

                    vm.selectedSecureVASTURLChanged = function () {
                        if (!vm.creative.secureVASTDocumentURL) {
                            vm.creative.secureVASTValid = null;
                            if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid)
                                vm.creative.videoURL = null;
                        } else if (vm.creative.secureVASTDocumentURL && !vm.creative.secureVASTDocumentURL.startsWith("https://")) {
                            vm.creative.secureVASTValid = false;
                            vm.secureVASTErrorReason = "Secure VAST document URL should start with https";
                            if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid)
                                vm.creative.videoURL = null;
                        }
                        else if (vm.creative.secureVASTDocumentURL && vm.creative.secureVASTDocumentURL.startsWith("https://")) {
                            vm.processingSecure = true;
                            hub.webapicontext.feValidateVASTURL(vm.creative.secureVASTDocumentURL).then(function (data) {
                                if (data) {
                                    validateVASTXML(data);
                                    vm.allowInputVASTXML = false;
                                } else {
                                    vm.allowInputVASTXML = true;
                                }
                            }).catch(error => {
                                hub.webapicontext.validateVASTURL(vm.creative.secureVASTDocumentURL).then(function (data) {
                                    if (data) {
                                        validateVASTXML(data);
                                    } else {
                                        vm.allowInputVASTXML = true;
                                    }
                                });
                            });;
                        }
                    }

                    function validateVASTXML(data) {
                        hub.webapicontext.validateVASTXML(data.data).then(function (data) {
                            if (data.url || data.message == 'Valid VAST') {
                                vm.creative.secureVASTValid = true;
                                if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid) {
                                    vm.creative.videoURL = data.url;
                                    $scope.videoFile = $sce.trustAsResourceUrl(vm.creative.videoURL);
                                    $rootScope.$broadcast("videoUploaded", $scope.videoFile);
                                    vm.selectedVAST = 'secure';
                                    vm.processingSecure = false;
                                }
                                else {
                                    vm.processingSecure = false;
                                }
                            }
                            else {
                                vm.allowInputVASTXML = true;
                                if (vm.creative.channelId == 5) {
                                    vm.creative.videoURL = '';
                                    vm.selectedVAST = 'secure';
                                    vm.processingSecure = false;
                                } else {
                                    vm.secureVASTErrorReason = data.message;
                                    vm.processingSecure = false;
                                    vm.creative.secureVASTValid = false;
                                    if (!vm.creative.nonSecureVASTDocumentURL || !vm.creative.nonSecureVASTValid)
                                        vm.creative.videoURL = null;
                                }
                            }
                        });
                    }

                    vm.selectedNonSecureVASTURLChanged = function () {
                        if (!vm.creative.nonSecureVASTDocumentURL) {
                            vm.creative.nonSecureVASTValid = null;
                            if (vm.selectedVAST != 'secure') {
                                if (vm.creative.secureVASTDocumentURL) {
                                    vm.selectedSecureVASTURLChanged();
                                }
                                else {
                                    vm.creative.videoURL = null;
                                }
                            }
                        } else if (vm.creative.nonSecureVASTDocumentURL && !vm.creative.nonSecureVASTDocumentURL.startsWith("http://")) {
                            vm.creative.nonSecureVASTValid = false;
                            vm.nonSecureVASTErrorReason = "Unsecure VAST document URL should start with http";
                        } else if (vm.creative.nonSecureVASTDocumentURL && vm.creative.nonSecureVASTDocumentURL.startsWith("http://")) {
                            vm.processingNonSecure = true;
                            vm.player = null;

                            hub.webapicontext.validateVASTURL(vm.creative.nonSecureVASTDocumentURL).then(function (data) {
                                if (data) {
                                    validateVASTXML(data);
                                    vm.allowInputVASTXML = false;
                                } else {
                                    vm.allowInputVASTXML = true;
                                }
                            });
                        }
                    }

                    vm.vastXMLChanged = function () {
                        validateVASTXML({ data: vm.vastXML });
                    }

                    vm.thirdPartyServedChanged = function () {
                        if (vm.creative.thirdPartyServed && (vm.creative.channelId == 2 || vm.creative.channelId == 4 || vm.creative.channelId == 5)) {
                            vm.videoURL = vm.creative.videoURL;
                            vm.creative.videoURL = null;
                            if (vm.creative.secureVASTDocumentURL)
                                vm.selectedSecureVASTURLChanged();
                            if (vm.creative.nonSecureVASTDocumentURL)
                                vm.selectedNonSecureVASTURLChanged();

                        }
                        else if (vm.creative.channelId == 2 || vm.creative.channelId == 4 || vm.creative.channelId == 5) {
                            vm.creative.videoURL = vm.videoURL;
                            vm.videoURL = null;
                        }
                    }

                    vm.selectedCreativeChanged = function () {
                        if (!vm.creative) {
                            vm.creative = {
                                id: '0', name: '', destinationURL: '', createdDate: '', status: 'Active', creativeIconURL: '', buttonType: '0', selectedItems: [], thirdPartyCreativeType: '1'
                            };
                            vm.creative.secureVASTValid = null;
                            vm.creative.nonSecureVASTValid = null;
                        }

                        if (vm.creative.channelId == 1 && vm.creative.thirdPartyServed && !vm.creative.id ) {
                            vm.thirdPartyAdTagChanged();
                        } else {
                            if(vm.creative.width && vm.creative.id){
                                vm.creative.size = vm.creative.width + 'x' + vm.creative.height;
                            } 
                        }

                        if (vm.creative.channelId == 2 || vm.creative.channelId == 4 || vm.creative.channelId == 5) {
                            $scope.videoFile = $sce.trustAsResourceUrl(vm.creative.videoURL);

                            if (vm.creative.creativeIconURL && vm.creative.creativeIconURL !== ",,,,")
                                vm.videoOptions = {
                                    image: vm.creative.creativeIconURL
                                };

                            if (vm.creative.thirdPartyServed) {
                                vm.selectedNonSecureVASTURLChanged();
                                vm.selectedNonSecureVASTURLChanged();
                            }
                        }

                        $scope.creative = vm.creative;
                        setCreativeData();
                        $rootScope.$broadcast("selectedImagesChanged", vm.creative);
                    }

                    vm.validateCreativeSlides = function () {
                        if (vm.creative.validation) {
                            var cardsValidationSummary = validationService.validateCreativeSlides(vm.creative);
                            vm.creative.cardsInvalid = !cardsValidationSummary.valid;
                            vm.creative.cardsInvalidMessage = cardsValidationSummary.errorMessage;
                        }
                    }

                    if (vm.selectedMarketer) {
                        vm.creative.marketer = vm.selectedMarketer.name;
                        vm.creative.marketerId = vm.selectedMarketer.id;
                        vm.selectedMarketerId = vm.selectedMarketer.id;
                        campaignService.setSelectedMarketerId(vm.selectedMarketerId);

                        if (vm.creative.marketerId) {
                            vm.selectedMarketerId = vm.creative.marketerId;
                            campaignService.setSelectedMarketerId(vm.selectedMarketerId);
                        }
                    }

                    setCreativeData();
                    vm.selectedCreativeChanged();
                    vm.checkForDuplicateImpressionTrackers();

                    $scope.$on('selectedAdGroupChanged', function (event, data) {
                        var selectedAdGroup = campaignService.getSelectedAdGroup();
                        vm.creative = selectedAdGroup.creative;
                        if (vm.creative) {
                            vm.creative.secureVASTValid = null;
                            vm.creative.nonSecureVASTValid = null;
                        }
                        vm.selectedCreativeChanged();
                    });

                    $scope.$on('creativeSelected', function (event, data) {
                        vm.creative = null;
                        $timeout(function () {
                            vm.creative = data;
                            if (vm.creative) {
                                vm.creative.secureVASTValid = null;
                                vm.creative.nonSecureVASTValid = null;
                            }
                            vm.selectedCreativeChanged();
                        });
                    });

                    return vm;
                }]
            }
        }]);
})();
