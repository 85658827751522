(function () {
    'use strict';

    angular.module('app')
  .directive('reportSidebar', function () {
      return {
          template: require('@views/app/report-sidebar.html').default,
          restrict: 'E',
          scope: { model: '=' },
          controller: ['$scope', 'hub', 'customReportService', 'campaignService', '$filter', 'accountService', 'authService', function ($scope, hub, customReportService, campaignService, $filter, accountService, authService) {
              var vm = this;

              vm.display = {
                  levels: { hidden: false },
                  refinements: { hidden: false },
                  sales: { hidden: false },
                  dimensions: { hidden: false },
                  campaignsFilter: { hidden: true },
                  columns: { hidden: false },
                  campaigns: {
                      hidden: true, items: [], title: 'Campaigns', section: 'campaigns', idColumn: 'campaignId'
                  },
                  adGroups: { hidden: true, items: [], title: 'Ad Groups', section: 'adGroups', idColumn: 'adGroupId' },
                  advertisers: { hidden: true, items: [], title: 'Advertiser', section: 'advertisers', idColumn: 'id' },
                  marketers: { hidden: true, items: [], title: 'Marketers', section: 'marketers', idColumn: 'id' },
                  channels: { hidden: true, items: [], title: 'Channels', section: 'channels', idColumn: 'id' },
                  mediaTypes: { hidden: true, items: [], title: 'Media Types', section: 'mediaTypes', idColumn: 'id' },
                  demandTypes: { hidden: true, items: [], title: 'Demand Types', section: 'demandTypes', idColumn: 'id' },
                  reportBuilder: { hidden: false }
              };

              if (accountService.get() && accountService.get().accountType == 2)
                  vm.display.marketers.removed = true;

              vm.sections = [{ name: 'Refinements', key: 'r', hidden: false },
              { name: 'Financial', key: 'fn', hidden: false },
              { name: 'Engagement', key: 'm', hidden: false },
              { name: 'Delivery', key: 'd', hidden: false },
              { name: 'Total Sales', key: 't', hidden: false },
              { name: 'Observed Sales', key: 'o', hidden: false },
              { name: 'Adjusted Sales', key: 'a', hidden: false },
              { name: 'Incremental Sales', key: 'i', hidden: false },
              { name: 'Full Basket Sales', key: 'f', hidden: false },
              { name: 'Metrics', key: 'mt', hidden: false }
            ];
              vm.showAll = function () {

                  vm.reportConfig.report.filter.campaigns.showAll = !vm.reportConfig.report.filter.campaigns.showAll;
                  vm.display.campaigns.hidden = vm.reportConfig.report.filter.campaigns.showAll;
                  vm.display.advertisers.hidden = vm.reportConfig.report.filter.advertisers.showAll;
                  vm.display.channels.hidden = vm.reportConfig.report.filter.channels.showAll;
                  vm.display.mediaTypes.hidden = vm.reportConfig.report.filter.mediaTypes.showAll;
                  vm.display.demandTypes.hidden = vm.reportConfig.report.filter.demandTypes.showAll;
                  vm.display.marketers.hidden = vm.reportConfig.report.filter.marketers.showAll;

                  if (vm.reportConfig.report.filter.campaigns.showAll) {
                      vm.reportConfig.report.filter.campaigns.ids = [];
                      vm.display.campaigns.items.forEach(function (it) {
                          it.checked = false;
                      });
                  }
              };

              vm.display.channels.items = campaignService.getChannels();
              vm.display.mediaTypes.items = campaignService.getMediaTypes();
              vm.display.demandTypes.items = campaignService.getDemandTypes();



              var programsList = [];
              var adgroupsList = [];
              var campaignsList = [];
              const hasAccessToCampaigns = authService.hasAccess('Campaigns', 'List');

              if (hasAccessToCampaigns) {
                campaignService.getCampaignsList()
                  .then(function (result) {
                      vm.display.campaigns.items = result;
                      campaignsList = _.clone(result);

                      var ids = vm.reportConfig.report.filter.campaigns.ids || [];

                      ids.forEach(function (id) {

                          var camp = vm.display.campaigns.items.find(function (x) { return x.campaignId === id; });
                          if (camp)
                              camp.checked = true;
                      });

                      var ags = [];

                      var advertisers = [];

                      result.forEach(function (c) {
                          var advertiser = _.find(advertisers, function (item) {
                              return item.id == c.advertiserId;
                          });
                          if (!advertiser)
                              advertisers.push({ id: c.advertiserId, name: c.advertiserName });
                          _.each(c.adGroups,
                              function (a) {
                                  ags.push(a);
                              });
                      });
                      adgroupsList = ags;
                      vm.display.adGroups.items = _.clone(adgroupsList);

                      vm.display.advertisers.items = advertisers;

                  });

                  campaignService.getAccounts(2).then(function (ret) {
                    var marketers = ret.filter(function (r) { return vm.display.campaigns.items.find(function (c) { return c.marketerId == r.id; }); });
                    vm.display.marketers.items = $filter('orderBy')(marketers, 'name');
                });
              }

              vm.reportConfig = customReportService;

              vm.filterCampaigns = function () {

                  if (vm.reportConfig.report.filter.advertisers.ids.length === 0) {

                      vm.display.campaigns.items = campaignsList;
                      vm.filterAdGroups();
                      return;
                  }
                  var advertisers = vm.reportConfig.report.filter.advertisers.ids;
                  var campaigns = _.clone(campaignsList);
                  var filteredCampaigns = [];
                  advertisers.forEach(function (b) {
                      var res = campaigns.filter(function (a) { return a.advertiserId === b; });
                      if (res && res.length > 0)
                          filteredCampaigns = _.concat(filteredCampaigns, res);
                  });

                  vm.display.campaigns.items = filteredCampaigns;
                  vm.filterAdGroups();

              };

              //filter adgroups based on selected campaigns
              vm.filterAdGroups = function () {

                  var camps = vm.reportConfig.report.filter.campaigns.ids;

                  if (vm.reportConfig.report.filter.campaigns.ids.length === 0) {

                      if (vm.reportConfig.report.filter.advertisers.ids.length != 0)
                          camps = _.map(vm.display.campaigns.items, function (item) { return item.campaignId; });
                      else {
                          vm.display.adGroups.items = adgroupsList;
                          return;
                      }

                  }

                  var ags = _.clone(adgroupsList);
                  var filteredAgs = [];
                  camps.forEach(function (c) {
                      var res = ags.filter(function (a) { return a.campaignId === c; });
                      if (res && res.length > 0)
                          filteredAgs = _.concat(filteredAgs, res);
                  });

                  vm.display.adGroups.items = filteredAgs;

              };
              vm.setListState = function (mode) {
                  console.log('Set list state');

                  vm.reportConfig.report.filter[mode].ids.forEach(function (id) {
                      var found = vm.display[mode].items.find(function (it) {
                          return it[vm.display[mode].idColumn] == id;
                      });
                      if (found)
                          found.checked = true;
                  });
              };

              vm.setMode = function ($event, mode) {
                  $event.stopPropagation();
                  if ($event.target.attributes['toggleState'] != null) {

                      vm.display.advertisers.hidden = true;
                      vm.display.campaigns.hidden = true;
                      vm.display.adGroups.hidden = true;
                      vm.display.channels.hidden = true;
                      vm.display.marketers.hidden = true;
                      vm.display.mediaTypes.hidden = true;
                      vm.display.demandTypes.hidden = true;

                      //create a copy of original items
                      vm.display[mode].copy = _.clone(vm.reportConfig.report.filter[mode].ids);
                      vm.setListState(mode);
                      vm.display[mode].hidden = false;
                  }
              };

              $scope.$watch('vm.keyword', function(newValue){
                vm.reportConfig.keyword = newValue;
              });

              return vm;
          }],
          controllerAs: 'vm'

      };
  });



})();
