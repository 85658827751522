(function () {
  'use strict';

  angular.module('app')
    .controller('accountManagementController', ['$scope', '$rootScope', '$timeout', '$uibModal', '$filter', 'hub', 'authService', '$location', 'Enum', 'accountService', '$routeParams', 'context', 'appCache', 'campaignService', 'dialogService', '$route', 'roleService', 'timezoneService', 'localStorageService',
      function ($scope, $rootScope, $timeout, $modal, $filter, hub, authService, $location, Enum, accountService, $routeParams, context, appCache, campaignService, dialogService, $route, roleService, timezoneService, localStorageService) {

        var vm = this;
        vm.page = hub.page;
        vm.enum = Enum;
        vm.ADVERTISER = 1;
        vm.RETAIL = 2;
        vm.accountsError = false;
        vm.paged_accounts = [];

        vm.currentEastTimeAbbreviation = timezoneService.getInfo().currentEastTimeAbbreviation;

        vm.currentUserName = authService.getCurrentUserName();
        vm.userAccounts = authService.getUserAccounts();
        vm.isAdvertiserAccountManager = authService.hasAccess('accounts', 'ManageAdvertisers');
        vm.isAdvertiserAccountViewer = authService.hasAccess('accounts', 'ListAdvertisers');

        vm.isPublisherOptionEnabled = accountService.hasOption(10120, true) || authService.isSuperAdmin();
        vm.isPublisherAccountManager = vm.isPublisherOptionEnabled && authService.hasAccess('accounts', 'ManagePublishers');
        vm.isPublisherAccountViewer = vm.isPublisherOptionEnabled && authService.hasAccess('accounts', 'ListPublishers');

        vm.isMarketerAccountManager = authService.userAccountType() === 'Admin' && authService.hasAccess('accounts', 'ManageMarketers');
        vm.isMarketerAccountViewer = authService.hasAccess('accounts', 'ListMarketers');

        vm.isManager = function (a) {
          if (authService.isSuperAdmin())
            return true;

          switch (a.accountType) {
            case 1:
            case 3:
              return vm.isAdvertiserAccountManager;
            case 2:
            case 4:
              return vm.isMarketerAccountManager;
            case 5:
              return vm.isPublisherAccountManager;
            default:
              return false;
          }
        };
        vm.isObserver = function (a) {
          return !vm.isManager(a);
        };
        vm.isObserverRole = authService.isObserverRole();
        vm.isUserManager = authService.hasAccess('users', 'List');
        vm.hasAccess = authService.hasAccess;
        vm.isUsersPage = $location.$$path.indexOf('admin/users') > -1;
        vm.isAdmin = authService.userAccountType() === 'Admin';

        vm.config = hub.config;
        if ($location.path().indexOf('/admin/users') > -1) {
          hub.page.setTitle('Users');
        } else {
          hub.page.setTitle('Accounts');
        }
        vm.sort = {
          accounts: { column: 'name', sortDesc: false, keyword: '', accountType: '' },
          users: { column: 'userName', sortDesc: false, keyword: '' }
        };
        //if account is specified remove filter
        vm.currentAccountType = accountService.get() ? accountService.get().accountType : null;
        vm.accountTypes = [];
        var types = angular.copy(Enum.AccountTypes);
        types.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        if (types) {
          types.forEach(function (t) {
            switch (t.id) {
              case 1:
              case 3:
                vm.isAdvertiserAccountViewer ? vm.accountTypes.push(t) : null;
                break;
              case 2:
              case 4:
                vm.isMarketerAccountViewer ? vm.accountTypes.push(t) : null;
                break;
              case 5:
                vm.isPublisherAccountViewer ? vm.accountTypes.push(t) : null;
                break;
            }
          });
        }

        var storedAccountType = 1;
        if (!vm.accountTypes.find(function (at) {
          return at.id == storedAccountType;
        }) && vm.accountTypes[0])
          storedAccountType = vm.accountTypes[0].id;

        var selectedView = context.storage.getCached('selectedView', 'account', storedAccountType);
        if (selectedView && vm.accountTypes.find(function (at) {
          return at.id == selectedView;
        }))
          storedAccountType = parseInt(selectedView);

        var random = (Math.random() * 1000000).toFixed();
        var cacheReset = function (update) {
          if (update) {
            random = (Math.random() * 1000000).toFixed();
            return '#x=' + random;
          }
          return '';
        };

        vm.selectedView = storedAccountType ?? vm.currentAccountType;
        vm.selectedView = vm.selectedView.toString();
        vm.urlProcessed = false;

        vm.userAccountTypes = angular.copy(types);
        vm.userAccountTypes.push({ id: 6, name: 'Lasso Internal' });
        vm.selectedAccountType = '';

        vm.accountType = vm.accountTypes.filter(function (item) {
          return item.id == vm.selectedView;
        })[0];
        vm.accountId = $routeParams.accountId;

        if (!vm.accountType)
          vm.accountType = vm.accountTypes[0];

        vm.accountId = $routeParams.accountId;
        if ($location.$$path.indexOf('/admin/account-management') > -1 && vm.accountType)
          $location.path('admin/accounts/' + vm.accountType.page, false);

        vm.users = [];
        vm.selectedTab = 0;
        vm.selectedAccount = null;

        //get user info
        vm.user = authService.authentication;
        vm.paged_users = [];
        vm.paged_accounts = [];
        vm.all_users = [];
        vm.currentPage_accounts = 1;

        vm.filters = { users: {} };


        vm.filterViewModel = {
          advertisers: JSON.parse(vm.userAccounts.viewAdvertiserIds),
          marketers: JSON.parse(vm.userAccounts.viewMarketerIds)
        };

        vm.checkAll = function (list, filterKeyword, initially) {
          if (list)
            vm.filterViewModel[filterKeyword] = vm.filterViewModel[filterKeyword].length < list.length ? _.map(list, 'id') : initially ? _.map(list, 'id') : [];
        };

        vm.filterChecked = function (item, list, filterList) {
          var checked = filterList.indexOf(item.id) > -1;
          if (!checked)
            filterList.push(item.id);
          else
            filterList.splice($.inArray(item.id, filterList), 1);

          if (item.accountType === 2) {
            if (!checked)
              vm.advertisers.forEach(function (a) {
                if (a.marketerId === item.id && vm.filterViewModel.advertisers.indexOf(a.id) === -1)
                  vm.filterViewModel.advertisers.push(a.id);
              });
            else
              vm.advertisers.forEach(function (a) {
                if (a.marketerId === item.id && vm.filterViewModel.advertisers.indexOf(a.id) > -1)
                  vm.filterViewModel.advertisers.splice($.inArray(a.id, vm.filterViewModel.advertisers), 1);
              });
          } else if (item.accountType === 1) {
            if (!checked && vm.filterViewModel.marketers.indexOf(item.marketerId) === -1)
              vm.filterViewModel.marketers.push(item.marketerId);
          }

        };

        vm.saveAccounts = function () {
          authService.setUserAccounts(JSON.stringify(vm.filterViewModel.marketers), JSON.stringify(vm.filterViewModel.advertisers)).then(function () {
            hub.getLog('User Management', 'info')('Accounts for "' + vm.user.userName + '" has been changed.');
          });
        }

        vm.CanCreate = function () {
          if ((authService.isSuperAdmin()))
            return true;

          if (vm.isAdvertiserAccountManager && vm.selectedView == 1)
            return true;
          if (authService.isSuperAdmin() && vm.selectedView == 5)
            return true;
          if ((vm.selectedView == 2 || vm.selectedView == 4) && vm.isMarketerAccountManager)
            return true;

          return false;
        }


        vm.applyCustomFilter = function (list, name, filter) {
          vm.filters[list][name] = filter;
          vm.search(list);
        }

        vm.userStatusFilter = '';
        vm.search = function (list) {

          if (list === 'accounts') {
            vm.accounts = $filter('filter')(vm.all_accounts, { accountType: vm.accountType.id });
            vm.total = vm.accounts.length;
          }
          if (list === 'users')
            vm.users = vm.all_users;


          if (vm.filters[list]) {
            for (var name in vm.filters[list]) {
              var filter = vm.filters[list][name];
              if (filter) {
                vm[list] = $filter('filter')(vm[list], filter);
              }
            }
          }

          if (list === 'users')
            vm[list] = vm[list].filter(function (entity) {
              return entity.firstName.toLowerCase().indexOf(vm.sort[list].keyword.toLowerCase()) > -1 || entity.lastName.toLowerCase().indexOf(vm.sort[list].keyword.toLowerCase()) > -1 || entity.userName.toLowerCase().indexOf(vm.sort[list].keyword.toLowerCase()) > -1;
            });
          else
            vm[list] = vm[list].filter(function (entity) {
              return entity.name.toLowerCase().indexOf(vm.sort[list].keyword.toLowerCase()) > -1;
            });

          if (list == 'users' && vm.selectedUserAccountType) {
            if (vm.selectedUserAccountType == 6)
              vm[list] = vm[list].filter(function (u) {
                return !u.account || !u.account.accountType;
              });
            else
              vm[list] = vm[list].filter(function (u) {
                return u.account && (u.account.accountType == vm.selectedUserAccountType);
              });
          }


          vm[list] = $filter('orderBy')(vm[list], vm.sort[list].column, vm.sort[list].sortDesc);

          vm.pageSelect(1, list);
        }

        vm.sortFn = function (column, list) {
          if (vm.sort[list].column == column)
            vm.sort[list].sortDesc = !vm.sort[list].sortDesc;
          else {
            vm.sort[list].column = column;
            vm.sort[list].sortDesc = false;
          }

          vm.search(list);

        }

        vm.pageSize = vm.config.pageSize().toString();

        vm.pageSelect = function (page, listName) {
          var end, start;
          start = (page - 1) * parseInt(vm.pageSize);
          end = start + parseInt(vm.pageSize);
          if (vm[listName]) {
            vm['paged_' + listName] = vm[listName].slice(start, end);
            vm['currentPage_' + listName] = page;
          }
        };

        vm.pageSizeChanged = function (size) {
          vm.pageSize = size;
          vm.pageNumber = 1;
        };

        $scope.$watch('vm.sort.accounts.keyword', function () {
          if (vm.accounts) {
            vm.total = $filter('filter')(vm.accounts, vm.sort.accounts.keyword).length;
            vm.pageNumber = 1;
          }
        });

        vm.selectAccount = function (a) {

          if (a) {
            vm.selectedAccount = a;
            vm.accountId = a.id;
          } else if (vm.accountId) {
            var accounts = vm.accounts.filter(function (acc) {
              return acc.id == vm.accountId;
            });
            a = accounts && accounts.length > 0 ? accounts[0] : null;
            vm.selectedAccount = a;
          } else {
            vm.selectAccount = null;
            vm.accountId = null;


          }

          vm.selectedUser = null;
          vm.getData();

        }

        vm.selectedUser = null;
        vm.selectUser = function (u) {
          vm.selectedUser = u;
        }

        vm.removeUser = function (user) {
          var modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Remove',
            message: 'Are you sure you want to remove user ' + user.firstName + ' ' + user.lastName + '?'
          };

          dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
            vm.toggleUserState(user, { delete: true })
          });
        }

        vm.toggleUserState = function (user, options) {
          if (!vm.enabled)
            return;
          vm.enabled = false;
          var opt = { userID: user.id, isLocked: !user.isLocked };
          if (options && options.delete)
            opt.deleted = true;

          authService.changeUserInfo(opt).then(function () {


            vm.enabled = true;
            if (options && options.delete) {
              vm.getData();
              hub.getLog('User Management', 'info')(user.userName + ' has been removed');
            } else {
              hub.getLog('User Management', 'info')(user.userName + (!user.isLocked ? ' disabled' : ' enabled'));
              user.isLocked = !user.isLocked;
            }

          }, function () {
            vm.enabled = true;
          });
        }

        vm.toggleAccountState = function (account, status) {
          if (!vm.enabled)
            return;
          vm.enabled = false;
          var statusText = '';
          switch (status) {
            case 0:
              statusText = 'restarted';
              break;
            case 1:
              statusText = 'removed';
              break;
            case 2:
              statusText = 'disabled';
              break;
            case 3:
              statusText = 'paused';
              break;
          }
          hub.webapicontext.setAccountStatus({ id: account.id, status: status }).then(function (result) {

            hub.getLog('Account Settings', 'info')(account.name + ' ' + statusText);
            if (result.status == 1)
              vm.getAccounts(true);

            account.status = result.status;
            vm.search('accounts');
            vm.enabled = true;

            campaignService.getAccounts(2, true);

          }, function () {
            vm.enabled = true;
          });
        }

        vm.navigateTo = function (type, id = null) {
          if (id)
            $location.path(`admin/accounts/${type}/${id}/edit`, false);
          else
            $location.path(`admin/accounts/${type}`, false);
        }

        vm.selectView = function () {
          vm.accountType = vm.accountTypes.filter(function (item) {
            return item.id == vm.selectedView;
          })[0];
          hub.storagecontext.saveProfile('selectedView', { account: vm.selectedView }).then(function () {
            vm.navigateTo(vm.accountType.page);
            vm.search('accounts');
          });
        }

        //update avatar image
        $scope.$on('image-uploaded', function (event, args) {
          if (args.type === 'account') {
            var account = _.find(vm.accounts, { id: args.entity.id });
            if (account)
              account.avatarUrl = args.entity.avatarUrl;
          } else if (args.type === 'user') {
            var user = _.find(vm.users, { id: args.entity.id });
            if (user) {
              user.hasAvatar = true;
              user.avatarUrl = args.entity.avatarUrl;
            }
          }
        });

        //update avatar image
        $scope.$on('user-added', function () {
          vm.getData();
        });

        vm.getAccountType = function (key) {
          if (typeof key !== 'undefined' && key !== '') {
            var accType = vm.accountTypes.filter(function (acc) {
              return acc.id === key;
            })[0];
            return accType ? accType.name : undefined;
          } else
            return undefined;
        };
        vm.accounts = null;
        vm.selectedTab = 0;


        vm.showUsers = function (id) {
          $location.path("/admin/users/" + id);
        };

        vm.editAccount = function (id, mode) {

          if (!mode)
            mode = 'edit';

          vm.loadAndOpen(id, mode);

        };

        vm.loadAndOpen = function (id, mode) {
          if (id) {
            hub.webapicontext.getAccount(id).then(function () {
              vm.navigateTo(vm.accountType.page, id);
            });
          } else {
            vm.openAccountModal({
              id: 0,
              exchangeSettings: {},
              accountType: parseInt(vm.selectedView),
              linkedAccount: { id: null, name: null },
              salesRepUser: { id: null, name: null },
              manager: { id: null, name: null },
              marketers: [],
              options: []
            }, mode);
          }
        };

        vm.processUrl = function () {
          if (!vm.urlProcessed) {
            var accountId = $routeParams.accountId;
            var userId = $routeParams.userId;
            if (accountId) {
              hub.webapicontext.getAccount(accountId).then(function (d) {
                vm.openAccountModal(d, (vm.isObserver('accounts', d) ? 'readonly' : 'edit'));
              });
            } else if (userId) {
              var user = vm.all_users.find(function (u) {
                return u.id == userId;
              });
              if (user)
                vm.openModal(user);
            } else if ($location.$$path.indexOf('/admin/' + vm.accountType.page + '/new') > -1) {
              vm.openAccountModal({
                id: 0,
                accountType: parseInt(vm.selectedView),
                linkedAccount: { id: null, name: null },
                salesRepUser: { id: null, name: null },
                manager: { id: null, name: null },
                marketers: [],
                options: []
              }, 'edit');
            } else if ($location.$$path.indexOf('/admin/users/new') > -1) {
              vm.editUser();
            } else {
              $rootScope.$broadcast('modal.force.close', null);
            }
            vm.urlProcessed = true;
          }
        }

        vm.openAccountModal = function (account, mode) {
          if (account.accountType == 2) {
            if (!account.marketerContract) {
              account.marketerContract = { id: 0, platformFeeType: 1, marketerId: account.id };
            }
          }
          if (!vm.accountManagers) {
            callAccountModalInstance(account, mode)
          } else
            callAccountModalInstance(account, mode)
        }

        function callAccountModalInstance(account, mode) {
          var modalInstance = $modal.open({
            templateUrl: 'views/admin/account.html',
            animation: true,
            controller: 'accountManagerController',
            windowClass: 'modal-dialog-dark-theme',
            backdrop: 'static',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
              account: function () {
                return account;
              },
              accountManagers: function () {
                return vm.accountManagers;
              },
              accounts: function () {
                return angular.copy(vm.all_accounts);
              },
              viewMode: function () {
                return mode;
              },
            }
          });

          modalInstance.result.then(function () {
            vm.getAccounts(true);
            vm.navigateTo(vm.accountType.page);
          }, function (account) {
            if (account)
              vm.all_accounts.forEach(function (a) {
                if (a.id === account.id)
                  a.status = account.status;

              });
            vm.search('accounts');
            $location.path('admin/accounts/' + vm.accountType.page, false);
          });
        }

        vm.all_accounts = [];

        vm.getAccounts = function (reload) {
          vm.accountsError = false;
          campaignService.getAccounts(0, reload).then(function (d) {
            _.each(d, function (item) {
              if (item.avatar)
                item.avatarUrl = item.avatar;
              else
                item.avatarUrl = 'images/no_image.svg';
            });
            vm.all_accounts = d;
            vm.marketers = vm.all_accounts.filter(x => x.accountType === 2);
            vm.advertisers = vm.all_accounts.filter(x => x.accountType === 1);

            vm.search('accounts');
            vm.processUrl();

          }, function (e) {

            vm.accountsError = true;
          });
        }
        vm.getAccounts();

        vm.displayRoles = function (roles) {
          return roles.join(', ');
        }
        vm.shouldOpenModal = function () {
          return document.querySelector('.modal-container') === null;
        }
        vm.editUser = function (user) {
          if (!vm.shouldOpenModal()) {
            return;
          }
          if (!user) {
            vm.openModal({ id: 0 });
            $location.path('admin/users/new', false);
          } else {
            vm.openModal(user);
            $location.path('admin/users/' + user.id + '/edit', false);
          }
        };

        vm.enabled = true;

        vm.saveEmail = function () {

          if (!vm.enabled)
            return;
          vm.enabled = false;

          authService.changeUserInfo({ userName: vm.user.userName }).then(function (result) {
            hub.getLog('User Management', 'info')('Email / account name "' + vm.user.userName + '" has been changed.');
            vm.enabled = true;


          }, function (err) {
            vm.enabled = true;
          });

        }

        vm.migrateAdGroups = function () {
          hub.webapicontext.migrateAdGroups()
        }

        vm.impersonate = async function (user) {
          if (!vm.enabled)
            return;
          vm.enabled = false;
          const result = await authService.impersonate(user.userName)

          if (result?.error?.details) {
            hub.getLog('User Management', 'error')(result.error.details.message);
          }

          vm.enabled = true;
        }

        vm.clearSession = function () {
          if (sessionStorage) {
            sessionStorage.removeItem("campaigns_filter");
            sessionStorage.removeItem("sort_campaigns");
            sessionStorage.removeItem("sort_adGroups");
            sessionStorage.removeItem("sort_segments");
            sessionStorage.removeItem("creatives_filter");
            sessionStorage.removeItem("adgroups_filter");
            sessionStorage.removeItem("campaign_adgroup_active_tab");
            appCache.remove('accounts');
            appCache.remove('advertisers');
          }
        }

        vm.canSubmit = function () {
          return vm.selectedAccount != null;
        };

        vm.saveAccount = function () {
          hub.webapicontext.saveAccount(vm.selectedAccount).then(function (d) {
            hub.getLog('Account Management')('Account "' + vm.selectedAccount.name + '"  saved!');
          });
        };

        vm.hover = function (obj, value) {
          obj.showActions = value;
        };

        vm.openModal = function (user) {
          vm.selectUser(user);
          var modalInstance = $modal.open({
            templateUrl: 'views/admin/user.html',
            animation: true,
            controller: 'userManagerController',
            windowClass: 'modal-dialog-dark-theme',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              user: function () {
                return user;
              },
              account: function () {
                var acc = accountService.get() && accountService.get().id == user.accountId ? accountService.get() : user.account;
                return vm.selectedAccount == null ? acc : vm.selectedAccount;
              },
              accounts: function () {
                return angular.copy(vm.all_accounts);
              }
            }
          });

          modalInstance.result.then(function () {
            $location.path('admin/users', false);


          }, function (result) {
            $location.path('admin/users', false);
            if (result === "cancel")
              return;
            if (!result.id)
              vm.getData();
            else {
              for (var i = 0; i < vm.paged_users.length; i++) {
                if (vm.paged_users[i].id == result.id)
                  vm.paged_users[i] = result;
              }
            }

          });
        };

        vm.userTableMessage = null;

        vm.getData = function () {
          vm.users = null;
          if (vm.accountId) {
            hub.webapicontext.getUsersByAccountId(vm.accountId).then(function (d) {
              vm.all_users = d.users;
              vm.users = d.users;

              vm.set12DmAccount();

              vm.userTableMessage = (d.users == null || d.users.length == 0) ? 'No users found' : null;
              vm.search('users');
            });
          } else if (vm.isUserManager) {
            hub.webapicontext.getUsers()
              .then(function (u) {
                vm.all_users = u;
                vm.users = u;
                vm.set12DmAccount();

                vm.accountName = 'No Account';
                vm.userTableMessage = (u == null || u.length == 0) ? 'No users found' : null;
                vm.search('users');
              });
          }

        };

        vm.set12DmAccount = function () {
          vm.all_users.forEach(function (item, idx) {
            item.avatarUrl = item.avatar + cacheReset();
            if (item.account == null)
              item.account = { id: 0, name: 'Lasso' };
          });
        }

        vm.getData();

        $scope.$on('accountChanged', function () {
          campaignService.getAccounts(2, true);
          vm.getAccounts(true);
        });

        var original = $location.path;
        $location.path = function (path, reload) {
          if (reload === false) {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess', function () {
              if (IsCurrentPage($route.current.$$route.originalPath))
                $route.current = lastRoute;

              un();
            });
          }
          return original.apply($location, [path]);
        };

        function IsCurrentPage(path) {
          if (vm.isUsersPage) {
            if (path.indexOf('admin/users') > -1)
              return true;
          } else {
            if (path.indexOf('admin/accounts/advertisers') > -1
              || path.indexOf('admin/accounts/marketers') > -1
              || path.indexOf('admin/accounts/advertisergroups') > -1
              || path.indexOf('admin/accounts/publishers') > -1
              || path.indexOf('admin/accounts') > -1
              || path.indexOf('admin/holding-companies') > -1
              || path.indexOf('admin/account-management') > -1)
              return true;
          }
          return false;
        }

        return vm;
      }]);

})();
