import { mapReportColumn } from '../utils/reportColumnNameMapper'

/**
 * Created by naomiokubo on 3/22/16.
 */
(function () {
    'use strict';

    const decodeASCII = function (_this) {
      if (!_this) return _this;

      return _this.replace(/&#(\d+);/g, function (m, n) {
        return String.fromCharCode(n);
      });
    }

    angular.module('app')
        .controller('customReportController', ['$scope', '$rootScope', '$routeParams', '$location', 'userSettingsService', 'hub', 'statcontext', 'config', 'customReportService', 'campaignService', '$uibModal', '$filter', '$document', '$timeout', 'authService', 'dialogService', '$window', 'localStorageService', 'mtaDateRangeService', '$q', 'signalR',  function ($scope, $rootScope, $routeParams, $location, userSettingsService, hub, statcontext, config, customReportService, campaignService, $modal, $filter, $document, $timeout, authService, dialogService, $window, localStorageService, mtaDateRangeService, $q, signalR) {
            var log = hub.getLog('customReportController');
            var vm = this;
            var salesMtaDateRanged = function(){};
            vm.log = log;
            vm.filterHidden = true;
            vm.page = hub.page;
            vm.config = config;
            vm.customRangeChanged = mtaDateRangeService.customRangeChanged;
            vm.dateRangeChanged = mtaDateRangeService.dateRangeChanged;
           vm.reportTypeName ='';


            vm.hasAccess = authService.hasAccess;
            vm.dateRanges = customReportService.ranges;
            if (vm.dateRanges)
                vm.dateRanges = vm.dateRanges.filter(x => x.key !== 'AllTime');
            //Current date (calendar max-date)
            vm.now = new Date();
            vm.userSettingsService = userSettingsService;
            vm.isFullBasket = userSettingsService.getFullBasket() === true;

            vm.service = customReportService;
            vm.warn = hub.getLog('customReportController', 'warn');
            hub.page.setSidebarMode('CampaignsOnly');
            vm.model = {};
            vm.reportData = {};
            vm.request = { reportType: '1', pageNumber: 1, sortColumn: 'Date', sortDescending: false, pageSize: config.pageSize(), getCount: true };



            vm.filter = '';

            vm.rowHeaders = [];
            vm.rowColumns = [];
            vm.info = {};
            vm.startDate = null;
            vm.endDate = null;
            const scheduledReportsRequestIds = new Set()
            const NA_METRICS = ['AVEF', 'UNQM', 'UNIQHCP', 'AVFHCP', 'VB'];
            vm.formatValue = function (value, format, key) {
                if(key && NA_METRICS.indexOf(key.toUpperCase()) > -1 && (value == 'N\\A' || value === -1)) {
                    return "N\\A";
                }
                switch (format) {
                    case '%':
                    case '0':
                        return value === "" ? "" : vm.page.formatValue(value, format);
                    case 'date2':
                        return key == 'agrcsd' ? vm.page.fixDate(value, true) : vm.page.formatValue(value, "estdate");
                    default:
                        return vm.page.formatValue(value, format);
                }
            };

            vm.formatColumn = function (value, key) {
              return mapReportColumn(value,  key);
            }

            vm.saveDefaultDateFilter = function ($event) {
                $event.stopPropagation();
                if (vm.filter === 'ExactRange')
                    return;
                else
                    vm.saveDateFilter($event);
            };

            vm.getQuery = function () {
                if (!vm.hasAccess('report', 'query'))
                    return;

                    var modalOptions = {
                        templateUrl: 'query.html',
                        animation: true,
                        controller: 'queryController',
                        controllerAs: 'vm',
                        backdrop:'static',
                        size: 'ls',
                        resolve: {
                            queries: function () {
                                return {
                                    totals: vm.totals.debugQueries.results,
                                    results: vm.reportData.debugQueries.results
                                };
                            }
                        }
                    };
                    dialogService.showModal({ windowClass: 'modal-dialog-dark-theme' }, modalOptions);
            };

            vm.saveDateFilter = function ($event) {
                if (vm.filter !== 'ExactRange') {
                    vm.startDate = null;
                    vm.endDate = null;
                } else {
                    if (typeof vm.startDate.getMonth === 'function')
                        vm.startDate = new Date(vm.startDate.toDateString());
                    if (typeof vm.endDate.getMonth === 'function')
                        vm.endDate = new Date(vm.endDate.toDateString());
                    vm.customRangeChanged({start: vm.startDate, end: vm.endDate }, vm.isMTA);
                }

                vm.service.report.dateFilter.startDate = vm.startDate;
                vm.service.report.dateFilter.endDate = vm.endDate;
                vm.service.report.dateFilter.filter = vm.filter;
                vm.service.setAllowedDimensions($scope.filter);

                vm.getDateRangeText();
                vm.getDateRangeText();
                vm.service.isDirty = true;
                vm.service.validate();
                vm.dateFilterDisplayed = false;
                $event.stopPropagation();

            };
            vm.dateFilterDisplayed = false;

            const getDefaultShowRange = () => {
                const isSitePerformanceReport = vm.service.reportType == vm.service.reportTypesEnum.sitePerformance;
                if(isSitePerformanceReport) {
                    return vm.getDefaultExactRange();
                } else {
                    return {
                        start: vm.service.report.dateFilter.startDate || new Date(),
                        end: vm.service.report.dateFilter.endDate || new Date()
                    }
                }
            }

            vm.showDateFilter = function () {
                if (vm.dateFilterDisplayed) {
                    const { start, end } = getDefaultShowRange();
                    vm.startDate = start;
                    vm.endDate = end;
                    vm.filter = vm.service.report.dateFilter.filter;
                }
            };

            vm.cancelDateFilter = function ($event) {

                vm.dateFilterDisplayed = false;
                $event.stopPropagation();
            };
            vm.canSaveDateFilter = function () {
                return (vm.filter === 'ExactRange') ? (vm.startDate && vm.endDate) : true;
            };

            $scope.$on('levels.drop-model', function (e, el, target, source) {
            });
            $scope.$on('columns.drop-model', function (e, el, target, source) {
            });


            vm.sort = {
                report: { column: 'date', sortDesc: false, keyword: '' }
            };

            vm.save = function (info) {
                if (!vm.service.canSave())
                    return;

                vm.__saveReport(info ? info : { id: vm.info.id, name: vm.service.info.name, type: vm.service.info.type });
            };

            vm.saveAs = function () {
                vm.saveReportPopup({ id: 0, name: '', reportType: vm.service.info.type });
            };

            vm.__saveReport = function () {

                vm.service.getReportByName(vm.service.info.name).then(function (d) {
                    if(d.reportType != 0 && d.reportType != vm.service.info.type) {
                        hub.getLog('Another report', 'error')('Another report with the name "' + vm.service.info.name + '" exists, please rename this report to save it.');
                    } else {
                        if (d && d.id && vm.service.info.id != d.id && !vm.service.info.confirmOverwrite || d.id && vm.service.info.id && vm.service.isDirty) {
                            vm.idToSaveAs = d.id;
                            vm.saveReportPopup(vm.service.info);
                        }
                        else {
                            if (vm.idToSaveAs)
                                vm.service.info.id = vm.idToSaveAs;
                            else
                                vm.service.info.id = vm.service.info.name === vm.info.name ? vm.service.info.id : 0;

                            var data = { id: vm.service.info.id, name: vm.service.info.name, reportType: vm.service.info.type, parameters: vm.service.report };
                            authService.trackEvent({ action: 'SaveReport', view: $location.path(), data: JSON.stringify({ id: data.id, name: data.name, reportType: data.reportType }) });
                            hub.api.post('api/reports/save', data)
                                .then(function (reportData) {
                                    vm.service.info.id = reportData.id;
                                    vm.info.id = reportData.id;
                                    log(data.name + ': report saved');
                                    var reportPath = customReportService.getReportUrl(data.reportType);
                                    var load = vm.reportData != {} && !vm.service.isDirty ? '1': '';
                                    $location.path(reportPath + reportData.id , true).search({load: load });
                                    vm.service.isDirty = false;
                                    vm.savedRequest = JSON.stringify(getRequest());
                                    $timeout(function () {
                                        $window.location.reload();
                                    });
                                }, function (err) {

                                    if (!err.data.logged) {
                                        hub.getLog('Custom Report', 'error')('Report Save Error. Duplicate name');
                                    }
                                });
                        }
                    }

                });
            };

            vm.getTotalValue = function (c) {
                if (['RET', 'CHAN', 'PROG', 'ADVERTISER', 'COM', 'AVEF', 'UNIQHCP', 'UNQM', 'AVFHCP',
                    "B_AVEF", "B_UNIQHCP", "B_UNQM", "B_AVFHCP", "B_HCPCOST", "B_UNIQUES", "HCPV" ]
                    .indexOf(c.key) >= 0)
                    return '';
                if (vm.totals.header[c.key.toLowerCase()] == null)
                    return vm.page.formatValue(0, c.format);

                return vm.page.formatValue(vm.totals.header[c.key.toLowerCase()], c.format);
            };

            vm.saveReportPopup = function (info) {

                var modalOptions = {
                    templateUrl: "newReport.html",
                    animation: false,
                    controller: ['$scope', '$uibModalInstance', 'model', function ($scope, $modalInstance, model) {

                        $scope.model = model;
                        $scope.ok = function () {
                            $modalInstance.close(model);
                        };

                        $scope.cancel = function () {
                            $modalInstance.dismiss('cancel');
                        };
                        $scope.canSubmit = function () {
                            return $scope.model.name;
                        };

                    }],
                    size: 'md',
                    resolve: {
                        model: function () {
                            return { name: info ? info.name : vm.service.info.name };
                        }
                    }
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (model) {
                    if (vm.service.info.name !== model.name) {
                        vm.info.id = 0;
                        vm.service.info.id = 0;
                        vm.service.info.name = model.name;
                        vm.service.info.confirmOverwrite = true;
                        vm.idToSaveAs = 0;
                    } else {
                        vm.service.info.confirmOverwrite = true;
                        vm.service.info.id = vm.info.id;
                    }
                    vm.service.isDirty = false;
                    //save report
                    vm.__saveReport(info ? info : { id: vm.info.id, name: vm.info.name, reportType: vm.service.info.type, confirmOverwrite: true });
                }, function () {




                });
            };

            vm.dateRangeText = '';
            vm.getDateRangeText = function () {

                var key = vm.service.report.dateFilter.filter;
                if (key === 'ExactRange') {
                    vm.dateRangeText = ($filter('date')(vm.service.report.dateFilter.startDate) +
                        (vm.service.report.dateFilter.endDate ? '-' + $filter('date')(vm.service.report.dateFilter.endDate) : ''));
                } else {


                    var range = config.dateRanges.find(function (d) { return d.key === key });
                    if (range)
                        vm.dateRangeText = range.day;
                    else
                        vm.dateRangeText = '[UNKNOWN]';
                }
            };

            vm.runReport = function (reload, clearData) {
                //set report type
                vm.request.reportType = vm.service.info.type;
                vm.service.validate();

                if (!vm.service.isValid)
                    return;

                if (reload) {
                    vm.request.pageNumber = 1;

                }

                vm.service.clean_filter();
                vm.request.parameters = _.clone(vm.service.report);

                //do not apply filters is refinement columns can not be added
                if (!vm.service.canAddColumn('r')) {
                    vm.request.parameters.filter.programs = { showAll: true, ids: [] };
                    vm.request.parameters.filter.channels = { showAll: true, ids: [] };
                }

                vm.request.id = vm.service.info.id;
                //copy sort info
                vm.request.sortColumn = vm.service.report.sortOrder.sortColumn;
                vm.request.sortDescending = vm.service.report.sortOrder.sortDescending;
                var ts = new Date();
                if (clearData) {
                    vm.reportData = {};
                    vm.getTotals();
                }
                statcontext.customReport(vm.request).then(function (data) {

                    if (data.isFullBasket !== vm.isFullBasket) {
                        hub.getLog('customReportController', 'error')('Full Basket Mode has changed. Page Refresh is required');

                        $timeout(function () {
                            $window.location.reload();
                        }, 5000);
                    }

                    authService.trackEvent({
                        action: 'ExecuteReport', view: $location.path(),
                        data: JSON.stringify(
                            {
                                id: vm.service.id, name: vm.service.name, reportType: vm.request.reportType,
                                headers: vm.service.report.columnOrder.headers.map(function (it) { return it.key; }),
                                columns: vm.service.report.columnOrder.columns.map(function (it) { return it.key; }),
                                filters: vm.request.parameters.filter,
                                dateFilter: vm.service.report.dateFilter
                            }
                        )
                    }, { timeElapsed: ((((new Date()).getTime() - ts.getTime()) / 1000) / 60).toFixed(2) });

                    if (data && data.results && data.results.length > 0) {

                        var dimensions = ['m', 'q', 'y'];

                        data.results.forEach(function (it) {
                            if (it.prod)
                                it.prod = decodeASCII(it.prod);
                            if (it.cat)
                                it.cat = decodeASCII(it.cat);
                            if (it.advertiser)
                                it.advertiser = decodeASCII(it.advertiser);
                            if (it.d) {
                                it.d = vm.page.fixDate(it.d, true);
                            }

                            dimensions.forEach(function (d) {
                                if (it[d]) {
                                    it[d] = it[d] + (it.dim == 'P' ? ' (partial)' : '');
                                }

                            });
                        });
                    }
                    vm.reportData = data;
                    vm.service.isDirty = false;

                    if (data && data.errorMessage) {
                        hub.getLog('customReportController', 'error')(data.errorMessage);
                    }

                }, function (err) {

                });
            };
            vm.getTotals = function () {
                vm.totals = null;
                statcontext.customReportSummary(vm.request).then(function (data) {
                    if (data) {
                        vm.totals = data;
                    }
                }, function (err) {
                });
            };

            var hasRequestChanges = function() {
                if(vm.savedRequest) {
                    return JSON.stringify(getRequest()) != vm.savedRequest;
                }
                return false;
            }

            var getRequest = function() {
                if (!vm.service.isValid)
                    return null;
                var req = _.cloneDeep(vm.request);
                req.sortColumn = vm.service.report.sortOrder.sortColumn;
                req.sortDescending = vm.service.report.sortOrder.sortDescending;
                req.pageNumber = 1;
                req.reportType = vm.service.info.type;
                req.parameters = vm.service.report;
                //define export columns
                req.columns = {};
                if (req.parameters && req.parameters.columnOrder) {
                    req.parameters.columnOrder.columns.forEach(function(c) {
                        delete c['$$hashKey'];
                    });
                    req.parameters.columnOrder.headers.forEach(function(c) {
                        delete c['$$hashKey'];
                    });
                }
                vm.service.report.levels.forEach(function (c) {
                    req.columns[c.key] = c.name;
                });
                vm.service.report.dimensions.forEach(function (c) {
                    req.columns[c.key] = c.name;
                });
                vm.service.report.columns.forEach(function (c) {
                    req.columns[c.key] = c.name;
                });
                return req;
            }

            var getExportScheduleRequest = function(isNoName, byEmail = true) {
                var req = getRequest();
                if(req) {
                    var dirty = hasRequestChanges();
                    req.id = dirty ? 0 : vm.service.info.id;
                    req.name = vm.service.info.id ? vm.service.info.name: null;
                    req.originalId = vm.service.info.id || 0;
                    req.byEmail = !signalR.isConnected() || byEmail
                }
                var deferred = $q.defer();
                var hasName = req.name;
                if(hasName || isNoName) {
                    deferred.resolve(req);
                    return deferred.promise;
                }
                var modalOptions = {
                    templateUrl: "reportName.html",
                    animation: false,
                    controller: ['$scope', '$uibModalInstance', 'model', function ($scope, $modalInstance, model) {

                        $scope.model = model;
                        $scope.ok = function () {
                            $modalInstance.close(model);
                        };

                        $scope.cancel = function () {
                            $modalInstance.dismiss('cancel');
                        };
                        $scope.canSubmit = function () {
                            return $scope.model.name;
                        };

                    }],
                    size: 'md',
                    resolve: {
                        model: function () {
                            return { name: '' };
                        }
                    }
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (model) {
                    req.name = model.name;
                    deferred.resolve(req);
                }, function () {
                    deferred.reject(req);
                });
                return deferred.promise;
            }

            vm.scheduleExport = async function (isNoName = false, byEmail = true) {
                try {
                    const req = await getExportScheduleRequest(isNoName, byEmail)
                    const { requestId } = await hub.api.post('api/reports/scheduleExport', req);
                    scheduledReportsRequestIds.add(requestId)

                    if (req.byEmail) {
                      vm.log('Your report export has been scheduled. You will have an email with download link as soon as report is generated.');
                    } else {
                      vm.log('Your report export has been scheduled. The download will start automatically. Please, wait...');
                    }
                } catch {}
            };

            var triggerDownload = function (url, count) {
                if (count) {
                    if (url) {
                        var link = document.createElement("a");
                        if (link.download !== undefined) { // feature detection
                            // Browsers that support HTML5 download attribute
                            link.target = '_blank';
                            link.setAttribute("href", url);
                            link.setAttribute("download", (vm.service.info.type == 2 ? 'sales_detail' : 'custom') + '_' + $filter('date')(new Date(), 'MM.dd.yyyy_hh.mm') + '.csv');
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    } else {
                        vm.warn('Report exceeded maximum rows count and will be delivered by email. Please, wait...');
                    }
                } else {
                    vm.log('No records to export');
                }
            };

            vm.exportReport = function () {
                var isNoName = !vm.service.info.id;
                if(vm.service.info.type == 7 || vm.service.info.type == 8){
                    vm.scheduleExport(isNoName, false);
                } else {
                    getExportScheduleRequest(isNoName).then(function(req){
                        statcontext.exportCustomReport(req).then(function (data) {

                            if (data.isFullBasket != vm.isFullBasket) {
                                hub.getLog('customReportController', 'error')('Full Basket Mode has changed. Page Refresh is required');

                                $timeout(function () {
                                    $window.location.reload();
                                }, 5000);
                            }
                            triggerDownload(data.filePath, data.count);

                        }, function (error) {
                            hub.getLog('customReportController', 'error')('Unable to export report', error);

                        });
                    });
                }
            };

            vm.getDateHeader = function () {
                return vm.service.report.dimension ? vm.service.report.dimension.name : '???';

            };

            vm.getSelectedFilterNames = function (type, key, nameField, collectionName) {

                var ids = vm.service.report.filter[type] ? (vm.service.report.filter[type].ids || []) : [];
                if (ids.length === 0)
                    return "(all)";
                var names = '';
                var list = null;
                if (type === 'channels')
                    list = campaignService.getChannels();
                if (type === 'mediaTypes') {
                    list = campaignService.getMediaTypes();
                }
                if (type === 'demandTypes') {
                  list = campaignService.getDemandTypes();
                }
                if (type === 'programs')
                    list = campaignService.getPrograms();

                ids.forEach(function (id) {

                    var targetList = list != null ? list : campaignService[(collectionName ? collectionName : type)];

                    var camp = targetList.find(function (x) { return x[key] === id; });

                    if (camp)
                        names += (nameField ? camp[nameField] : camp.name) + '; ';

                });
                return names;

            };

            vm.sortFn = function (column, list, level) {

                //do not sort table if it has segments
                if (level) {
                    var seg = vm.service.report.levels.find(function (item) { return item.key === "SEG"; });
                    if (seg && column !== 'SEG') {
                        return;
                    }
                }

                if (vm.sort[list].column === column) {
                    vm.sort[list].sortDesc = !vm.sort[list].sortDesc;
                }
                else {
                    vm.sort[list].column = column;
                    vm.sort[list].sortDesc = column === 'H'; // by hour default descending other ascending
                }

                vm.request.parameters.sortOrder.sortColumn = vm.sort[list].column;
                vm.request.parameters.sortOrder.sortDescending = vm.sort[list].sortDesc;

                vm.runReport(true);
            };

            vm.resetReport = function () {
                vm.service.isLoading = true;
                $timeout(function () {
                    var reportId = $routeParams.id;
                    if (reportId)
                        load();
                    else {
                        vm.service.resetReport();
                        vm.getDateRangeText();
                    }
                }, 500);
                vm.reportData = {};
                vm.service.isLoading = false;
            };

            vm.reportData = {};
            function changeMtaNotificator() {
                if (vm.service.info.type == 2) {
                    vm.customRangeChanged = vm.dateRangeChanged = salesMtaDateRanged;
                } else if (vm.service.info.type == 1) {
                    vm.customRangeChanged = mtaDateRangeService.customRangeChanged;
                    vm.dateRangeChanged = mtaDateRangeService.dateRangeChanged;
                }
            }
            function load() {
                var reportId = $routeParams.id;
                var info = localStorageService.get('userInfo');
                vm.isMTA = info.multiTouchMode;


                if (reportId) {
                    vm.service.load(reportId).then(function (d) {
                        vm.getDateRangeText();
                        vm.sort.report = vm.service.report.sortOrder;
                        vm.info = { id: vm.service.info.id, name: vm.service.info.name, reportType: vm.service.info.type };
                        vm.service.validate();
                        vm.service.isDirty = false;
                        var runReport = $routeParams.load;
                        changeMtaNotificator();
                        var key = vm.service.report.dateFilter.filter;
                        vm.isMTA = d.isMTA;
                        if(key) {
                            if(key == 'ExactRange') {
                                var start = vm.service.report.dateFilter.startDate;
                                var end = vm.service.report.dateFilter.endDate;
                                vm.customRangeChanged({start: moment(start).toDate(), end: moment(end).toDate() }, vm.isMTA);
                            } else {
                                vm.dateRangeChanged(key, vm.isMTA);
                            }
                        }
                        vm.savedRequest = JSON.stringify(getRequest());

                        if(runReport) {
                            vm.runReport(true, true);
                        }


                    }, function (error) {
                        hub.getLog('customReportController', 'error')('Unable to get custom report');
                            $location.path("/reporting");
                    });

                }
                else
                {
                    //reset report settings
                        vm.service.info.name = '';
                        vm.service.info.id = 0;
                        vm.resetReport();
                        vm.info = { id: vm.service.info.id, reportType: vm.service.info.type };
                        changeMtaNotificator();
                }
            }

            vm.toggleFilter = function($event, mode) {
                var filterModeChanged = mode != vm.service.filter.mode;
                vm.service.event = $event;
                vm.service.filter.mode = null;
                if (filterModeChanged) {
                    $timeout(function(){
                        vm.service.filter.mode = mode;
                    });
                }

            }

            load();
            $scope.$watch('vm.filter', function(key){
                if(key && key != 'ExactRange') {
                    changeMtaNotificator();
                    vm.dateRangeChanged(key, vm.isMTA);
                    vm.service.syncRange(key)
                }
            });
            $scope.$on('columns.drop', function(){
                setTimeout(() => {
                    vm.service.makeFirstSort();
                },500)
            });
            $scope.$watch('vm.service.filter', function(newValue) {
                if(newValue) {
                    let filters = Object.values(newValue).filter(function(item){
                        return item && item.title && item.on;
                    });
                    let filter_order = ["marketers", "advertisers", "publishers", "campaigns", "adGroups", "channels", "mediaTypes", "demandTypes"];
                    vm.filterSections = [];
                    for(const next_filter of filter_order){
                      filters.forEach(item => {
                        if(item.section == next_filter){
                          vm.filterSections.push(item);
                        }
                      });
                    }
                }
            });

            vm.pageSizeChanged = function(size) {
                vm.request.pageSize = size;
                vm.request.pageNumber = 1;
                vm.runReport();
            }
            vm.getDefaultExactRange = function() {
                let end, start;
                if (vm.service.reportType == vm.service.reportTypesEnum.sitePerformance) {
                    end = moment().utc().add(-1, 'days').toDate();
                    start = moment(end).clone().add(-29, 'days').toDate();
                } else {
                    end = new Date();
                    start = null;
                }
                return {start , end};
            }

            $scope.$watch('vm.service.reportType', function(newValue){
                vm.service.filter = null;

                if(newValue){
                    const { start, end } = vm.getDefaultExactRange();
                    const ranges = vm.service.getRanges();
                    vm.reportTypeName = vm.service.reportTypes.find(function(it){  return it.type === newValue.type; }).name;
                    if(vm.service.reportType == vm.service.reportTypesEnum.sitePerformance) {
                        vm.endDate = end;
                        vm.minDate = start;
                        vm.now = vm.endDate;
                    } else {
                        vm.now = new Date();
                        vm.endDate = end;
                        vm.minDate = start;
                    }
                    vm.dateRanges = vm.service.ranges;
                    if (vm.dateRanges)
                        vm.dateRanges = vm.dateRanges.filter(x => x.key !== 'AllTime');
                }
                vm.service.setFilter();
            });
            $rootScope.$on('ReportUpdate', function (name, notification) {
                const { url, rowsCount, requestId } = JSON.parse(notification.value);

                if (scheduledReportsRequestIds.has(requestId)) {
                  scheduledReportsRequestIds.delete(requestId)
                  triggerDownload(url, rowsCount);
                }
            });

            return vm;
        }]);

        angular.module('app')
        .controller('queryController', ['$scope', '$uibModalInstance', 'queries', function ($scope, $modalInstance, queries) {

            $scope.model = queries;
            $scope.ok = function () {
                $modalInstance.close(model);
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        }]);


})();
