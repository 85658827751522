import { navigateToAppUrl } from '../../utils/navigation';

(function () {

  'use strict';

  const containsSame = function (_this, array) {
    // if the other array is a falsy value, return
    if (!array) return false;

    // compare lengths - can save a lot of time
    if (_this.length != array.length) return false;

    for (var i = 0, l = _this.length; i < l; i++) {
      if (array.indexOf(_this[i]) == -1) return false;
    }
    return true;
  }

  angular.module('app').factory('authService', ['$http', '$q', '$window', '$location', '$timeout', '$injector', 'accountService', 'localStorageService', 'authSettings', 'logger', 'featureService', 'columnsService', 'userSettingsService', '$browser',
    function ($http, $q, $window, $location, $timeout, $injector, accountService, localStorageService, authSettings, logger, featureService, columnsService, userSettingsService, $browser) {

      var serviceBase = authSettings.apiServiceBaseUri;

      var authServiceFactory = {};

      var _authentication = {
        isAuth: false,
        userName: "",
        useRefreshTokens: false
      };

      var _acl = {};

      var _externalAuthData = {
        provider: "",
        userName: "",
        externalAccessToken: ""
      };

      var _saveRegistration = function (registration) {

        _logOut();

        return $http.post(serviceBase + 'api/account/register', registration).then(function (response) {
          return response;
        });

      };

      var _changePassword = function (currentPassword, newPassword) {
        var deferred = $q.defer();

        $http.post(serviceBase + 'api/account/changePassword', {
          oldPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: newPassword
        }).then(function (response) {
          deferred.resolve(response.data);
        }, function (err) {
          deferred.reject(err);
        });
        return deferred.promise;
      };

      var _setPassword = function (userId, code, newPassword) {
        var deferred = $q.defer();

        $http.post(serviceBase + 'api/account/ResetPassword', {
          userId: userId,
          password: newPassword,
          confirmPassword: newPassword,
          code: code
        }).then(function (response) {
          deferred.resolve(response.data);
        }, function (err) {
          deferred.reject(err);
        });
        return deferred.promise;
      };

      var _setMtaMode = function (mode) {
        var deferred = $q.defer();

        $http.post(serviceBase + 'api/user/SetMTAMode/' + mode)
          .then(function (response) {
              if (_authentication.impersonate) {
                _impersonate(_authentication.userName);
              } else if (response.data.access_token) {
                var authInfo = localStorageService.get('authorizationData');
                authInfo.token = response.data.access_token;
                localStorageService.set('authorizationData', authInfo);
                var userInfo = localStorageService.get('userInfo');
                userInfo.multiTouchMode = mode;
                localStorageService.set('userInfo', userInfo);
                //userSettingsService.setMta(mode);
              }
              deferred.resolve(response.data);
            },
            function (err) {
              deferred.reject(err);
            });
        return deferred.promise;
      }

      var _changeUserInfo = function (update) {
        var deferred = $q.defer();

        $http.post(serviceBase + 'api/user/saveUserInfo', update).then(function (response) {

          if (response.data.access_token) {
            //update access token and username
            var authInfo = localStorageService.get('authorizationData');

            authInfo.token = response.data.access_token;
            authInfo.userName = update.userName;

            localStorageService.set('authorizationData', authInfo);
          }
          deferred.resolve(response.data);
        }, function (err) {
          deferred.reject(err);
        });
        return deferred.promise;
      };

      var _sendForgotPasswordEmail = function (email) {
        var deferred = $q.defer();

        $http.post(serviceBase + 'api/account/SendForgotPasswordLink', { email: email }).then(function (response) {
          deferred.resolve(response.data);
        }, function (err) {
          deferred.reject(err);
        });
        return deferred.promise;
      };

      async function _getUserInfo() {
        let userInfo = localStorageService.get('userInfo')

        if (!_authentication?.isAuth || !userInfo) {
          _clearAuth()
          return
        }

        _acl = userInfo.access;

        var storagecontext = $injector.get('storagecontext');
        storagecontext.saveSession(userInfo.profile);

        //claims set has changed
        if (_authentication.userClaims && !(_authentication.userClaims instanceof Array)) {
          _authentication.userClaims = [_authentication.userClaims];
        }

        //roles has changed
        if (!_authentication.userRoles || !containsSame(_authentication.userRoles, userInfo.roles)) {
          _authentication.userRoles = userInfo.roles;
        }
        //set allowed features
        _authentication.features = userInfo.features;
        _authentication.disabledFeatures = userInfo.disabledFeatures;
        _authentication.firstTimeUser = userInfo.firstTimeUser;
        _authentication.name = userInfo.firstName + ' ' + userInfo.lastName;
        _authentication.environemnt = userInfo.environemnt;
        columnsService.setUserColumns(userInfo.columns);
        columnsService.columnsLoadingPromise.resolve(userInfo.columns);

        _authentication.isFullBasket = userInfo.isFullBasket;
        localStorageService.set('isFullBasket', _authentication.isFullBasket);
        if (_authentication.userRoles.includes('Super Admin')) {
          localStorageService.set('adminMode', true);
        } else {
          localStorageService.remove('adminMode');
        }

        _authentication.accountFilterSettings = userInfo.accountFilterSettings;

        _authentication.avatar = userInfo.avatar;

        logger.init();

        //set MTA mode
        userSettingsService.setMta(userInfo.multiTouchMode);

        //remove features cache
        featureService.disabledFeatures = null;

        _fillAuthData();

        //First time user
        if (_authentication.firstTimeUser) {
          navigateToAppUrl('app/auth/change-password');
        }

        return userInfo
      }

      const clearUserDataAndRedirect = () => {
        localStorageService.remove('userInfo')
        localStorageService.remove('account')
        localStorageService.remove('authorizationData')
        navigateToAppUrl('app/auth')
      }

      const refreshUserData = async (shouldRedirect = true) => {
        const authInfo = localStorageService.get('authorizationData')

        if (!authInfo) {
          clearUserDataAndRedirect()
          return
        }

        try {
          const [{ data: userInfo }, { data: accountInfo }] = await Promise.all([
            $http.get(serviceBase + 'api/Account/UserInfo'),
            $http.get(serviceBase + 'api/CompanyAccount')
          ])
          localStorageService.set('userInfo', userInfo);
          localStorageService.set('account', accountInfo)

          if (shouldRedirect) {
            navigateToAppUrl('app/auth')
          }
        } catch {
          clearUserDataAndRedirect()
        }
      }

      var _trackEvent = function (parameters, metrics, eventName) {

      };

      var _impersonate = async function (userName) {
        return await window.__globalState?.impersonate?.value?.(userName)
      }

      var _getUserAccounts = function () {
        return _authentication.accountFilterSettings;
      }

      var _setUserAccounts = function (viewMarketerIds, viewAdvertiserIds, noFilter) {
        var deferred = $q.defer();

        $http.post(serviceBase + 'api/user/updateViewAccountsIds/', {
          viewMarketerIds: viewMarketerIds,
          viewAdvertiserIds: viewAdvertiserIds,
          noFilter: noFilter
        }).then(function (response) {
          if (response.data.access_token) {
            var authInfo = localStorageService.get('authorizationData');
            authInfo.token = response.data.access_token;
            localStorageService.set('authorizationData', authInfo);
          }
          deferred.resolve(response.data);
        }, function (err) {
          deferred.reject(err);
        });
        return deferred.promise;
      };

      var _setMarketer = function () {
        _authentication.isMarketer = false;
        if (_authentication.userClaims && _authentication.userClaims.indexOf("Retail") >= 0 && _authentication.userClaims.indexOf("Admin") === -1) {
          _authentication.isMarketer = true;
        }
      };

      const _getOAuthClientIdGoogle = () => {
        return $http.get(serviceBase + 'api/Account/GetOAuthClientId/Google')
      }

      const _getOAuthClientIdMicrosoft = () => {
        return $http.get(serviceBase + 'api/Account/GetOAuthClientId/Microsoft')
      }

      const _signInWithOAuthCode = (code, provider) => {
        const data = `code=${encodeURIComponent(code)}`;
        var deferred = $q.defer();
        console.warn(`${provider} + code: ${code}`);

        $http.post(`${serviceBase}api/Account/SignInWithOAuthCode/${provider}`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then(e => {
            deferred.resolve(e);
          })
          .catch(err => {
            console.warn(err);
            deferred.reject(err);
          });
        return deferred.promise;
      }

      var _clearAuth = function () {
        localStorageService.remove('authorizationData');
        localStorageService.clearAll();
        accountService.remove();
        navigateToAppUrl('app/auth')
      }

      var _logOut = function () {
        _clearAuth();
      };

      var _getCurrentUserName = function () {
        return _authentication.userName;
      };

      var _fillAuthData = function () {

        var authData = localStorageService.get('authorizationData');
        if (authData) {
          _authentication.isAuth = true;
          _authentication.userName = authData.userName;
          _authentication.userClaims = authData.userClaims;
          _authentication.features = authData.features;
          _authentication.isFullBasket = authData.isFullBasket;
          _authentication.disabledFeatures = authData.disabledFeatures;
          _authentication.userRoles = authData.userRoles;
          _authentication.useRefreshTokens = authData.useRefreshTokens;
          _authentication.impersonate = (!authData.impersonate) ? false : authData.impersonate;
          _authentication.isExternal = authData.isExternal;
          if (authData.originalUserName)
            _authentication.originalUserName = authData.originalUserName;

          _setMarketer();

          var userInfo = localStorageService.get('userInfo');
          if (userInfo) {
            _authentication.name = userInfo.firstName + ' ' + userInfo.lastName;
          }
          var $rootScope = $injector.get('$rootScope');
          var hub = $injector.get('hub');
          //set username and avatar
          if ($rootScope.rootVM) {

            $rootScope.rootVM.isFullBasket = authData.isFullBasket;
            $rootScope.rootVM.userName = (!authData.impersonate) ? _authentication.name : ' as ' + _authentication.name;
            $rootScope.rootVM.avatar = (_authentication.avatar) ? hub.settings.imagesUrl + 'avatars/' + _authentication.avatar : hub.settings.imagesUrl + 'avatars/user-icon-gray.svg';
          }
        }


      };

      var _userInfo = function () {
        return localStorageService.get('userInfo');
      };

      var _refreshToken = function () {
        var deferred = $q.defer();

        var authData = localStorageService.get('authorizationData');

        if (authData) {

          if (authData.useRefreshTokens) {

            var data = "grant_type=refresh_token&refresh_token=" + authData.refreshToken + "&client_id=" + authSettings.clientId;

            localStorageService.remove('authorizationData');

            $http.post(serviceBase + 'token', data, {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function (response) {

              localStorageService.set('authorizationData', {
                token: response.data.access_token,
                userName: response.data.userName,
                refreshToken: response.data.refresh_token,
                useRefreshTokens: true
              });

              deferred.resolve(response.data);

            }, function (err) {
              _logOut();
              deferred.reject(err);
            });
          }
        }

        return deferred.promise;
      };

      var _obtainAccessToken = function (externalData) {

        var deferred = $q.defer();

        $http.get(serviceBase + 'api/account/ObtainLocalAccessToken', {
          params: { provider: externalData.provider, externalAccessToken: externalData.externalAccessToken }
        }).then(function (response) {

          localStorageService.set('authorizationData', {
            token: response.data.access_token,
            userName: response.data.userName,
            refreshToken: "",
            useRefreshTokens: false
          });

          _authentication.isAuth = true;
          _authentication.userName = response.data.userName;
          _authentication.useRefreshTokens = false;

          deferred.resolve(response.data);

        }, function (err, status) {
          _logOut();
          deferred.reject(err);
        });

        return deferred.promise;

      };

      var _hasFeature = function (feature) {
        if (!feature)
          return true;
        var userData = localStorageService.get('userInfo');
        if (!userData)
          return false;

        return userData.features && userData.features.length && userData.features.indexOf(feature) >= 0;
      }

      var isObserverRole = function () {
        var userData = localStorageService.get('userInfo');
        var roles = userData.roles
        return roles && roles.length && roles.includes('Observer');
      }

      var _isSuperAdmin = function () {
        return _acl.accessAll;
      }

      var _hasAccess = function (resource, action, entity) {
        if (_acl.accessAll)
          return true;

        if (!_acl || !_acl.resources)
          return false;

        if (entity && entity.actions)
          if (entity.actions.indexOf(action) > -1)
            return true;
          else
            return false;

        if (_acl.resources[resource] && _acl.resources[resource].indexOf(action) > -1)
          return true;

        return false
      };

      var _isObserver = function (resource, entity) {
        if (entity && entity.actions)
          if (entity.actions.indexOf('View') > -1 && entity.actions.indexOf('Manage') < 0)
            return true;
          else
            return false;

        if (_acl.resources[resource] && _acl.resources[resource].indexOf('View') > -1 && _acl.resources[resource].indexOf('Manage') < 0)
          return true;

        return false;
      };

      var _registerExternal = function (registerExternalData) {

        var deferred = $q.defer();

        $http.post(serviceBase + 'api/account/registerexternal', registerExternalData).then(function (response) {

          localStorageService.set('authorizationData', {
            token: response.data.access_token,
            userName: response.data.userName,
            refreshToken: "",
            useRefreshTokens: false
          });

          _authentication.isAuth = true;
          _authentication.userName = response.data.userName;
          _authentication.useRefreshTokens = false;

          deferred.resolve(response.data);

        }, function (err) {
          _logOut();
          deferred.reject(err);
        });

        return deferred.promise;

      };

      var _isLoggedIn = function () {
        var userData = localStorageService.get('authorizationData');
        if (!userData)
          return false;
        return true;
      }

      var userAccountType = function () {
        return _acl.accountTypeValue;
      }

      var isMarketerUser = function () {
        return userAccountType() == "Marketer";
      }

      var isPublisherUser = function () {
        return userAccountType().indexOf('Publisher') >= 0;
      }

      var getResources = function () {
        return [
          // select concat('{ id: ',id,', name: ''',[Name],''' },') from resources
          { id: 1, name: 'Campaigns' },
          { id: 2, name: 'Audiences' },
          { id: 3, name: 'Creatives' },
          { id: 4, name: 'CreativeRequests' },
          { id: 5, name: 'Reports' },
          { id: 6, name: 'Users' },
          { id: 7, name: 'Accounts' },
          { id: 8, name: 'Settings' },
          { id: 9, name: 'InventoryLists' },
          { id: 10, name: 'Locations' },
          { id: 11, name: 'Analytics' },
          { id: 12, name: 'Deals' },
          { id: 13, name: 'Measurement' },
          { id: 14, name: 'Monetization' },
          { id: 15, name: 'Placements' },
          { id: 16, name: 'AdUnits' },
          { id: 17, name: 'WebEvents' },
          { id: 18, name: 'Features' },
          { id: 19, name: 'DirectSoldCampaigns' },
          { id: 20, name: 'MarketplaceAdGroups' },
          { id: 21, name: 'Plans' },
        ];
      }

      var getActions = function () {
        return [
          // select concat('{ id: ',id,', actionTypeId: ',actiontypeid,', name: ''',[Name],''', resourceId: ',resourceid,' },') from Actions
          { id: 110, actionTypeId: 1, name: 'List Campaigns', resourceId: 1 },
          { id: 120, actionTypeId: 2, name: 'View Campaigns', resourceId: 1 },
          { id: 130, actionTypeId: 3, name: 'Manage Campaigns', resourceId: 1 },
          { id: 140, actionTypeId: 4, name: 'Launch Campaigns', resourceId: 1 },
          { id: 176, actionTypeId: 76, name: 'List Publishers', resourceId: 1 },
          { id: 210, actionTypeId: 1, name: 'List Audiences', resourceId: 2 },
          { id: 220, actionTypeId: 2, name: 'View Audiences', resourceId: 2 },
          { id: 221, actionTypeId: 21, name: 'Download Match List of Audience', resourceId: 2 },
          { id: 230, actionTypeId: 3, name: 'Manage Audiences', resourceId: 2 },
          { id: 240, actionTypeId: 4, name: 'Publish Audiences', resourceId: 2 },
          { id: 250, actionTypeId: 5, name: 'Create Audiences', resourceId: 2 },
          { id: 260, actionTypeId: 6, name: 'Update Audiences', resourceId: 2 },
          { id: 270, actionTypeId: 7, name: 'Delete Audiences', resourceId: 2 },
          { id: 310, actionTypeId: 1, name: 'List Creatives', resourceId: 3 },
          { id: 320, actionTypeId: 2, name: 'View Creatives', resourceId: 3 },
          { id: 330, actionTypeId: 3, name: 'Manage Creatives', resourceId: 3 },
          { id: 331, actionTypeId: 31, name: 'CreativeAdmin CF Group', resourceId: 3 },
          { id: 332, actionTypeId: 32, name: 'CreativeTeam CF Group', resourceId: 3 },
          { id: 333, actionTypeId: 33, name: 'CreativeBrand CF Group', resourceId: 3 },
          { id: 334, actionTypeId: 34, name: 'Approve Creatives', resourceId: 3 },
          { id: 410, actionTypeId: 1, name: 'List Creative Requests', resourceId: 4 },
          { id: 420, actionTypeId: 2, name: 'View Creative Requests', resourceId: 4 },
          { id: 430, actionTypeId: 3, name: 'Manage Creative Requests', resourceId: 4 },
          { id: 510, actionTypeId: 1, name: 'List Reports', resourceId: 5 },
          { id: 520, actionTypeId: 2, name: 'View Reports', resourceId: 5 },
          { id: 530, actionTypeId: 3, name: 'Manage Reports', resourceId: 5 },
          { id: 610, actionTypeId: 1, name: 'List Users', resourceId: 6 },
          { id: 620, actionTypeId: 2, name: 'View Users', resourceId: 6 },
          { id: 630, actionTypeId: 3, name: 'Manage Users', resourceId: 6 },
          { id: 710, actionTypeId: 1, name: 'List Accounts', resourceId: 7 },
          { id: 720, actionTypeId: 2, name: 'View Accounts', resourceId: 7 },
          { id: 730, actionTypeId: 3, name: 'Manage Accounts', resourceId: 7 },
          { id: 771, actionTypeId: 71, name: 'Admin - Manage Brands', resourceId: 7 },
          { id: 772, actionTypeId: 72, name: 'Admin - Manage Retailers', resourceId: 7 },
          { id: 773, actionTypeId: 73, name: 'Admin - Manage Publishers', resourceId: 7 },
          { id: 774, actionTypeId: 74, name: 'Admin - List Advertisers', resourceId: 7 },
          { id: 775, actionTypeId: 75, name: 'Admin - List Marketers', resourceId: 7 },
          { id: 776, actionTypeId: 76, name: 'Admin - List Publishers', resourceId: 7 },
          { id: 810, actionTypeId: 1, name: 'List Settings', resourceId: 8 },
          { id: 820, actionTypeId: 2, name: 'View Settings', resourceId: 8 },
          { id: 830, actionTypeId: 3, name: 'Manage Settings', resourceId: 8 },
          { id: 883, actionTypeId: 83, name: 'Manage Announcements', resourceId: 8 },
          { id: 910, actionTypeId: 1, name: 'List Inventory Lists', resourceId: 9 },
          { id: 920, actionTypeId: 2, name: 'View Inventory Lists', resourceId: 9 },
          { id: 930, actionTypeId: 3, name: 'Manage Inventory Lists', resourceId: 9 },
          { id: 931, actionTypeId: 1, name: 'List Locations', resourceId: 10 },
          { id: 932, actionTypeId: 2, name: 'View Locations', resourceId: 10 },
          { id: 933, actionTypeId: 3, name: 'Manage Locations', resourceId: 10 },
          { id: 1001, actionTypeId: 2, name: 'View Analytics', resourceId: 11 },
          { id: 1002, actionTypeId: 1, name: 'List Analytics', resourceId: 11 },
          { id: 1003, actionTypeId: 1, name: 'List Deals', resourceId: 12 },
          { id: 1004, actionTypeId: 2, name: 'View Deals', resourceId: 12 },
          { id: 1005, actionTypeId: 3, name: 'Manage Deals', resourceId: 12 },
          { id: 1006, actionTypeId: 1, name: 'List Deals', resourceId: 12 },
          { id: 1007, actionTypeId: 2, name: 'View Deals', resourceId: 12 },
          { id: 1008, actionTypeId: 3, name: 'Manage Deals', resourceId: 12 },
          { id: 1009, actionTypeId: 1, name: 'List Placements', resourceId: 15 },
          { id: 1010, actionTypeId: 2, name: 'View Placements', resourceId: 15 },
          { id: 1011, actionTypeId: 3, name: 'Manage Placements', resourceId: 15 },
          { id: 1012, actionTypeId: 1, name: 'List AdUnits', resourceId: 16 },
          { id: 1013, actionTypeId: 2, name: 'View AdUnits', resourceId: 16 },
          { id: 1014, actionTypeId: 3, name: 'Manage AdUnits', resourceId: 16 },
          { id: 1015, actionTypeId: 7, name: 'Delete Placements', resourceId: 15 },
          { id: 1016, actionTypeId: 7, name: 'Delete AdUnits', resourceId: 16 },
          { id: 1100, actionTypeId: 2, name: 'View Measurement data', resourceId: 13 },
          { id: 1101, actionTypeId: 1, name: 'List Placements', resourceId: 15 },
          { id: 1102, actionTypeId: 2, name: 'View Placements', resourceId: 15 },
          { id: 1103, actionTypeId: 3, name: 'Manage Placements', resourceId: 15 },
          { id: 1104, actionTypeId: 1, name: 'List AdUnits', resourceId: 16 },
          { id: 1105, actionTypeId: 2, name: 'View AdUnits', resourceId: 16 },
          { id: 1106, actionTypeId: 3, name: 'Manage AdUnits', resourceId: 16 },
          { id: 1107, actionTypeId: 2, name: 'View monetization dashboard', resourceId: 14 },
          { id: 1108, actionTypeId: 2, name: 'View digital events', resourceId: 17 },
          { id: 1109, actionTypeId: 1, name: 'List digital events', resourceId: 17 },
          { id: 1110, actionTypeId: 1, name: 'List monetization dashboard', resourceId: 14 },
          { id: 1111, actionTypeId: 1, name: 'List Features', resourceId: 18 },
          { id: 1112, actionTypeId: 6, name: 'Edit Features', resourceId: 18 },
          { id: 1113, actionTypeId: 3, name: 'Manage Monetization', resourceId: 14 },
          { id: 1901, actionTypeId: 1, name: 'List Direct Sold Campaigns', resourceId: 19 },
          { id: 1902, actionTypeId: 2, name: 'View Direct Sold Campaign', resourceId: 19 },
          { id: 1903, actionTypeId: 3, name: 'Manage Direct Sold Campaigns', resourceId: 19 },
          { id: 1906, actionTypeId: 6, name: 'Edit Direct Sold Campaign', resourceId: 19 },
          { id: 1907, actionTypeId: 7, name: 'Delete Direct Sold Campaign', resourceId: 19 },
          { id: 2001, actionTypeId: 1, name: 'List Marketplace Ad Groups', resourceId: 20 },
          { id: 2002, actionTypeId: 2, name: 'View Marketplace Ad Groups', resourceId: 20 },
          { id: 2003, actionTypeId: 3, name: 'Manage Marketplace Ad Groups', resourceId: 20 },
          { id: 2101, actionTypeId: 1, name: 'List Monetization Plans', resourceId: 21 },
          { id: 2102, actionTypeId: 2, name: 'View Monetization Plans', resourceId: 21 },
          { id: 2103, actionTypeId: 3, name: 'Manage Monetization Plans', resourceId: 21 },
          { id: 2104, actionTypeId: 3, name: 'Manage Digital Events', resourceId: 17 },
        ];
      }

      const setReturnLink = () => {
        const returnLink = window.location.pathname.replace(
          new RegExp(`^${_.escapeRegExp($browser.baseHref())}`),
          ''
        )
        if (returnLink !== 'pages/403' && !returnLink.includes('app/auth')) {
          localStorage.setItem('authReturnLink', returnLink + window.location.search)
        }
      }


      authServiceFactory.isLoggedIn = _isLoggedIn;
      authServiceFactory.saveRegistration = _saveRegistration;
      authServiceFactory.getOAuthClientIdGoogle = _getOAuthClientIdGoogle;
      authServiceFactory.getOAuthClientIdMicrosoft = _getOAuthClientIdMicrosoft;
      authServiceFactory.signInWithOAuthCode = _signInWithOAuthCode;
      authServiceFactory.logOut = _logOut;
      authServiceFactory.fillAuthData = _fillAuthData;
      authServiceFactory.getCurrentUserName = _getCurrentUserName;
      authServiceFactory.authentication = _authentication;
      authServiceFactory.refreshToken = _refreshToken;
      authServiceFactory.hasAccess = _hasAccess;
      authServiceFactory.isObserver = _isObserver;
      authServiceFactory.getUserInfo = _getUserInfo;
      authServiceFactory.impersonate = _impersonate;
      authServiceFactory.obtainAccessToken = _obtainAccessToken;
      authServiceFactory.externalAuthData = _externalAuthData;
      authServiceFactory.registerExternal = _registerExternal;
      authServiceFactory.sendForgotPasswordEmail = _sendForgotPasswordEmail;
      authServiceFactory.changePassword = _changePassword;
      authServiceFactory.setPassword = _setPassword;
      authServiceFactory.changeUserInfo = _changeUserInfo;
      authServiceFactory.setMtaMode = _setMtaMode;
      authServiceFactory.isSuperAdmin = _isSuperAdmin;
      authServiceFactory.hasFeature = _hasFeature;
      authServiceFactory.userAccountType = userAccountType;
      authServiceFactory.getActions = getActions;
      authServiceFactory.getResources = getResources;
      authServiceFactory.trackEvent = _trackEvent;
      authServiceFactory.isMarketerUser = isMarketerUser;
      authServiceFactory.isPublisherUser = isPublisherUser;
      authServiceFactory.userInfoData = _userInfo;
      authServiceFactory.setUserAccounts = _setUserAccounts;
      authServiceFactory.getUserAccounts = _getUserAccounts;
      authServiceFactory.isObserverRole = isObserverRole;
      authServiceFactory.refreshUserData = refreshUserData
      authServiceFactory.setReturnLink = setReturnLink

      return authServiceFactory;
    }]);
})();
