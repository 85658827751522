(function () {
    'use strict';

    angular.module('app')
        .controller('creativeController', ['$scope', '$rootScope', '$uibModal', '$sce', 'hub', 'authService', '$compile', 'campaignService', 'creativeService', 'angularGridInstance', '$timeout', 'tableService', 'dialogService', '$location', '$routeParams', '$route', 'featureService', 'accountService', 'appSettings', 'timezoneService', function ($scope, $rootScope, $modal, $sce, hub, authService, $compile, campaignService, creativeService, angularGridInstance, $timeout, tableService, dialogService, $location, $routeParams, $route, featureService, accountService, appSettings, timezoneService) {
            var rootVM = $rootScope.rootVM;
            var log = hub.getLog('Creatives');
            var vm = this;
            vm.log = log;
            vm.rootVM = rootVM;
            vm.page = hub.page;
            vm.config = hub.config;
            vm.creativesNotFound = false;
            vm.pageNumber = 1;
            vm.sort = { creatives: { column: 'createdDate', sortDesc: true, keyword: '' } };
            vm.allImgsBlank = false;
            vm.getCreativeStatusById = creativeService.getCreativeStatusById;
            vm.getCreativeStatusIconById = creativeService.getCreativeStatusIconById;
            vm.isObserver = authService.isObserver;
            vm.hasAccess = authService.hasAccess;
            vm.isAdmin = authService.userAccountType() == "Admin";
            vm.isSuperAdmin = authService.isSuperAdmin();
            vm.displayAdvertiserName = authService.userAccountType() == "Admin" || authService.userAccountType() == "Marketer";
            vm.showId = true;
            vm.showAdvertiserName = featureService.isActive('ADVERTISERNAME');
            vm.account = accountService.get();
            vm.adBlocked = $rootScope.canRunAds === undefined;

            vm.sizes = campaignService.getSizes();
            vm.selectedSizeId = '0';
            vm.selectedStatusId = '-1';

            vm.channels = campaignService.getChannels();
            vm.selectedChannelId = '0';

            vm.ts = tableService;
            vm.ts.init('creatives');

            vm.viewMode = 1;
            vm.setViewMode = function (mode) {
                vm.viewMode = mode;
                if (mode == 1)
                    $scope.refresh();
            }

            vm.currentEastTimeAbbreviation = timezoneService.getInfo().currentEastTimeAbbreviation;

            $scope.clearSearch = function () {
                vm.sort.creatives.keyword = '';
                $scope.search();
            }

            $scope.refresh = function () {
                if (angularGridInstance && angularGridInstance.gallery)
                    angularGridInstance.gallery.refresh();
            }

            $scope.search = function () {
                vm.pageNumber = 1;
                vm.pageSelected();
            }

            if (sessionStorage["creatives_filter"]) {
                vm.filter = JSON.parse(sessionStorage["creatives_filter"]);
            }
            else {
                vm.filter = { channels: [], sizes: [], statuses: [], owners: [], marketers: [], creativeTypes: [], advertisers: [] };
            }

            vm.filterViewModel = angular.copy(vm.filter);

            vm.checkAll = function (list, filterKeyword) {
                vm.filterViewModel[filterKeyword] = vm.filterViewModel[filterKeyword].length < list.length ? _.map(list, 'id') : [];
            }

            vm.filterChecked = function (item, list, filterList) {
                var checked = filterList.indexOf(item.id) > -1;
                if (!checked)
                    filterList.push(item.id);
                else
                    filterList.splice($.inArray(item.id, filterList), 1);
            }

            vm.isItemChecked = function (item, filterList) {
                return filterList.indexOf(item.id) > -1;
            }

            vm.cancelFilter = function () {
                vm.filterViewModel = angular.copy(vm.filter);
                $scope.showFilter = false;
            }

            vm.filterCreatives = function () {
                vm.filter = angular.copy(vm.filterViewModel);
                $scope.showFilter = false;
                vm.getCreatives();
                sessionStorage["creatives_filter"] = JSON.stringify(vm.filter);
            }

            vm.filtersOn = function () {
                return vm.filter.channels.length != vm.channels.length
                    || vm.filter.sizes.length != vm.sizes.length
                    || vm.marketers && vm.filter.marketers.length != vm.marketers.length
                    || vm.filter.owners.length != vm.owners.length
                    || vm.filter.creativeTypes.length != vm.creativeTypes.length
                    || vm.advertisers && vm.filter.advertisers.length != vm.advertisers.length
                    || vm.filter.statuses.length != vm.statuses.length;
            }

            vm.clearFilters = function () {
                if (sessionStorage) {
                    sessionStorage.removeItem("creatives_filter");
                }
                $location.path('activation/creative/', true);
            }

            vm.shouldOpenBulkModal = function () {
                return document.querySelector('.modal-container.creative-bulk-upload') === null;
            }

            vm.bulkCreativeUpload = function () {
                if (!vm.shouldOpenBulkModal()) {
                    return;
                }

                $location.path("/activation/creative/bulk", false);

                var modalInstance = $modal.open({
                    templateUrl: 'views/creative/editors/bulk-creative-upload.html',
                    animation: false,
                    controller: 'bulkCreativeUploadController',
                    size: 'lg',
                    backdrop: 'static',
                    resolve: {
                        settings: {}
                    }
                });

                modalInstance.result.then((function (data) {
                    vm.log("Bulk Creatives saved.");
                    vm.getCreatives();
                }), (function () {
                    $location.path('activation/creative', false);
                    $rootScope.$broadcast('modal.force.close', null);
                }));

            };


            vm.bulkCreativeResend = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'views/creative/editors/bulk-creative-resend.html',
                    animation: false,
                    controller: 'bulkCreativeResendController',
                    size: 'lg',
                    backdrop: 'static',
                    resolve: {
                        settings: {}
                    }
                });

                modalInstance.result.then((function (data) {
                    vm.log("Bulk Creatives resent.");
                    vm.getCreatives();
                }), (function () {
                    $rootScope.$broadcast('modal.force.close', null);
                }));

            };
            vm.modalOpenedDate = null;
            vm.shouldOpenModal = function () {
                return document.querySelector('.modal-container.creative-modal') === null;
            }
            vm.newCreative = function () {
                if (!vm.shouldOpenModal()) {
                    return;
                }
                var modalInstance;
                $location.path("/activation/creative/new", false);

                var advertiserId = null;
                var marketerId = null;
                if (vm.account) {
                    if (vm.account.accountType == 1)
                        advertiserId = vm.account.id;
                    else if (vm.account.accountType == 2)
                        marketerId = vm.account.id;
                }
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/creative/editors/creative.html',
                        animation: false,
                        controller: 'editCreativeController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return {
                                    creative: {
                                        id: 0, name: '', adChoices: true, destinationURL: '', created: '', status: 'Active', creativeIconURL: '', buttonType: '0', selectedAdvertisers: [], selectedCategories: [], selectedSubCategories: [], selectedUPCs: [],
                                        thirdPartyCreativeType: '1', advertiserId: advertiserId, marketerId: marketerId, sensitiveCategoriesApply: false, availableActions: [], creativeSlides: [{}, {}], optimizeCardDisplay: true, showProfileCard: true
                                    }
                                };
                            },
                            viewMode: function () {
                                return "new";
                            }
                        }
                    });

                    modalInstance.result.then((function (data) {
                        var savedCreative = data.creative;
                        vm.log(savedCreative.name + " Created");
                        campaignService.getCreatives(null, null, 0, vm.sort.creatives.keyword, vm.filter, vm.sort.creatives.column, vm.sort.creatives.sortDesc, true, null).then(function (d) {
                            vm.creatives = d.creatives;
                            vm.totalCount = d.totalCount;
                            processCreatives();
                            $timeout(function () {
                                $location.path('activation/creative', false);
                                $rootScope.$broadcast('modal.force.close', null);
                                $scope.refresh();
                            });
                        });

                    }), (function () {
                        $location.path('activation/creative', false);
                        $rootScope.$broadcast('modal.force.close', null);
                    }));
                });
            }

            vm.newCreativeRequest = function () {
                $location.path("/activation/creative-request/new");
            }

            vm.page.setTitle('Creatives');

            if (vm.rootVM.addNewCreativeMode) {
                vm.newCreative();
            }

            vm.creatives = [];
            vm.sizes = campaignService.getSizes();
            vm.statuses = campaignService.getStatuses();
            vm.owners = campaignService.getOwners();
            vm.creativeTypes = campaignService.getCreativeTypes();

            if (!sessionStorage["creatives_filter"]) {
                vm.checkAll(vm.channels, 'channels');
                vm.checkAll(vm.sizes, 'sizes');
                vm.checkAll(vm.statuses, 'statuses');
                vm.checkAll(vm.owners, 'owners');
                vm.checkAll(vm.creativeTypes, 'creativeTypes');
                vm.filter = angular.copy(vm.filterViewModel);
            }

            vm.getCreatives = function (reload) {
                campaignService.getCreatives(null, null, vm.pageNumber - 1, vm.sort.creatives.keyword, vm.filter, vm.sort.creatives.column, vm.sort.creatives.sortDesc, true, null).then(function (d) {
                    campaignService.getAccounts(1).then(function (bd) {
                        vm.creatives = d.creatives;
                        vm.totalCount = d.totalCount;
                        if (!vm.marketers) {
                            vm.marketers = campaignService.marketers;
                            if (vm.marketers.length == 0) {
                                var advertiser = campaignService.advertisers[0];
                                if (advertiser)
                                    vm.marketers = advertiser.marketersOptions;
                            }
                            if (!sessionStorage["creatives_filter"])
                                vm.checkAll(vm.marketers, 'marketers');
                            vm.filter = angular.copy(vm.filterViewModel);
                        }
                        if (!vm.advertisers) {
                            vm.advertisers = campaignService.advertisers;
                            if (!sessionStorage["creatives_filter"])
                                vm.checkAll(vm.advertisers, 'advertisers');
                            vm.filter = angular.copy(vm.filterViewModel);
                        }
                        processCreatives();
                        $timeout(function () {
                            $scope.refresh();
                        });
                    });
                });
            }

            function processCreatives() {
                vm.creatives.forEach(function (creative, index, array) {
                    if (creative.channelId == 2 || creative.channelId == 4 || creative.channelId == 5) {
                        creative.videoFile = $sce.trustAsResourceUrl(creative.videoURL);
                        creative.videoOptions = { height: 316 };
                        if (creative.creativeIconURL && creative.creativeIconURL !== ",,,,")
                            creative.videoOptions.image = creative.creativeIconURL;
                    }
                    else if (creative.channelId == 1) {
                        if (creative.bannerSize && creative.bannerSize != 0) {
                            creative.size = vm.sizes.find(function (s) { return s.id == creative.bannerSize; });
                            creative.width = parseInt(creative.size.name.substring(0, creative.size.name.indexOf('x')));
                            creative.height = parseInt(creative.size.name.substring(creative.size.name.indexOf('x') + 1));
                        } else if (creative.width && creative.height) {
                            creative.size = { name: creative.width + 'x' + creative.height };
                        }

                        creative.previewApiURL = appSettings.apiUrl + 'api/creative/preview/' + creative.id;

                        if (creative.thirdPartyServed || creative.isHtml5) {
                            var advertiser = campaignService.advertisers.find(function (b) { return b.id == creative.advertiserId; });
                            if (advertiser)
                                creative.advertiserAvatarUrl = hub.settings.imagesUrl + 'avatars/' + advertiser.avatar;

                            switch (creative.size.id) {
                                case 1:
                                    creative.zoom = 0.37;
                                    break;
                                case 2:
                                    creative.zoom = 0.9;
                                    break;
                                case 3:
                                    creative.zoom = 0.455;
                                    break;
                                case 4:
                                    creative.zoom = 0.85;
                                    break;
                                case 5:
                                    creative.zoom = 0.9;
                                    break;
                                case 6:
                                    creative.zoom = 0.85;
                                    break;
                                default:
                                    creative.zoom = 1;
                                    break;
                            }
                            creative.previewWidth = creative.width * creative.zoom;
                            creative.previewHeight = creative.height * creative.zoom;
                        }
                    }
                    else if (creative.channelId == 3) {
                        creative.creativeType = vm.creativeTypes.find(function (ct) { return ct.id == creative.creativeTypeId; });
                    }
                    checkIfBlank(creative);
                });
            }

            vm.pageSize = vm.config.pageSize().toString();
            vm.pageSizeChanged = function (size) {
                vm.pageSize = size;
                $scope.search();
            }

            vm.pageSelected = function () {
                campaignService.getCreatives(null, null, vm.pageNumber - 1, vm.sort.creatives.keyword, vm.filter, vm.sort.creatives.column, vm.sort.creatives.sortDesc, true, null).then(function (d) {
                    vm.creatives = d.creatives;

                    if (vm.sort.creatives.keyword != '' && vm.creatives.length == 0)
                        vm.creativesNotFound = true;
                    else
                        vm.creativesNotFound = false;

                    vm.totalCount = d.totalCount;
                    processCreatives();
                    $scope.refresh();
                });
            }

            vm.sortFn = function (column) {
                vm.sort.creatives.sortDesc = column == vm.sort.creatives.column ? !vm.sort.creatives.sortDesc : true;
                vm.sort.creatives.column = column;
                $scope.search();
            }

            function checkIfBlank(e) {
                if (e.bannerURL == null) {
                    e.allImgsBlank = true;
                } else {
                    e.allImgsBlank = false;
                }
            }

            $scope.$on('creativesLoaded', function (list) {
                vm.getCreatives();
            });

            $scope.hover = function (entity) {
                return entity.showActions = true;
            };

            $scope.out = function (entity) {
                return entity.showActions = false;
            };

            vm.removeCreative = function (creative, removeCallback) {
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Remove',
                    message: 'Are you sure you want to remove creative ' + creative.name + '?'
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                    var startTime = new Date();
                    hub.webapicontext.removeCreative(creative.id).then(function () {
                        vm.log(creative.name + " Removed");
                        vm.getCreatives(true);
                        var data = { id: creative.id, name: creative.name };
                        authService.trackEvent({ action: 'RemoveCreative', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                        vm.isRemoved = 'true';
                        $rootScope.$broadcast('nextCreative', vm.isRemoved);
                        if (removeCallback)
                            removeCallback();
                    });
                });
            };

            vm.copyCreative = function (creative) {
                var modalOptions = {
                    templateUrl: 'views/creative/editors/creative-copy.html',
                    animation: false,
                    controller: 'copyCreativeController',
                    size: 'md',
                    resolve: { creative: creative }
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                    vm.log(creative.name + " Copied across " + result.advertiserName);
                    campaignService.getCreatives(null, null, 0, vm.sort.creatives.keyword, vm.filter, vm.sort.creatives.column, vm.sort.creatives.sortDesc, true, null).then(function (d) {
                        vm.creatives = d.creatives;
                        vm.totalCount = d.totalCount;
                        processCreatives();
                        $timeout(function () {
                            $scope.refresh();
                        });
                    });
                });
            };

            vm.zoomCreative = function (creative, hideNavigation) {
                $location.path('activation/creative/' + creative.id + '/zoom', false);
                var modalInstance;
                modalInstance = $modal.open({
                    templateUrl: 'views/creative/editors/creative-zoom.html',
                    animation: false,
                    controller: 'zoomCreativeController as vm',
                    size: 'lg',
                    resolve: {
                        data: function () {
                            return { creatives: vm.creatives, creative: creative, editCreative: vm.editCreative, copyCreative: vm.copyCreative, removeCreative: vm.removeCreative, hideNavigation: hideNavigation };
                        }
                    }
                });
                modalInstance.result.then((function () { }), function () {
                    $location.path('activation/creative', false);
                });
            };

            vm.getTrackingCodes = function (creative) {
                var result = "";
                if (creative.trackingCodes)
                    creative.trackingCodes.forEach(function (tc) {
                        if (tc.trackingCodeValue) {
                            if (result)
                                result += ', ' + tc.trackingCodeValue;
                            else
                                result += tc.trackingCodeValue;
                        }
                    });
                return result;
            };


            vm.getClassByChannelId = function (channelId) {
                var cssClass = '';
                if (channelId == 1)
                    return 'ti-world';
                else if (channelId == 2 || channelId == 4)
                    return 'ti-video-camera';
                else if (channelId == 5)
                    return 'ti-music';
                else if (channelId == 3)
                    return 'ti-facebook';
                else
                    return '';
            }

            rootVM.getChannelById = function (channelId) {
                var name = '';
                campaignService.getChannels().forEach(function (item, index) {
                    if (item.id == channelId)
                        name = item.name;
                });
                return name;
            }

            rootVM.getSizeById = function (sizeId) {
                var name = '';
                campaignService.getSizes().forEach(function (item, index) {
                    if (item.id == channelId)
                        name = item.name;
                });
                return name;
            }

            vm.editCreative = function (creative, viewMode, zoom, fromZoom) {
                if (!vm.shouldOpenModal()){
                    return;
                }
                if (!viewMode)
                    viewMode = 'edit';

                $location.path('activation/creative/' + creative.id + '/edit', false);
                var zoomPath = fromZoom ? '/' + creative.id + '/zoom' : '';
                var modalInstance;
                campaignService.getAccounts(1).then(function () {
                    modalInstance = $modal.open({
                        templateUrl: 'views/creative/editors/creative.html',
                        animation: false,
                        controller: 'editCreativeController',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            data: function () {
                                return { creatives: vm.creatives, creative: creative, files: [] };
                            },
                            creativeForm: function () {
                                return $scope.creativeForm;
                            },
                            viewMode: function () { return viewMode; }
                        }
                    });
                    modalInstance.result.then((function (data) {
                        var savedCreative = data.creative;
                        vm.log(savedCreative.name + " Saved");
                        for (var i = 0; i < vm.creatives.length; i++) {
                            if (vm.creatives[i].id == savedCreative.id) {
                                vm.creatives[i] = savedCreative;
                                break;
                            }
                        }
                        processCreatives();
                        $scope.refresh();
                        $rootScope.$broadcast("creativeUpdated", savedCreative);
                        $location.path('activation/creative' + zoomPath, false);

                    }), (function () {
                        if ($location.$$path.indexOf('/activation/campaign') === -1)
                            $location.path('activation/creative' + zoomPath, false);
                    }));
                });
            };

            function LoadData() {
                vm.getCreatives();
            }

            vm.processUrl = function () {
                var creativeId = $routeParams.creativeId;
                var mode = $routeParams.mode;

                if (creativeId) {
                    hub.webapicontext.getCreative(creativeId).then(function (d) {
                        if (mode == 'edit')
                            vm.editCreative(d, (vm.isObserver('creatives', d) ? 'readonly' : 'edit'));
                        else if (mode == 'zoom')
                            vm.zoomCreative(d, true);
                    });
                } else if ($location.$$path.indexOf('/activation/creative/new') > -1) {
                    vm.newCreative();
                } else if ($location.$$path.indexOf('/activation/creative/bulk') > -1) {
                    vm.bulkCreativeUpload();
                } else {
                    $rootScope.$broadcast('modal.force.close', null);
                }
            };

            var original = $location.path;
            $location.path = function (path, reload) {
                if (reload === false) {
                    var lastRoute = $route.current;
                    var un = $rootScope.$on('$locationChangeSuccess', function () {
                        if (IsCurrentPage($route.current.$$route.originalPath))
                            $route.current = lastRoute;

                        un();
                    });
                }
                return original.apply($location, [path]);
            };

            function IsCurrentPage(path) {
                if (path.indexOf('activation/creative') > -1 && path.indexOf('activation/creative-request') == -1)
                    return true;
                return false;
            }

            LoadData();
            vm.processUrl();
        }]);


    angular.module('app')
        .controller('bulkCreativeResendController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'settings', 'hub', 'campaignService', 'authService', '$location', '$timeout', 'Enum', function ($scope, $rootScope, $modalInstance, $sce, settings, hub, campaignService, authService, $location, $timeout, Enum) {
            var vm = this;
            $scope.vm = vm;
            var rootVM = $rootScope.rootVM;
            vm.isAdmin = authService.isSuperAdmin();

            $scope.ok = function () {
                vm.isInProgress = true;
                var creativeIds = vm.creativeIds.replace(' ', '').split(',');
                hub.webapicontext.resendBulkCreatives(creativeIds).then(function () {
                    $modalInstance.close();
                });
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };
        }]);

    angular.module('app')
        .controller('bulkCreativeUploadController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'settings', 'hub', 'campaignService', 'authService', '$location', '$timeout', 'Enum', function ($scope, $rootScope, $modalInstance, $sce, settings, hub, campaignService, authService, $location, $timeout, Enum) {
            var vm = this;
            $scope.vm = vm;
            var log = hub.getLog('Creatives');
            vm.log = log;
            var rootVM = $rootScope.rootVM;
            vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('creatives', 'Manage');
            vm.sizes = campaignService.getSizes();
            vm.showPreview = true;
            vm.creativeDefaultExchangeSeat = hub.settings.environment === 'production' ? 11550 : 13361;
            vm.isAdmin = authService.userAccountType() == "Admin";
            vm.exchangeSeatId = vm.creativeDefaultExchangeSeat;

            vm.selectedAdvertiserChanged = function () {
                vm.selectedMarketer = vm.marketers.find(function (m) { return m.id == vm.selectedAdvertiser.marketerId; });
                if (vm.selectedMarketer.exchangeSettings && vm.selectedMarketer.exchangeSettings[1]) {
                    vm.exchangeSeatId = vm.selectedMarketer.exchangeSettings[1].externalId;
                } else {
                    vm.exchangeSeatId = vm.creativeDefaultExchangeSeat;
                }
            }

            vm.uploadXLSX = function (file) {
                return hub.webapicontext.uploadFile('Creative/XLSXUpload', file).then(function (data) {
                    if (data.data.error) {
                        vm.log(data.data.error);
                        return;
                    }
                    vm.fileName = file.name;
                    vm.creatives = data.data;
                    var i = 1;
                    vm.creatives.forEach(function (creative) {
                        creative.tempId = i++;
                        creative.thirdPartyAdTagUrl = null;
                        creative.thirdPartyCreativeType = '1';
                        creative.include = true;
                        var width = creative.width;
                        var height = creative.height;
                        $timeout(function () {
                            creative.height = '';
                            creative.width = '';
                            if (creative.thirdPartyAdTag && creative.thirdPartyAdTag.indexOf('<ins') > -1) {
                                if (creative.thirdPartyAdTag.match(/height:(\d)*/g))
                                    creative.height = creative.thirdPartyAdTag.match(/height:(\d)*/g)[0].substring(7);
                                if (creative.thirdPartyAdTag.match(/width:(\d)*/g))
                                    creative.width = creative.thirdPartyAdTag.match(/width:(\d)*/g)[0].substring(6);
                                if (creative.height && creative.width)
                                    creative.size = creative.width + 'x' + creative.height;
                            }
                            else if (creative.thirdPartyAdTag && creative.thirdPartyAdTag.indexOf('${CLICK_URL}') > -1) {
                                creative.bannerURL = '';
                                if (creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)) {
                                    var img = new Image();
                                    img.onload = function () {
                                        var allowedDimensions = ['728x90', '300x600', '300x250', '160x600', '320x50', '300x50', '970x90', '970x250', '1x1', '460x120', '570x70', '120x240', '650x136', '1200x628', '320x480', '640x480', '640x640', '120x600', '1920x1080', '768x1024', '1024x768', '320x100', '300x1050', '250x250', '336x280', '480x320', '320x250', '970x550', '468x60'];
                                        if (allowedDimensions.indexOf(this.width + 'x' + this.height) >= 0) {
                                            creative.size = this.width + 'x' + this.height;
                                            creative.height = this.height;
                                            creative.width = this.width;
                                            creative.bannerURL = creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)[0];

                                            if (creative.size != 'All Sizes') {
                                                creative.bannerSize = Enum.getId('BannerSizes', creative.size);
                                            }
                                        }
                                        else {
                                            vm.dimensionIsValid = false;
                                            vm.validateDimension = true;
                                        }
                                    }
                                    img.src = creative.thirdPartyAdTag.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/i)[0];

                                }

                            } else if (creative.thirdPartyAdTag && creative.thirdPartyAdTag.indexOf('<script ') > -1) {
                                if (!creative.size)
                                    creative.size = creative.thirdPartyAdTag.match(/([0-9]+x[0-9]+)/i)[0];
                                if (creative.size) {
                                    creative.width = creative.size.split('x')[0];
                                    creative.height = creative.size.split('x')[1];
                                }
                            }

                            creative.nonStandardCreativeSize = false;
                            if (!vm.sizes.find(function (s) { return s.name == creative.size || s.name == creative.size; })) {
                                if (creative.height && creative.width) {
                                    creative.bannerSize = 0;
                                    creative.size = 'No Size';
                                    creative.nonStandardCreativeSize = true;
                                } else {
                                    creative.height = '';
                                    creative.width = '';
                                    creative.size = '';
                                }
                            }
                            else {
                                var size = vm.sizes.find(function (s) { return s.name == creative.size || s.name == creative.size; });
                                creative.width = size.name.split('x')[0];
                                creative.height = size.name.split('x')[1];
                            }

                            if (creative.size != 'All Sizes' && !creative.bannerSize) {
                                creative.bannerSize = Enum.getId('BannerSizes', creative.size);
                            }

                            creative.height = parseInt(creative.height);
                            creative.width = parseInt(creative.width);
                            creative.thirdPartyAdTagUrl = $sce.trustAsResourceUrl(creative.thirdPartyAdTag);

                            if (isNaN(creative.height) || isNaN(creative.width)) {
                                creative.bannerSize = null;
                                creative.size = null;
                            }

                            if (!creative.height && height && !creative.width && width && !creative.size) {
                                creative.height = parseInt(height);
                                creative.width = parseInt(width);
                                creative.size = width + 'x' + height;
                                creative.bannerSize = Enum.getId('BannerSizes', creative.size);
                            }
                        });
                    });
                });
            };

            $scope.ok = function () {
                var startTime = new Date();
                vm.isInProgress = true;
                vm.maxLengthItems = null;
                var creativeValid = this.bulkForm.$valid;
                if (!creativeValid) {
                    if (this.bulkForm.$error && this.bulkForm.$error.maxlength)
                        vm.maxLengthItems = this.bulkForm.$error.maxlength;
                    $('#bulkForm').validator('destroy');
                    $('#bulkForm').validator('validate');
                    vm.isInProgress = false;
                    return;
                }

                vm.creatives.forEach(function (c) {
                    c.channelId = 1;
                    c.adChoices = true;
                    c.thirdPartyServed = true;
                    if (c.trackingCode)
                        c.trackingCodes = [{ trackingCodeValue: c.trackingCode, trackingCodeMode: 'Lifetime' }];

                    if (vm.selectedAdvertiser)
                        c.advertiserId = vm.selectedAdvertiser.id;

                    c.exchangeSeatId = vm.exchangeSeatId;
                });
                var creativesToSave = vm.creatives.filter(function (c) { return c.include; });
                hub.webapicontext.saveBulkCreatives(creativesToSave).then(function () {
                    $timeout(function () {
                        $modalInstance.close(vm.creatives);
                    });
                });
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            campaignService.getAccounts(1).then(function () {
                vm.advertisers = campaignService.advertisers;
                vm.marketers = campaignService.marketers;
                if (!vm.isAdmin && !vm.selectedMarketerId && vm.marketers && vm.marketers.length === 1) {
                    vm.selectedMarketerId = vm.marketers[0].id;
                    vm.selectedMarketer = vm.marketers[0];
                    if (vm.selectedMarketer.exchangeSettings && vm.selectedMarketer.exchangeSettings[1]) {
                        vm.exchangeSeatId = vm.selectedMarketer.exchangeSettings[1].externalId;
                    } else {
                       vm.exchangeSeatId = vm.creativeDefaultExchangeSeat;
                    }
                }
            });

        }]);

    angular.module('app')
        .controller('copyCreativeController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'creative', 'hub', 'campaignService', 'authService', '$location', 'logger', function ($scope, $rootScope, $modalInstance, $sce, creative, hub, campaignService, authService, $location, logger) {
            var vm = this;
            $scope.vm = vm;
            vm.logger = logger;
            var rootVM = $rootScope.rootVM;
            vm.creative = angular.copy(creative);

            $scope.ok = function () {
                var startTime = new Date();
                vm.creative.advertiser = vm.selectedAdvertiser.name;
                hub.webapicontext.copyCreative(vm.creative.id, vm.selectedAdvertiser.id).then(function () {
                    var data = { id: vm.creative.id, name: vm.creative.name };
                    authService.trackEvent({ action: 'CopyCreative', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
                    $modalInstance.close(vm.creative);
                }).catch(error => vm.logger.logError(error && error.data && error.data.exceptionMessage));
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };

            campaignService.getAccounts(1).then(function () {
                vm.advertisers = campaignService.advertisers;
                vm.selectedAdvertiser = _.find(vm.advertisers, function (advertiser) { return advertiser.id == vm.creative.advertiserId });
            });

        }]);

    angular.module('app').controller('creativeVersionsController', ['$scope', '$uibModalInstance', 'data', function ($scope, $modalInstance, data) {
        var vm = this;
        $scope.vm = vm;
        vm.creativeVersions = data.creativeVersions;
        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
        vm.downloadCreative = function (url) {
            window.open(url, '_blank');
        }
        vm.formatDate = function (zuluDate) {
            var date = new Date(zuluDate);
            return date.toLocaleString();
        }
    }]);
})();
