(function () {
    'use strict';

    angular.module('app')
        .filter('startFrom',
          function () {
            return function (input, start) {
              if (input) {
                start = +start;
                return input.slice(start);
              }
              return [];
            };
          }
        )
        .controller('locationController', ['$scope', '$rootScope', '$timeout', '$uibModal', '$filter', 'hub', 'authService', '$location', '$q', 'Enum', '$route', '$routeParams', 'dialogService', 'timezoneService', 'logger', function ($scope, $rootScope, $timeout, $modal, $filter, hub, authService, $location, $q, Enum, $route, $routeParams, dialogService, timezoneService, logger) {


            var vm = this;
            vm.page = hub.page;
            vm.locationTypes = angular.copy(Enum.LocationTypes);
            vm.config = hub.config;
            var log = hub.getLog('Locations Management');
            vm.log = log;

            vm.canEdit = authService.isSuperAdmin() || authService.hasAccess('locations', 'Manage');
            hub.page.setTitle('Locations Management');
            vm.locations = [];

            vm.currentEastTimeAbbreviation = timezoneService.getInfo().currentEastTimeAbbreviation;

            vm.sort = {
                locations: { column: 'id', sortDesc: true, keyword: '', accountType: '' }
            };

            var loadLocations = function () {

                var deferred = $q.defer();

                hub.webapicontext.getAudiences('Location').then(function (data) {
                    data.forEach(function (item) {

                        let type = vm.locationTypes.find(function (lt) { return lt.id === item.locationGroupTypeId; });
                        item.typeName = type ? type.name : 'Unknown';
                        item.statusText = item.statusId === 4 ? 'Draft' : (item.statusId === 128 || !item.audienceStatusId ? 'Processing' : 'Ready');

                    });

                    deferred.resolve(data.filter(l => l.isInternal == true));
                });

                return deferred.promise;
            };

            vm.sendMessage = function (l) {
                return hub.api.get('api/locationGroup/SendLocationGroupMessage/' + l.id).then(function (r) {
                    hub.getLog('Location Group Management', 'info')('Syncronization message for ' + l.name + ' has been sent to exchange-integration');
                    l.messageSent = true;
                });
            };

            $rootScope.$on('locationUpdate', function (name, notification) {
                if (vm.locations && vm.locations.find(a => a.id == notification.value)) {
                    logger.showToastPermanent('Lasso Notification: Location List has been changed.');
                    loadLocations().then(function (data) {
                        vm.locations = data;
                        vm.total = data.length;
                    });
                }
            });

            vm.getDownloadUrl = function (id) {
                return hub.api.get('api/audience/download/' + id + '/true').then(function (r) {
                    var a = document.createElement('a');
                    a.href = r.url;
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                });
            };

            vm.load = function () {
                loadLocations().then(function (data) {
                    vm.locations = data;
                    vm.total = data.length;
                });
            };

            vm.sortFn = function (column, list) {
                if (vm.sort[list].column === column)
                    vm.sort[list].sortDesc = !vm.sort[list].sortDesc;
                else {
                    vm.sort[list].column = column;
                    vm.sort[list].sortDesc = false;
                }

                vm.search(list);

            };

            vm.pageSize = vm.config.pageSize().toString();
            vm.pageNumber = 1;
            vm.total = 0;

            vm.pageSizeChanged = function (size) {
                vm.pageSize = size;
                vm.pageNumber = 1;
            };

            $scope.$watch('vm.sort.locations.keyword', function (){
              vm.total = $filter('filter')(vm.locations, vm.sort.locations.keyword).length;
              vm.pageNumber = 1;
            });
            vm.shouldOpenModal = function () {
                return document.querySelector('.modal-container') === null;
            }
            vm.editLocation = function (location) {
                if(!vm.shouldOpenModal()) return;
                if (!location && $location.$$path.indexOf('/activation/location/new') == -1)
                    $location.path('activation/location/new', false);
                else if (location && $location.$$path.indexOf('/activation/location/' + location.id + '/edit') == -1)
                    $location.path('activation/location/' + location.id + '/edit', false);

                var modalInstance;
                modalInstance = $modal.open({
                    templateUrl: location && location.id ? 'views/location/location-editor.html' : 'views/location/new-location-editor.html',
                    animation: true,
                    controller: 'editLocationController',
                    controllerAs: 'vm',
                    size: 'lg',
                    backdrop: 'static',
                    resolve: {
                        data: function () {
                            if (location)
                                return location;
                            else
                                return { name: '', locationGroupTypeId: 1, radius: '' };
                        },
                        locationGroup: function () {
                            return {};
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $location.path('activation/location', false);
                    vm.load();
                }, function (result) {
                    $location.path('activation/location', false);
                    vm.load();
                });
            };

            vm.removeLocation = function (location) {
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Remove',
                    message: 'Are you sure you want to remove location ' + location.name + '?'
                };
                dialogService.showModal({ windowClass: 'modal-dialog-alert' }, modalOptions).then(function (result) {
                    hub.webapicontext.removeLocation(location.id).then(function () {
                        vm.log(location.name + " Removed");
                        $location.path('activation/location', false);
                        vm.load();
                    });
                });
            };


            var original = $location.path;
            $location.path = function (path, reload) {
                if (reload === false) {
                    var lastRoute = $route.current;
                    var un = $rootScope.$on('$locationChangeSuccess', function () {
                        if (IsCurrentPage($route.current.$$route.originalPath))
                            $route.current = lastRoute;

                        un();
                    });
                }
                return original.apply($location, [path]);
            };

            function IsCurrentPage(path) {
                if (path.indexOf('activation/location') > -1)
                    return true;
                return false;
            }

            vm.processUrl = function () {
                var locationId = $routeParams.locationId;

                if (locationId) {
                    var location = vm.locations.find(function (l) { return l.id == locationId; });
                    if(!location){
                        hub.webapicontext.getAudiences('Location').then(function (data) {
                            var location = data.find(function (l) { return l.id == locationId; });
                            location.statusText = location.statusId === 4 ? 'Draft' : (location.statusId === 128 ? 'Processing' : 'Ready');
                            vm.editLocation(location);
                        });
                    }else{
                        vm.editLocation(location);
                    }
                } else if ($location.$$path.indexOf('/activation/location/new') > -1) {
                    vm.editLocation();
                }
                else {
                    $rootScope.$broadcast('modal.force.close', null);
                }
            };
            vm.load();
            vm.processUrl();

            return vm;
        }]);

})();
