(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:reportFilterSection
 * @infoPanel directive
 * # <report-filter-section model={{model}}  />
 * informational panel
 */

    angular.module('app')
      .directive('reportFilterSection', function () {
          return {
              template: require('@views/app/report-filter-section.html').default,
              restrict: 'E',
              scope: { model: '=' },
              controller: ['$scope', '$element', 'customReportService', function ($scope, $element, customReportService) {
                  var vm = this;
                  vm.model = $scope.model;
                  vm.reportConfig = customReportService;
                  vm.keyword = '';

                  vm.cancelFilter = function ($event, section)
                  {
                      $event.stopPropagation();
                      if(vm.model.old_items){
                        vm.model.items = vm.model.old_items;
                        delete vm.model.old_items;
                      }
                      if(vm.model.old_copy){
                        vm.model.copy = vm.model.old_copy;
                        delete vm.model.old_copy;
                      }
                      vm.reportConfig.report.filter[section].ids = vm.model.copy;
                      vm.reportConfig.report.filter[section].showAll = vm.model.copy.length === 0

                      vm.model.items.forEach(function (item) {
                          if (vm.model.copy.indexOf(item[vm.model.idColumn]) >= 0)
                              item.checked = true;
                          else
                              item.checked = false;
                      });

                      vm.close()

                      vm.reportConfig.filter.mode = null;
                      vm.reportConfig.report.filter.clearAllFlag = false;
                  }
                  vm.saveFilter = function (section,key)
                  {
                      var campIds = [];
                      vm.model.items.forEach(function (item) {
                        if (item.checked)
                            campIds.push(item[key]);
                      });

                      vm.reportConfig.report.filter[section].ids = campIds;
                      vm.reportConfig.filter.mode = null;
                      vm.reportConfig.clean_filter();
                  }

                  vm.getSelectedTitle = function() {
                      var checked = vm.model.items.filter(function (item) {
                          return item.checked;
                      });
                      if(checked.length == vm.model.items.length) {
                          return 'All ' + vm.model.title;
                      } else if(checked.length == 0) {
                          return 'Select ' + vm.model.title.toLowerCase() + ' to filter.';
                      }
                      return null;
                  }

                  vm.applyFilter = function ($event, section, key) {
                      $event.stopPropagation();
                      if(vm.model.old_items){
                        delete(vm.model.old_items);
                      }
                      if(vm.model.old_copy){
                        delete(vm.model.old_copy);
                      }
                      vm.reportConfig.report.filter[section].ids = [];
                      vm.saveFilter(section, key);
                      vm.close()
                      vm.reportConfig.filter.mode = null;
                      if(vm.reportConfig.report.filter.clearAllFlag){
                        for (const [k, v] of Object.entries(vm.reportConfig.report.filter)){
                          if(v.ids && k != section){
                            v.ids = [];
                          }
                        }
                      }
                    vm.reportConfig.report.filter.clearAllFlag = false;
                    vm.reportConfig.applyChannelSpecificOptions(section);
                  }

                  $element.on('$destroy', function() {
                    document.body.style = ""
                  });

                  vm.clear = function ()
                  {
                      vm.model.items.forEach(function (item) {
                          item.checked = false;

                      });

                      vm.saveFilter(vm.model.section, vm.model.idColumn);
                      vm.reportConfig.report.filter[vm.model.section].showAll = true;
                      vm.close()
                  }

                  vm.close = function (){
                    vm.model.hidden = true;
                  }

                  vm.hasSelected = function ()
                  {
                      return vm.model.items.find(function (x) { return x.checked; });
                  }

                  vm.getSelected = function (type, idColumn)
                  {

                      if (vm.reportConfig.report.filter[type] == null)
                          vm.reportConfig.report.filter[type] = { showAll: true, ids: [] };

                      var ids = vm.reportConfig.report.filter[type].ids || [];
                      if (ids.length === 0)
                          return "All";
                      else
                          return "Filtered";
                      /*
                      var names = '';
                      ids.forEach(function (id) {

                          var camp = vm.model.items.find(function (x) { return x[idColumn] === id; });
                          if (camp)
                              names += camp.name + '; ';

                      });
                      return names;
                      */
                  }

                  vm.setAll = function(newValue){
                    vm.reportConfig.report.filter.clearAllFlag = true;
                    vm.model.old_items = vm.model.items;
                    vm.model.old_copy = vm.model.copy;
                    vm.model.copy = [];
                    switch(vm.model.section){
                      case "advertisers":
                        vm.model.items = vm.reportConfig.get_advertisers();
                        break;
                      case "marketers":
                        vm.model.items = vm.reportConfig.get_marketers();
                        break;
                      case "publishers":
                        vm.model.items = vm.reportConfig.get_publishers();
                        break;
                      case "campaigns":
                        vm.model.items = vm.reportConfig.get_campaigns();
                        break;
                      case "adGroups":
                        vm.model.items = vm.reportConfig.get_adgroups();
                        break;
                      case "channels":
                        vm.model.items = vm.reportConfig.get_channels();
                        break;
                      case "mediaTypes":
                        vm.model.items = vm.reportConfig.get_mediaTypes();
                        break;
                      case "demandTypes":
                        vm.model.items = vm.reportConfig.get_demandTypes();
                        break;
                      default:
                        console.log("unknown section", vm.model.section);
                    }
                    vm.model.items.forEach(function(it){
                        it.checked = newValue;
                    });
                  }
                  $scope.$watch('vm.model.items.length', function(newValue){
                    if(newValue && !vm.reportConfig.report.filter.clearAllFlag) {
                        vm.model.items.forEach(function(it){
                            const id = it[vm.model.idColumn];
                            it.checked = vm.reportConfig.report.filter[vm.model.section].ids.indexOf(id) > -1;
                        });
                    }
                  });

                  $scope.$watch('vm.reportConfig.filter.mode', function(newValue){
                    document.body.style = "overflow-y: hidden;"
                  });

                  $scope.$watch('vm.reportConfig.event', function(newValue){
                    var offsetY = newValue.clientY+20;
                    var windowWidth = 522
                    var windowHeight = 500
                    var offsetX = newValue.clientX-(windowWidth/2);
                    var innerWidth = newValue.view.innerWidth
                    var innerHeight = newValue.view.innerHeight

                    if(offsetX+windowWidth>innerWidth){
                      offsetX = innerWidth - windowWidth - 20
                    }

                    var style = `left: ${offsetX}px;`

                    if(offsetY+windowHeight>innerHeight){
                      style+=`bottom: 20px;top: unset;`
                    }else{
                      style+=`top: ${offsetY}px;`
                    }

                    vm.parent = $element.parent().parent().parent()[0];
                    vm.parent.style = style
                  });

                  return vm;
              }]
              ,
              controllerAs: 'vm'
          }
      });

})();
