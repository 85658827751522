const supportedKeys = {
  MediaType: 'MediaType',
  Channel: 'ChannelID',
  DemandType: 'DemandType'
}

const mediaTypes = {
  1: 'Banner',
  2: 'Video',
  3: 'Audio',
  4: 'E-Newsletter',
}

const channels = {
  0: 'None',
  1: 'Banner',
  2: 'Video',
  3: 'Facebook Instagram',
  4: 'Connected TV',
  5: 'Audio',
  6: 'Email',
  7: 'Search',
  8: 'Twitter',
  9: 'DataOnly',
  10: 'Measurement',
  11: 'LinkedIn',
  12: 'External DSP',
  13: 'TikTok',
  14: 'Snapchat',
  15: 'Xandr DMP',
  16: 'Endemic HCP',
  17: 'TradeDesk DMP',
  18: 'LiveRamp',
  19: 'Direct Sold',
  20: 'Endemic DTC',
  21: 'Reddit',
  22: 'Yahoo',
  23: 'Adtheorent',
  24: 'Reserved',
  25: 'Endemic EHR'
};

function mapReportColumn(key, value) {
  if (key === supportedKeys.MediaType) {
    return mapColumnValue(value, mediaTypes);
  }

  if (key === supportedKeys.DemandType || key === supportedKeys.Channel) {
    return mapColumnValue(value, channels);
  }

  return value;
}

function mapColumnValue(value, valuesMapper) {
  const mappedKey = valuesMapper[value];
  if (mappedKey) {
    return mappedKey;
  }

  return value;
}

export { mapReportColumn };
